import React from 'react';
import styled from 'styled-components';


const TitleContainer = styled.div`
    width: 100%;
    font-size: 24px;
    color: var(--primary);
    font-weight: 600;
    text-align: center;
    margin: 10px 0;
`

const Title = ({text}) => {
    return (
        <TitleContainer>
            {text}
        </TitleContainer>
    );
};

export default Title;