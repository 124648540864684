import { pluck } from "ramda";

export const uppercase = (word) => {
  return word.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const aSorted = a.sort((a, b) => a - b);
  const bSorted = b.sort((a, b) => a - b);

  for (var i = 0; i < aSorted.length; ++i) {
    if (aSorted[i] !== bSorted[i]) return false;
  }
  return true;
}
export function priceEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const aStrings = pluck("displayName")(a);
  const bStrings = pluck("displayName")(b);
  const aSorted = aStrings.sort((a, b) => a - b);
  const bSorted = bStrings.sort((a, b) => a - b);

  for (var i = 0; i < aSorted.length; ++i) {
    if (aSorted[i] !== bSorted[i]) return false;
  }
  return true;
}
