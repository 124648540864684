import React from "react";
import screenStyles from '../../../styles/screen.module.css';
import checkoutStyles from '../../../styles/checkout.module.css';
import cardStyles from './checkoutCard.module.css';
import { PaymentTypes } from "../../../interfaces/enums";

const PaymentCard = (props) => {
  return (
    <div className={screenStyles.backgroundContainer}>
      <div
        className={checkoutStyles.subheading}
        style={{ marginBottom: "10px" }}
      >
       <div className={"row"}>
        <div className={checkoutStyles.subheading}>Paying with</div>
        <div className={checkoutStyles.editLink} onClick={() => props.handleEdit('payment')}>
          edit
        </div>
      </div>
      </div>
      <div className={checkoutStyles.inputPadding + ' ' + cardStyles.row} style={{alignItems: 'start'}}>
        <div className={cardStyles.key}>Type</div>
        <div className={cardStyles.value}>
        {PaymentTypes[props.paymentType]}
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
