import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import MessageBox from "../../components/messageBox";
import ProductCard from "../../components/product/productCard/productCard";
import ProductCardSkeleton from "../../components/product/productCard/productCardSkeleton";
import ProductModal from "../../components/product/productCard/productModal/productModal";
import screenStyles from "../../styles/screen.module.css";
import { listProducts } from "../../actions/productActions";
import Filter from "../../components/filter";
import { arraysEqual, priceEqual, uppercase } from "../../utils";
import Sort from "../../components/filter/sort";
import Button from "../../components/button/button";
import IconService from "../../services/iconService";
import iconStyles from "../../styles/icon.module.css";
import MobileFilter from "../../components/filter/mobileFilter";


const NoProductsContainer = styled.div`
  text-align: center;
  color: var(--primary-hover);
  font-size: 20px;
  padding: 30px 0;
`;

const ShopByAreaOfFocus = ({
  loading,
  error,
  products,
  listProducts,
  search,
  searchString,
  brands,
  searchBrands,
  categories,
  searchCategories,
  concerns,
  searchConcerns,
  areasOfFocus,
  searchAreasOfFocus,
  prices,
  searchPrices,
  sortType,
  order,
}) => {
  const { name: area } = useParams();
  const dispatch = useDispatch();
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  function openProductModal(product) {
    setShowProductModal(true);
    setSelectedProduct(product);
  }
  function hideProductModal() {
    setShowProductModal(false);
    setSelectedProduct(null);
  }

  useEffect(() => {
    if (!areasOfFocus.includes(area)) {
      dispatch({
        type: "SET_AREAS_OF_FOCUS_REFRESH",
        payload: [area],
      });
    }
    if (search) {
      dispatch({
        type: "SET_SEARCH_VALUE",
        payload: "",
      });
    }
  }, [area, areasOfFocus, dispatch, search]);

  useEffect(() => {
    document.title = `${uppercase(area)} | Shop at ShotDocMD`;
  }, [area]);

  useEffect(() => {
    if (
      search !== searchString ||
      !arraysEqual(brands, searchBrands) ||
      !arraysEqual(categories, searchCategories) ||
      !arraysEqual(concerns, searchConcerns) ||
      !arraysEqual(areasOfFocus, searchAreasOfFocus) ||
      !priceEqual(prices, searchPrices) ||
      sortType !== order ||
      !products
    ) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      listProducts({
        name: search ?? "",
        brands: brands ?? [],
        categories: categories ?? [],
        concerns: concerns ?? [],
        areasOfFocus: areasOfFocus ?? [],
        prices: prices ?? [],
        order: sortType,
      });
    }
  }, [
    listProducts,
    search,
    searchString,
    products,
    brands,
    searchBrands,
    categories,
    searchCategories,
    concerns,
    searchConcerns,
    areasOfFocus,
    searchAreasOfFocus,
    prices,
    searchPrices,
    order,
    sortType,
  ]);

  return (
    <>
      <div className={"row-container"}>
        <div
          className={screenStyles.row}
          style={{ width: "100%", height: "100%" }}
        >
          <div className="filter-container">
            <Filter {...{ omitType: "areas" }} />
          </div>
          <div style={{ flex: "1", padding: "10px" }}>
            <div>
              <Breadcrumb
                options={[
                  {
                    name: "Home",
                    path: "/",
                  },
                  {
                    name: "Areas Of Focus",
                    path: "/areas-of-focus",
                  },
                  {
                    name: `${uppercase(area)}`,
                    path: `/areas-of-focus/${area}`,
                  },
                ]}
              />
              <div
                className="row"
                style={{
                  alignItems: "flex-end",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1 }}>
                  <div
                    className={screenStyles.brandName}
                    style={{ marginTop: "5px" }}
                  >
                    {uppercase(area)}
                  </div>
                  <div
                    className={screenStyles.results}
                    style={{ marginBottom: 0 }}
                  >
                    {products ? (
                      <>
                        {products.length} result{products?.length !== 1 && "s"}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}{" "}
                  </div>
                  <div
                    className={"mobile-filter-container"}
                    style={{ marginTop: "10px" }}
                  >
                    <Button fullWidth onClick={() => setShowMobileFilter(true)}>
                      {IconService.getIcon("plus", iconStyles.filterIcon)}
                      Filter
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="filter-container">
                    <Sort />
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="product-grid-small">
                <div className="product-col">
                  <ProductCardSkeleton />
                </div>
                <div className="product-col">
                  <ProductCardSkeleton />
                </div>
                <div className="product-col">
                  <ProductCardSkeleton />
                </div>
              </div>
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : products ? (
              <>
                {products.length === 0 && (
                  <NoProductsContainer>
                    {search
                      ? `No Products Matching "${search}" Found`
                      : "No Products Found"}
                  </NoProductsContainer>
                )}
                <div className="product-grid-small">
                  {products.map((product) => {
                    return (
                      <div className="product-col" key={product._id}>
                        <ProductCard
                          key={product._id}
                          product={product}
                          showModal={openProductModal}
                        />
                      </div>
                    );
                  })}
                </div>
                <ProductModal
                  show={showProductModal}
                  product={selectedProduct}
                  closeModal={hideProductModal}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <MobileFilter
        show={showMobileFilter}
        closeDrawer={() => setShowMobileFilter(false)}
        omitType={'areas'}
      />
    </>
  );
};

export default connect(
  ({ productList, searchFilter }) => ({
    loading: productList.loading,
    error: productList.error,
    products: productList.products,
    searchString: productList.params?.searchString,
    search: searchFilter.search,
    brands: searchFilter.brands,
    searchBrands: productList.params?.brands,
    categories: searchFilter.categories,
    searchCategories: productList.params?.categories,
    concerns: searchFilter.concerns,
    searchConcerns: productList.params?.concerns,
    areasOfFocus: searchFilter.areasOfFocus,
    searchAreasOfFocus: productList.params?.areasOfFocus,
    prices: searchFilter.prices,
    searchPrices: productList.params?.prices,
    order: productList.params?.order,
    sortType: searchFilter.sortType,
  }),
  { listProducts }
)(ShopByAreaOfFocus);
