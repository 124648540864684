import React from "react";
import ContactForm from "../../components/contact/contactForm";


const ContactScreen = (props) => {
  return (
    <div className={'content-container'}>
      <div className="row center" style={{ marginTop: '60px' }}>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactScreen;
