import React from "react";
import { Link } from "react-router-dom";
import { BrandLogos } from "../../interfaces/enums";
import MiscService from "../../services/miscService";
import styles from './brandCard.module.css';

const BrandCard = ({ brandName }) => {
  return (
    <Link to={`/brands/${brandName}`}>
      <div className={styles.container}>
        <img
          src={BrandLogos[MiscService.formatBrandName(brandName)]}
          alt={brandName}
          className={`${styles.image} image-border`}
        />
        <div className={styles.name}>{brandName}</div>
      </div>
    </Link>
  );
};

export default BrandCard;
