import React from "react";
import IconService from "../../../services/iconService";
import Modal from "../../modal/modal";
import modalStyles from "../../modal/modal.module.css";
import iconStyles from "../../../styles/icon.module.css";
import Button from "../../button/button";




const ConfirmModal = ({ show, denyAction, message, confirmText, denyText, confirmAction }) => {
  return (
    <Modal show={show} onHide={denyAction}>
      <div className={modalStyles.header}>
        <span onClick={denyAction}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.title}>Are you sure?</div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.subheading} style={{marginTop: '5px'}}>{message}</div>
        <div style={{marginTop: '20px'}}>
          <Button variant={"no-border"} onClick={denyAction} fullWidth>
            {denyText ? denyText : 'Cancel'}
          </Button>
          <Button onClick={confirmAction} fullWidth>
            {confirmText ? confirmText : 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
