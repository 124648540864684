import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_SAVE_ADDRESS_REQUEST,
  USER_SAVE_ADDRESS_SUCCESS,
  USER_SAVE_ADDRESS_FAIL,
  USER_SAVE_ADDRESS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_DELETE_ADDRESS_REQUEST,
  USER_DELETE_ADDRESS_SUCCESS,
  USER_DELETE_ADDRESS_FAIL,
  USER_DELETE_ADDRESS_RESET,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_UPDATE_PASSWORD_RESET,
} from "../constants/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload.userInfo,
        tokenState: action.payload.token,
      };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload.userInfo,
        tokenState: action.payload.token,
      };
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    case "REFRESH_TOKEN_REQUEST":
      return { ...state, loading: true };
    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        loading: false,
        tokenState: action.payload,
        refreshError: null,
      };
    case "REFRESH_TOKEN_FAIL":
      return {
        ...state,
        loading: false,
        refreshError: action.payload,
      };
    default:
      return state;
  }
};

export const userListReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { loading: true };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userSaveAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SAVE_ADDRESS_REQUEST:
      return { loading: true };
    case USER_SAVE_ADDRESS_SUCCESS:
      return { loading: false, success: true };
    case USER_SAVE_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    case USER_SAVE_ADDRESS_RESET:
      return {};
    default:
      return state;
  }
};

export const userDeleteAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_ADDRESS_REQUEST:
      return { loading: true };
    case USER_DELETE_ADDRESS_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DELETE_ADDRESS_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PASSWORD_REQUEST:
      return { loading: true };
    case USER_UPDATE_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PASSWORD_RESET:
      return {};
    default:
      return state;
  }
};

export const userIsAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_IS_ADMIN_LOADING":
      return { loading: true };
    case "USER_IS_ADMIN":
      return { isAdmin: action.payload, success: true, loading: false };
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_FORGOT_PASSWORD_REQUEST":
      return { loading: true };
    case "USER_FORGOT_PASSWORD_SUCCESS":
      return { success: true, loading: false };
    case "USER_FORGOT_PASSWORD_FAIL":
      return {
        success: false,
        error: action.payload,
        loading: false,
      };
    case "USER_FORGOT_PASSWORD_RESET":
      return {
        success: false,
        error: false,
        loading: false,
      };
    default:
      return state;
  }
};

export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_RESET_PASSWORD_REQUEST":
      return { loading: true };
    case "USER_RESET_PASSWORD_SUCCESS":
      return { success: action.payload, loading: false };
    case "USER_RESET_PASSWORD_FAIL":
      return {
        success: false,
        error: action.payload,
        loading: false,
      };
    case "USER_RESET_PASSWORD_RESET":
      return {
        success: false,
        error: false,
        loading: false,
      };
    default:
      return state;
  }
};
