import React, { useEffect, useRef } from "react";
import inputStyles from "../checkout/checkoutInput/input.module.css";

let autoComplete;

const AddressSelector = (props) => {
  const autoCompleteRef = useRef(null);

  async function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect());
  }

  async function handlePlaceSelect() {
    const place = autoComplete.getPlace();
    props.handleAddressSelection(place);
    if (autoCompleteRef?.current) {
      autoCompleteRef.current.value =
        place.address_components[0].short_name +
        " " +
        place.address_components[1].short_name;
    }
  }

  useEffect(() => {
    if (window.google?.maps?.places?.Autocomplete) {
      handleScriptLoad(autoCompleteRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.google?.maps?.places]);

  return (
    <div>
      <label className={inputStyles.label}>Address</label>
      <input
        ref={autoCompleteRef}
        className={`${inputStyles.input} ${
          props.showError ? inputStyles.inputError : inputStyles.inputOutline
        }`}
        placeholder={"Enter an address"}
        onChange={(e) => props.handleChange(e.target.value)}
      />
    </div>
  );
};

export default AddressSelector;
