import React from "react";
import styles from "./orderImage.module.css";
const OrderImage = ({ orderItems }) => {
  if (orderItems.length === 1) {
    return (
      <img
        src={orderItems[0].images?.[0]}
        className={styles.oneImage}
        alt={"img"}
      />
    );
  }
  if (orderItems.length === 2) {
    return (
      <div className={styles.container}>
        <div className={styles.child}>
          <div className={styles.grid}>
            <div className={styles.firstContainer}>
              {" "}
              <img
                src={orderItems[0].images?.[0]}
                className={styles.oneImage}
                alt={"img"}
              />
            </div>
            <div className={styles.secondContainer}>
              {" "}
              <img
                src={orderItems[1].images?.[0]}
                className={styles.oneImage}
                alt={"img"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (orderItems.length >= 3) {
    return (
      <div className={styles.container}>
        <div className={styles.child}>
          <div className={styles.grid}>
            <div className={styles.firstContainerThree}>
              {" "}
              <img
                src={orderItems[0].images?.[0]}
                className={styles.oneImage}
                alt={"img"}
              />
            </div>
            <div className={styles.secondContainerThree}>
              {" "}
              <img
                src={orderItems[1].images?.[0]}
                className={styles.oneImage}
                alt={"img"}
              />
            </div>
            <div className={styles.thirdContainerThree}>
              {" "}
              <img
                src={orderItems[2].images?.[0]}
                className={styles.oneImage}
                alt={"img"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default OrderImage;
