import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSpecial, getSpecialDetails, updateSpecial } from '../../../actions/specialActions';
import Button from '../../../components/button/button';
import Checkbox from '../../../components/checkbox/checkbox';
import ImageUploader from '../../../components/image/ImageUploader';
import Input from '../../../components/input/input';
import LoadingCog from '../../../components/loadingCog';
import MessageBox from '../../../components/messageBox';
import TextArea from '../../../components/textarea/textarea';
import { Box, Flex, Title } from '../../../styles/utils';

const today = new Date().toLocaleDateString('en-ca');

const inFuture = (date) => {
    return date > today;
};

const SpecialCreateEditForm = ({ special, type }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isEdit = type === "edit";

    const { success, error, loading } = useSelector(state => state.specialCreate);

    const { error: errorSpecialDetails } = useSelector(state => state.specialDetails)

    const { success: updateSuccess, error: updateError, loading: updateLoading } = useSelector(state => state.specialUpdate)


    const [data, setData] = useState({
        title: "",
        description: "",
        image: "",
        price: "",
        visible: false,
        expiresOn: "",
        visibleOn: today,
    })

    useEffect(() => {
        if (special) {
            setData({
                title: special.title,
                description: special.description,
                image: special.image,
                price: special.price,
                visible: special.visible,
                expiresOn: special.expiresOn.split("T")[0],
                visibleOn: special?.visibleOn?.split("T")[0] ?? today,
            })
        }
    }, [special])

    const handleChange = useCallback((value, name) => {
        if (name === "visibleOn") {
            if (inFuture(value)) {
                setData(data => ({
                    ...data,
                    visible: false,
                }))
            }
            if (value > data.expiresOn) {
                setData(data => ({
                    ...data,
                    expiresOn: value,
                }))
            }

        }
        setData(data => ({
            ...data,
            [name]: value
        }))
    }, [data.expiresOn])


    const setImages = (images) => {
        setData(data => ({
            ...data,
            image: images[0]
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isVisible = inFuture(data.visibleOn) ? false : data.visible;
        dispatch(isEdit ? updateSpecial({ _id: special._id, visible: isVisible, ...data }) : createSpecial({ visible: isVisible, ...data }));
    }


    useEffect(() => {
        if (success) {
            history.push("/specials");
            dispatch({ type: "SPECIAL_CREATE_RESET" });
        }
        if (updateSuccess) {
            history.push("/specials");
            dispatch({ type: "SPECIAL_UPDATE_RESET" });
            special?._id && dispatch(getSpecialDetails(special._id))

        }
    }, [success, history, dispatch, updateSuccess, special?._id])

    const isSubmittable = () => {
        return !!data.title && !!data.description && !!data.image && !!data.price && !!data.expiresOn && !!data.visibleOn
    }

    return (
        <div className="content-container" style={{ maxWidth: "600px" }}>
            <Flex justifyContent="center">
                <Title>{isEdit ? "Edit" : "New"} Special</Title>
            </Flex>
            {error &&
                <Box margin={"10px 0"}>
                    <MessageBox variant="danger">{error}</MessageBox>
                </Box>
            }
            {errorSpecialDetails &&
                <Box margin={"10px 0"}>
                    <MessageBox variant="danger">{errorSpecialDetails}</MessageBox>
                </Box>
            }
            {updateError &&
                <Box margin={"10px 0"}>
                    <MessageBox variant="danger">{updateError}</MessageBox>
                </Box>
            }
            <Box margin="10px 0">
                <ImageUploader {...{
                    images: data.image ? [data.image] : [],
                    multiple: false,
                    setImages
                }} />
            </Box>
            <Box margin="10px 0">
                <Input
                    name="Title"
                    type="text"
                    placeholder="Enter title"
                    value={data.title}
                    onChange={(val) => handleChange(val, "title")}
                    withLabel
                    showStar
                    required
                />
            </Box>
            <Box margin="10px 0">
                <TextArea
                    name={"Description"}
                    type="text"
                    placeholder="Enter description"
                    value={data.description}
                    onChange={(val) => handleChange(val, "description")}
                    withLabel
                    rows={6}
                    showStar
                    required
                />
            </Box>
            <Box margin="10px 0">
                <Input
                    name="Price"
                    type="text"
                    placeholder="Enter price"
                    value={data.price}
                    onChange={(val) => handleChange(val, "price")}
                    withLabel
                    showStar
                    required
                />
            </Box>
            <Box margin="15px 0">
                <Checkbox
                    checked={data.visible}
                    handleCheck={() => handleChange(!data.visible, "visible")}
                    label={"Visible?"}
                    name={"Visibility Checkbox"}
                    disabled={inFuture(data.visibleOn)}
                />
                {inFuture(data.visibleOn) && <Box margin="5px 0 0">The special will become visible on the <i>visible on</i> date specified below</Box>}
            </Box>
            <Box margin="10px 0">
                <Input
                    name="Visible on"
                    type="date"
                    placeholder="Enter date"
                    value={data.visibleOn}
                    onChange={(val) => handleChange(val, "visibleOn")}
                    withLabel
                    showStar
                    required
                    min={today}
                />
            </Box>
            <Box margin="10px 0">
                <Input
                    name="Expires on"
                    type="date"
                    placeholder="Enter date"
                    value={data.expiresOn}
                    onChange={(val) => handleChange(val, "expiresOn")}
                    withLabel
                    showStar
                    required
                    min={data.visibleOn}
                />
            </Box>
            <Box margin="20px 0">
                <Button fullWidth type="submit" onClick={handleSubmit} disabled={loading || !isSubmittable() || errorSpecialDetails || updateLoading}>
                    {loading ? <LoadingCog /> : `${isEdit ? "Update" : "Create"} Special`}
                </Button>
            </Box>
        </div>
    );
};

export default SpecialCreateEditForm;