import React from "react";
import ContactView from "../../components/contact/contactView";

const ContactEditScreen = (props) => {
  const contactId = props.match.params.id;

  return <ContactView {...{ contactId }} />;
};

export default ContactEditScreen;
