import React from "react";
import { Link } from "react-router-dom";
import styles from "./breadcrumb.module.css";

const Breadcrumb = ({ options }) => {
  return (
    <div className={styles.container}>
      {options.map(
        ({ path, name }, index) => {
          if (
            index <
            options.length - 1
          ) {
            return (
              <>
                <div
                  className={
                    styles.linkContainer
                  }
                  key={index}
                >
                  <Link to={path}>
                    <div
                      className={
                        styles.link
                      }
                    >
                      {name}
                    </div>
                  </Link>
                </div>

                <div
                  className={
                    styles.slash
                  }
                >
                  &nbsp;&#47;&nbsp;
                </div>
              </>
            );
          } else {
            return (
              <div
                className={
                  styles.linkActive
                }
                key={index}
              >
                {name}
              </div>
            );
          }
        }
      )}
    </div>
  );
};

export default Breadcrumb;
