export const brandToggle = (brand) => (dispatch, getState) => {
  const {
    searchFilter: { brands },
  } = getState();
  const updatedBrands = brands;
  const index = updatedBrands.indexOf(brand);
  if (index > -1) {
    dispatch({
      type: "SET_BRANDS",
      payload: updatedBrands.filter((b) => b !== brand),
    });
  } else {
    dispatch({
      type: "SET_BRANDS",
      payload: [...updatedBrands, brand],
    });
  }
};
export const categoryToggle = (category) => (dispatch, getState) => {
  const {
    searchFilter: { categories },
  } = getState();
  const updatedCategories = categories;
  const index = updatedCategories.indexOf(category);
  if (index > -1) {
    dispatch({
      type: "SET_CATEGORIES",
      payload: updatedCategories.filter((c) => c !== category),
    });
  } else {
    dispatch({
      type: "SET_CATEGORIES",
      payload: [...updatedCategories, category],
    });
  }
};
export const concernToggle = (concern) => (dispatch, getState) => {
  const {
    searchFilter: { concerns },
  } = getState();
  const updatedConcerns = concerns;
  const index = updatedConcerns.indexOf(concern);
  if (index > -1) {
    dispatch({
      type: "SET_CONCERNS",
      payload: updatedConcerns.filter((c) => c !== concern),
    });
  } else {
    dispatch({
      type: "SET_CONCERNS",
      payload: [...updatedConcerns, concern],
    });
  }
};
export const areaOfFocusToggle = (area) => (dispatch, getState) => {
  const {
    searchFilter: { areasOfFocus },
  } = getState();
  let updatedAreas = areasOfFocus;
  const index = updatedAreas.indexOf(area);
  if (index > -1) {
    dispatch({
      type: "SET_AREAS_OF_FOCUS",
      payload: updatedAreas.filter((a) => a !== area),
    });
  } else {
    dispatch({
      type: "SET_AREAS_OF_FOCUS",
      payload: [...updatedAreas, area],
    });
  }
};
export const priceToggle = (price) => (dispatch, getState) => {
  const {
    searchFilter: { prices },
  } = getState();
  let updatedPrices = prices;
  const index = updatedPrices.indexOf(price);
  if (index > -1) {
    dispatch({
      type: "SET_PRICES",
      payload: updatedPrices.filter((a) => a !== price),
    });
  } else {
    dispatch({
      type: "SET_PRICES",
      payload: [...updatedPrices, price],
    });
  }
};

export const changeFilter = (type) => (dispatch) => {
    dispatch({
        type: "SET_SORT_FILTER",
        payload: type
    })
}

export const clearFilters = () => dispatch => {
    dispatch({
        type: 'RESET_FILTERS'
    })
}