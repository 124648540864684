import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleSearch } from "../../actions/generalActions";
import IconService from "../../services/iconService";
import searchStyles from "./search.module.css";
import SearchDropdown from "./searchDropdown";

const SearchMobile = ({
  loading,
  data,
  error,
  handleSearch,
  closeSearch,
  ...props
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [searchExpanded, setSearchExpanded] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSearchExpanded(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue) {
        handleSearch(searchValue);
      } else {
        dispatch({ type: "SEARCH_RESET" });
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, handleSearch, dispatch]);

  const clearSearch = () => {
    setSearchValue("");
    closeSearch();
  };

  useEffect(() => {
    if (!searchExpanded) {
      clearSearch();
      dispatch({ type: "SEARCH_RESET" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchExpanded, dispatch]);

  const redirectToSearchPage = (event) => {
    if (event.key === "Enter") {
      dispatch({ type: "SET_SEARCH_VALUE", payload: searchValue });
      history.push(`/search`);
      setSearchExpanded(false);
      setSearchValue("");
      if (inputRef?.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <div
      className={searchStyles.searchContainer}
      onClick={() => setSearchExpanded(true)}
    >
      <div className={searchStyles.containerMobile} ref={dropdownRef}>
        {IconService.getIcon("search", searchStyles.searchIcon)}
        <input
          ref={inputRef}
          type={props.type}
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
          name={props.name}
          placeholder={props.placeholder}
          className={searchStyles.search}
          onKeyPress={redirectToSearchPage}
        />
        <SearchDropdown {...{ clearSearch, isMobile: true }} />
      </div>
    </div>
  );
};

export default connect(
  ({ generalSearch }) => ({
    loading: generalSearch.loading,
    data: generalSearch.data,
    error: generalSearch.error,
  }),
  { handleSearch }
)(SearchMobile);
