export const generalSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case "SEARCH_REQUEST":
      return { loading: true };
    case "SEARCH_SUCCESS":
      return { loading: false, data: action.payload };
    case "SEARCH_FAIL":
      return { loading: false, error: action.payload };
    case "SEARCH_RESET":
      return {};
    default:
      return state;
  }
};
