import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getContactRequests,
} from "../../actions/contactActions";
import Button from "../../components/button/button";
import dateFormat from "../../components/dateFormat";
import LoadingBox from "../../components/loadingBox";
import MessageBox from "../../components/messageBox";

const ContactListScreen = ({
  getContactRequests,
  loading,
  error,
  contacts,
}) => {
  useEffect(() => {
    getContactRequests();
  }, [getContactRequests]);

  return (
    <>
      <div className="content-container">
        <h1>Contact Requests</h1>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <div style={{ overflow: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>USER</th>
                  <th>DATE</th>
                  <th>SUBJECT</th>
                  <th>MESSAGE</th>
                  <th>READ?</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {contacts?.map((contact) => {
                  return (
                    <tr key={contact._id}>
                      <td>{contact._id}</td>
                      <td>{contact.name}</td>
                      <td>{dateFormat(contact.createdAt)}</td>
                      <td>{contact.subject}</td>
                      <td>{contact.message}</td>
                      <td>{contact.isRead ? "YES" : "NO"}</td>
                      <td>
                        {/* TODO: add actions for contact requests */}
                        <Link to={`/contactlist/${contact._id}`}>
                          <Button type="button" className="small">
                            See More
                          </Button>
                        </Link>
                        {/* <button
                          type="button"
                          className="small"
                          onClick={() => deleteHandler(contact)}
                        >
                          Delete
                        </button> */}
                      </td>{" "}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(
  ({ getAllContacts }) => ({
    loading: getAllContacts.loading,
    error: getAllContacts.error,
    contacts: getAllContacts.contacts,
  }),
  {
    getContactRequests,
  }
)(ContactListScreen);
