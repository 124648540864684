import React from "react";
import screenStyles from '../../../styles/screen.module.css';
import checkoutStyles from '../../../styles/checkout.module.css';
import cardStyles from './checkoutCard.module.css';

const AddressCard = (props) => {
  return (
    <div className={screenStyles.backgroundContainer}>
      <div
        className={checkoutStyles.subheading}
        style={{ marginBottom: "10px" }}
      >
       <div className={"row"}>
        <div className={checkoutStyles.subheading}>Sending To</div>
        <div className={checkoutStyles.editLink} onClick={() => props.handleEdit('shipping')}>
          edit
        </div>
      </div>
      </div>
      <div className={checkoutStyles.inputPadding + ' ' + cardStyles.row} style={{alignItems: 'start'}}>
        <div className={cardStyles.key}>Address</div>
        <div className={cardStyles.value}>
        {props.shippingState?.firstName} {props.shippingState?.lastName}<br />
        {props.shippingState?.address}{props.shippingState?.address2 && ', ' + props.shippingState.address2}<br />
        {props.shippingState?.city}, {props.shippingState?.state} {props.shippingState?.zipcode}
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
