import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listOrderMine } from "../../../actions/orderActions";
import contentLayoutStyles from "../../../styles/contentLayout.module.css";
import styles from "./userOrderList.module.css";
import LoadingCog from "../../loadingCog";
import MessageBox from "../../messageBox";
import OrderCard from "./orderCard/orderCard";
import dateFormat from "../../dateFormat";
import Input from "../../input/input";

const UserOrderList = () => {
  const orderMineList = useSelector((state) => state.orderMineList);
  const { loading, error, orders } = orderMineList;
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("all");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(listOrderMine());
  }, [dispatch]);

  const changeTab = (tab) => {
    setSelectedTab(tab);
  };
  const orderFilter = (order) => {
    if (selectedTab === 'all') return order;
    else if (selectedTab === 'shipped') {
      if (order.isShipped && !order.isDelivered) return order;
    } else if (selectedTab === 'delivered') {
      if (order.isDelivered) return order;
    }
  };
  const searchFilter = (order) => {
    if (!searchValue) return order;

    if (order._id.toLowerCase().includes(searchValue.toLowerCase())) return order;
    else if (
      ("order #" + order._id)
        .toLowerCase()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
      return order;
    else if (
      dateFormat(order.createdAt)
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
      return order;
    else if (order.totalPrice.toString().toLowerCase().includes(searchValue.toLowerCase())) return order;
    let items = order.orderItems.reduce((a, c) => a + c.qty, 0);

    if ((items.toString() + ' items').toLowerCase().includes(searchValue.toLowerCase())) return order;
  }
  return (
    <>
      {loading ? (
        <div className="row center" style={{height: '100%'}}>
          <LoadingCog isLarge={true} />
        </div>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className={contentLayoutStyles.backgroundContainer}>
          <div className={styles.title}>My Orders</div>
          {!orders?.length ? (
            <div className={contentLayoutStyles.noData}>No Orders</div>
          ) : (
            <>
              <div className={styles.row}>
                <div
                  className={`${styles.tab} ${
                    selectedTab === "all" ? styles.selected : ""
                  }`}
                  onClick={() => changeTab("all")}
                >
                  All
                </div>
                <div
                  className={`${styles.tab} ${
                    selectedTab === "shipped" ? styles.selected : ""
                  }`}
                  onClick={() => changeTab("shipped")}
                >
                  Shipped
                </div>
                <div
                  className={`${styles.tab} ${
                    selectedTab === "delivered" ? styles.selected : ""
                  }`}
                  onClick={() => changeTab("delivered")}
                >
                  Delivered
                </div>
              </div>
              <div className={styles.inputContainer}>
                <Input
                  width={"100%"}
                  onChange={setSearchValue}
                  value={searchValue}
                  placeholder={"Search"}
                />
              </div>
              <div className={styles.ordersContainer}>
                {orders
                  .filter(orderFilter)
                  .filter(searchFilter)
                  .map((order, index) => {
                    return (
                      <>
                        <OrderCard {...{ order }} />
                      </>
                    );
                  })}
                {!orders.filter(orderFilter).filter(searchFilter).length && (
                  <div className={contentLayoutStyles.noData}>
                    No orders found
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UserOrderList;
