import React from 'react';
import SpecialCreateEditForm from './SpecialCreateEditForm';


const SpecialsCreateScreen = () => {
    return (
        <SpecialCreateEditForm type="create" />
    );
};

export default SpecialsCreateScreen;