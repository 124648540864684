import React from "react";
import IconService from "../../services/iconService";
import Modal from "../modal/modal";
import modalStyles from "../modal/modal.module.css";
import iconStyles from "../../styles/icon.module.css";
import Button from "../button/button";

const RemoveModal = ({ show, closeModal, handleRemove, message, confirmButton, rejectButton }) => {
  function hideModal() {
    closeModal();
  }

  return (
    <Modal show={show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div>
        <div className={"row center"} style={{ marginBottom: "10px" }}>
          <div className={modalStyles.titleSmall}>{message}</div>
        </div>
        <div className={""}>
          <div className={modalStyles.modalContent}>
            <div style={{ marginTop: "20px" }}>
              <Button
                width={"100%"}
                onClick={hideModal}
                height={"50px"}
                variant={"no-border"}
              >
                {rejectButton}
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button onClick={handleRemove} width={"100%"} height={"50px"}>
                {confirmButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveModal;
