import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import IconService from "../../services/iconService";
import HorizontalLine from "../horizontalLine";
import MobileFilterSection from "./mobileFilterSection";
import {
  getAllBrands,
  getAllCategories,
  getAllAreasOfFocus,
  getAllConcerns,
} from "../../actions/productActions";
import {
  brandToggle,
  categoryToggle,
  concernToggle,
  areaOfFocusToggle,
  changeFilter,
  clearFilters,
} from "../../actions/searchActions";
import { MobileTitle } from "./styles";
import styled from "styled-components";
import mobileStyles from "../navbar/mobileNavbar.module.css";
import Button from "../button/button";
import LoadingCog from "../loadingCog";
import MobileSortSection from "./mobileSortSection";
import MobilePriceSection from "./mobilePriceSection";
import styles from './styles.module.css';

const TopContainer = styled.div`
  box-sizing: border-box;
  padding: 15px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--card-outline-color);
`;
const BottomContainer = styled.div`
  box-sizing: border-box;
  padding: 15px 10px;
  width: 100%;
  border-top: 1px solid var(--card-outline-color);
`;

const MobileFilter = ({
  brands,
  getAllBrands,
  categories,
  getAllCategories,
  concerns,
  getAllConcerns,
  areasOfFocus,
  getAllAreasOfFocus,
  selectedBrands,
  selectedCategories,
  selectedConcerns,
  selectedAreasOfFocus,
  brandToggle,
  categoryToggle,
  concernToggle,
  areaOfFocusToggle,
  show,
  closeDrawer,
  products,
  sortType,
  changeFilter,
  prices,
  search,
  searchBrands,
  searchCategories,
  searchConcerns,
  searchAreas,
  clearFilters,
  omitType,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [show]);
  useEffect(() => {
    if (!brands) {
      getAllBrands();
    }
    if (!categories) {
      getAllCategories();
    }
    if (!concerns) {
      getAllConcerns();
    }
    if (!areasOfFocus) {
      getAllAreasOfFocus();
    }
  }, [
    areasOfFocus,
    brands,
    categories,
    concerns,
    getAllAreasOfFocus,
    getAllBrands,
    getAllCategories,
    getAllConcerns,
  ]);

  const checkShow = useMemo(() => {
    return (
      (omitType === "brands" ? "" : !!searchBrands.length) ||
      (omitType === "categories" ? "" : !!searchCategories.length) ||
      (omitType === "concerns" ? "" : !!searchConcerns.length) ||
      (omitType === "areas" ? "" : !!searchAreas.length) ||
      !!prices.length ||
      !!sortType.length ||
      !!search.length
    );
  }, [
    searchBrands,
    searchCategories,
    searchConcerns,
    searchAreas,
    prices,
    sortType,
    search,
    omitType,
  ]);

  return (
      <div className={`${styles.mobileFilterContainer} ${show ? styles.mobileFilterOpen : ''}`}>
        <TopContainer>
          <MobileTitle>Filters</MobileTitle>
          <div onClick={closeDrawer}>
            {IconService.getIcon("close", mobileStyles.closeIcon)}
          </div>
        </TopContainer>
        <div
          className={mobileStyles.linkContainer}
          style={{ padding: "0 15px" }}
        >
          <MobileSortSection
            {...{
              selected: sortType,
              handleCheck: changeFilter,
              items: [
                {
                  value: "",
                  text: "None",
                },
                {
                  value: "lowest",
                  text: "Price: Low-High",
                },
                {
                  value: "highest",
                  text: "Price: High-Low",
                },
                {
                  value: "toprated",
                  text: "Rating",
                },
              ],
            }}
          />
          <HorizontalLine />
          {omitType !== "brands" && (
            <>
              <MobileFilterSection
                {...{
                  title: "Brands",
                  items: brands,
                  handleCheck: brandToggle,
                  selected: selectedBrands,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "categories" && (
            <>
              <MobileFilterSection
                {...{
                  title: "Categories",
                  items: categories,
                  handleCheck: categoryToggle,
                  selected: selectedCategories,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "concerns" && (
            <>
              <MobileFilterSection
                {...{
                  title: "Concerns",
                  items: concerns,
                  handleCheck: concernToggle,
                  selected: selectedConcerns,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "areas" && (
            <>
              <MobileFilterSection
                {...{
                  title: "Areas of Focus",
                  items: areasOfFocus,
                  handleCheck: areaOfFocusToggle,
                  selected: selectedAreasOfFocus,
                }}
              />
              <HorizontalLine />
            </>
          )}
          <MobilePriceSection />
          <HorizontalLine />
        </div>
        <BottomContainer>
          {checkShow && (
            <div style={{ marginBottom: "10px" }}>
              <Button
                variant="only-border"
                fullWidth
                height={"40px"}
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            </div>
          )}
          <Button fullWidth onClick={closeDrawer} height={"40px"}>
            {products ? (
              <>
                View Item{products.length !== 1 && "s"} ({products.length})
              </>
            ) : (
              <LoadingCog />
            )}
          </Button>
        </BottomContainer>
      </div>
    )
};

export default connect(
  ({
    getBrands,
    getCategories,
    getConcerns,
    getAreasOfFocus,
    searchFilter,
    productList,
  }) => ({
    products: productList.products,
    brands: getBrands.brands,
    categories: getCategories.categories,
    concerns: getConcerns.concerns,
    areasOfFocus: getAreasOfFocus.areasOfFocus,
    selectedBrands: searchFilter.brands,
    selectedCategories: searchFilter.categories,
    selectedConcerns: searchFilter.concerns,
    selectedAreasOfFocus: searchFilter.areasOfFocus,
    sortType: searchFilter.sortType,
    prices: searchFilter.prices,
    search: searchFilter.search,
    searchBrands: searchFilter.brands,
    searchCategories: searchFilter.categories,
    searchConcerns: searchFilter.concerns,
    searchAreas: searchFilter.areasOfFocus,
  }),
  {
    getAllBrands,
    getAllCategories,
    getAllConcerns,
    getAllAreasOfFocus,
    brandToggle,
    categoryToggle,
    concernToggle,
    areaOfFocusToggle,
    changeFilter,
    clearFilters,
  }
)(MobileFilter);
