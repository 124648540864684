import React from 'react';
import styles from './checkbox.module.css';
const CheckboxSmall = (props) => {
    return (
      <label className={styles.container}>
        {props.label}
        <input
          name={props.name}
          type="checkbox"
          checked={props.checked}
          onChange={props.handleCheck}
        />
        <span className={styles.checkmark}></span>
      </label>
    );
};

export default CheckboxSmall;