import React from 'react';
import styled from 'styled-components';


const TitleContainer = styled.div`
    width: 100%;
    font-size: 20px;
    color: var(--primary-dark);
    font-weight: 600;
    text-align: center;
    margin: 10px 0 5px 0;
`

const SubTitle = ({text, children}) => {
    return (
        <TitleContainer>
            {text ? text : children}
        </TitleContainer>
    );
};

export default SubTitle;