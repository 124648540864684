import React, { useEffect, useRef, useState } from "react";
import modalStyles from "./modal.module.css";
import ReactDOM from "react-dom";

const Modal = (props) => {
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [displayNone, setDisplayNone] = useState(true);
  useEffect(() => {
    if (props.show) {
      setShow(true);
      setDisplayNone(false);
      // document.body.style.overflow = "hidden";
    } else {
      handleHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

   useEffect(() => {
     if (props.show) {
       document.body.style.overflow = "hidden";
     }
     return () => {
       document.body.style.overflow = "unset";
     };
   }, [props.show]);

  function handleHide() {
    setShow(false);
    setTimeout(() => {
      setDisplayNone(true);
      // document.body.style.overflow = "visible";
      props.onHide();
    }, 500);
  }

  function handleClickOutside(event) {
    if (
      props.show &&
      !props.noOutsideClick &&
      containerRef?.current &&
      !containerRef.current.contains(event.target)
    ) {
      handleHide();
    }
  }
  return ReactDOM.createPortal(
    <div
      className={`${modalStyles.modal} ${
        show
          ? modalStyles.show
          : displayNone
          ? modalStyles.displayNone
          : modalStyles.hide
      }`}
    >
      <div
        className={`${show ? modalStyles.showModal : modalStyles.hideModal} ${
          props.isLarge ? modalStyles.containerLarge : modalStyles.container
        }`}
        ref={containerRef}
      >
        {props.children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
