import React, { useEffect } from "react";

import ResetPasswordCard from "../components/password/resetPasswordCard";

const ResetPasswordScreen = () => {
  useEffect(() => {
    document.title = "Reset Password | Shop at ShotDocMD";
  }, []);
  return (
    <div className={"content-container"}>
      <div className="row center" style={{ marginTop: "60px" }}>
        <ResetPasswordCard />
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
