import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconService from "../../../services/iconService";
import productCardStyles from "./productCard.module.css";

class ProductCard extends Component {
  constructor(props) {
    super(props);

    this.handleQuickGlance = this.handleQuickGlance.bind(this);
  }
  handleQuickGlance(e) {
    const { showModal } = this.props;
    e.preventDefault();
    e.stopPropagation();
    showModal(this.props.product);
  }
  render() {
    const { product } = this.props;
    return (
      <Link to={`/product/${product._id}`}>
        <div className={productCardStyles.container}>
          <div className={productCardStyles.imageContainer}>
            <div className={productCardStyles.imageContent}>
              <img
                src={
                  product.images
                    ? product.images[0]
                    : IconService.defaultProductImage
                }
                alt={product.name}
                className={`${productCardStyles.image} image-border`}
              />
            </div>
            <div
              className={productCardStyles.imageOverlay}
              onClick={this.handleQuickGlance}
            >
              Quick Peek
            </div>
          </div>
          <div className={productCardStyles.cardTextContainerNoReviews}>
            <div>
              <div className={productCardStyles.productName}>
                {product.name}
              </div>
              <div className={productCardStyles.productDescription}>
                {product.description}
              </div>
            </div>
            <div className={productCardStyles.priceContainer}>
              ${product.price}&nbsp;
              <span className={productCardStyles.currency}>USD</span>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default ProductCard;
