import axios from "axios";




export const getBaseUrl = () => {
    // for local dev
    if (window.location.origin === "http://localhost:3000") {
        return "http://127.0.0.1:4000";
    }
    return "https://api.shotdocslo.com";
}


export const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: getBaseUrl(),
})