import { axiosInstance } from "../api";

export const sendContactForm = (form) => async (dispatch) => {
  dispatch({ type: "CONTACT_REQUEST" });
  try {
    // eslint-disable-next-line no-unused-vars
    const { data } = await axiosInstance.post(`/contact`, form);
    dispatch({ type: "CONTACT_SUCCESS" });
  } catch (error) {
    dispatch({
      type: "CONTACT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getContactRequests = () => async (dispatch, getState) => {
  dispatch({ type: "GET_CONTACT_REQUEST" });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.get(`/contact`, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: "GET_CONTACT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CONTACT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getContactRequestById = (id) => async (dispatch, getState) => {
  dispatch({ type: "GET_CONTACT_BY_ID_REQUEST" });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.get(`/contact/${id}`, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: "GET_CONTACT_BY_ID_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CONTACT_BY_ID_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const markMessageAsRead = (messageId) => async (dispatch, getState) => {
  dispatch({ type: "MARK_AS_READ_REQUEST" });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.put(
      `/contact/${messageId}`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
      }
    );
    dispatch({ type: "MARK_AS_READ_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "MARK_AS_READ_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const replyToContact = (messageBody) => async (dispatch, getState) => {
  dispatch({ type: "REPLY_TO_MESSAGE_REQUEST" });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.post(
      `/contact/reply`,
      { messageBody },
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
      }
    );
    dispatch({ type: "REPLY_TO_MESSAGE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "REPLY_TO_MESSAGE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
