import React from "react";
import { Link } from "react-router-dom";
import styles from './index.module.css';



const SectionCard = ({ type, image }) => {
  return (
    <Link to={type === 'Brand' ? '/brands' : '/categories'}>
      <div className={styles.cardContainer}>
        <img className={styles.image} src={image} alt={"type"} />
        <div className={styles.textContainer}>
            <div className={styles.title}>Browse by {type}</div>
        </div>
      </div>
    </Link>
  );
};

export default SectionCard;
