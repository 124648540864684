import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faShoppingCart,
  faSortDown,
  faSpinner,
  faTimes,
  faTrashAlt,
  faUserCircle,
  faSignOutAlt,
  faUserShield,
  faBars,
  faAngleDown,
  faAngleUp,
  faStar,
  faMinus,
  faEye,
  faEyeSlash,
  faChevronLeft,
  faUser,
  faEnvelope,
  faInfoCircle,
  faEdit,
  faCaretRight,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faUserCircle as faUserCircleOutline,
  faStar as faStarOutline,
  faCopyright,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faGoogle, faInstagram, faYelp } from "@fortawesome/free-brands-svg-icons";

import defaultImage from "../images/default-product.png";

const IconService = {
  defaultProductImage: defaultImage,

  getIcon(optionName, className, styleName = "") {
    if (optionName.toLowerCase() === "spinner") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faSpinner}
        />
      );
    } else if (optionName.toLowerCase() === "trash") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faTrashAlt}
        />
      );
    } else if (optionName.toLowerCase() === "edit") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faEdit}
        />
      );
    } else if (optionName.toLowerCase() === "plus") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faPlus}
        />
      );
    } else if (optionName.toLowerCase() === "minus") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faMinus}
        />
      );
    } else if (optionName.toLowerCase() === "sort-down") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faSortDown}
        />
      );
    } else if (optionName.toLowerCase() === "close") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faTimes}
        />
      );
    } else if (optionName.toLowerCase() === "user") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faUserCircleOutline}
        />
      );
    } else if (optionName.toLowerCase() === "useralt") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faUser}
        />
      );
    } else if (optionName.toLowerCase() === "usercircle") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faUserCircle}
        />
      );
    } else if (optionName.toLowerCase() === "cart") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faShoppingCart}
        />
      );
    } else if (optionName.toLowerCase() === "search") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faSearch}
        />
      );
    } else if (optionName.toLowerCase() === "signout") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faSignOutAlt}
        />
      );
    } else if (optionName.toLowerCase() === "admin") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faUserShield}
        />
      );
    } else if (optionName.toLowerCase() === "menu") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faBars}
        />
      );
    } else if (optionName.toLowerCase() === "down") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faAngleDown}
        />
      );
    } else if (optionName.toLowerCase() === "up") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faAngleUp}
        />
      );
    } else if (optionName.toLowerCase() === "right") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faAngleRight}
        />
      );
    } else if (optionName.toLowerCase() === "left") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faAngleLeft}
        />
      );
    } else if (optionName.toLowerCase() === "star-solid") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faStar}
        />
      );
    } else if (optionName.toLowerCase() === "star-outline") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faStarOutline}
        />
      );
    } else if (optionName.toLowerCase() === "eye-open") {
      return (
        <FontAwesomeIcon className={className} style={styleName} icon={faEye} />
      );
    } else if (optionName.toLowerCase() === "eye-closed") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faEyeSlash}
        />
      );
    } else if (optionName.toLowerCase() === "back") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faChevronLeft}
        />
      );
    } else if (optionName.toLowerCase() === "mail") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faEnvelope}
        />
      );
    } else if (optionName.toLowerCase() === "info") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faInfoCircle}
        />
      );
    } else if (optionName.toLowerCase() === "caretright") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faCaretRight}
        />
      );
    } else if (optionName.toLowerCase() === "copyright") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faCopyright}
        />
      );
    } else if (optionName.toLowerCase() === "facebook") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faFacebook}
        />
      );
    } else if (optionName.toLowerCase() === "instagram") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faInstagram}
        />
      );
    } else if (optionName.toLowerCase() === "yelp") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faYelp}
        />
      );
    } else if (optionName.toLowerCase() === "google") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faGoogle}
        />
      );
    } else if (optionName.toLowerCase() === "left-arrow") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faChevronLeft}
        />
      );
    } else if (optionName.toLowerCase() === "right-arrow") {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faChevronRight}
        />
      );
    } else if (optionName.toLowerCase() === 'calendar') {
      return (
        <FontAwesomeIcon
          className={className}
          style={styleName}
          icon={faCalendar}
        />
      );
    }
  },
};

export default IconService;
