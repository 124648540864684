import React from "react";
import buttonStyles from "./button.module.css";
const Button = (props) => {
  let style = "";
  if (props.variant === "success") {
    style = buttonStyles.success;
  } else if (props.variant === "alert") {
    style = buttonStyles.alert;
  } else if (props.variant === "no-border") {
    style = buttonStyles.noBorder;
  } else if (props.variant === "only-border") {
    style = buttonStyles.onlyBorder;
  } else {
    style = buttonStyles.general;
  }
  return (
    <button
      style={
        props.padding
          ? { padding: `${props.padding}` }
          : props.fullWidth && !props.height
          ? { width: "100%", padding: "10px" }
          : props.height && props.fullWidth
          ? { height: `${props.height}`, width: "100%", padding: "10px" }
          : props.height && !props.fullWidth && !props.width
          ? { height: `${props.height}` }
          : props.height && props.width
          ? {
              height: `${props.height}`,
              width: `${props.width}`,
            }
          : props.width
          ? { width: `${props.width}` }
          : {}
      }
      disabled={props.disabled ? props.disabled : false}
      className={`${style} ${buttonStyles.button}`}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
