import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import logo from "../../images/logo.png";
import logoSmall from "../../images/logo-small.png";
import IconService from "../../services/iconService";
import SignInModal from "../signinModal/signinModal";
import SignUpModal from "../signUpModal/signUpModal";
import { useSelector } from "react-redux";
const Footer = () => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  function openSignInModal() {
    setShowSignInModal(true);
  }
  function hideSignInModal() {
    setShowSignInModal(false);
  }
  function hideCreateAccountModal() {
    setShowCreateAccountModal(false);
  }
  function openCreateAccountModal() {
    setShowCreateAccountModal(true);
  }

  const signInToAccountCreate = () => {
    setShowSignInModal(false);
    setShowCreateAccountModal(true);
  };

  const accountCreateToSignIn = () => {
    setShowSignInModal(true);
    setShowCreateAccountModal(false);
  };
  return (
    <>
      <footer>
        <div className="content-container" style={{ marginBottom: 0 }}>
          <div className={styles.rowCenter}>
            <img src={logo} className={styles.logo} alt="logo" />
          </div>
          <div className={styles.content}>
            <div className={styles.column}>
              <div>
                {userInfo ? (
                  <Link to={"/my-account"}>
                    <div className={styles.title}>My Account</div>
                  </Link>
                ) : (
                  <div className={styles.title}>My Account</div>
                )}
                {!userInfo && (
                  <>
                    <div className={styles.tabItem} onClick={openSignInModal}>
                      Sign In
                    </div>
                    <div
                      className={styles.tabItem}
                      onClick={openCreateAccountModal}
                    >
                      Register
                    </div>
                  </>
                )}
                <Link to={"/find-order"}>
                  <div className={styles.tabItem}>Order Status</div>
                </Link>
                <div></div>
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <div className={styles.title}>Help</div>
                <Link to={"/contact"}>
                  <div className={styles.tabItem}>Contact Us</div>
                </Link>
                {/* <Link to={"/find-order"}>
                  <div className={styles.tabItem}>Join Newsletter</div>
                </Link> */}

                <Link to={"/returns"}>
                  <div className={styles.tabItem}>Returns</div>
                </Link>
                <Link to={"/shipping"}>
                  <div className={styles.tabItem}>Shipping</div>
                </Link>
                <div></div>
              </div>
            </div>
            <div className={styles.column}>
              <div>
                <div className={styles.title}>Company</div>
                <a
                  href={"https://www.shotdocmd.com/contact/about"}
                  rel="noreferrer"
                >
                  <div className={styles.tabItem}>About Us</div>
                </a>
                <a
                  href={
                    "https://clients.mindbodyonline.com/classic/ws?studioid=598760&stype=-9"
                  }
                  target={"_blank"}
                  rel="noreferrer noopener"
                >
                  <div className={styles.tabItem}>Book Now</div>
                </a>{" "}
                <div className={styles.row}>
                  <a
                    href={"https://www.facebook.com/shotdocmd"}
                    target={"_blank"}
                    rel="noreferrer noopener"
                    className={styles.linkIcon}
                  >
                    {IconService.getIcon("facebook")}
                  </a>
                  <a
                    href={"https://www.instagram.com/shotdocmd/?hl=en"}
                    target={"_blank"}
                    rel="noreferrer noopener"
                    className={styles.linkIcon}
                  >
                    {" "}
                    {IconService.getIcon("instagram")}
                  </a>
                  <a
                    href={"https://www.yelp.com/biz/shotdocmd-san-jose-2"}
                    target={"_blank"}
                    rel="noreferrer noopener"
                    className={styles.linkIcon}
                  >
                    {" "}
                    {IconService.getIcon("yelp")}
                  </a>
                  <a
                    href={
                      "https://www.google.com/search?q=shotdocmd&oq=shotdocmd&aqs=chrome.0.69i59l2j69i60l4.3994j0j1&sourceid=chrome&ie=UTF-8#lrd=0x808e355d1e22bdb1:0xeea0301d1b46ef11,1,,,"
                    }
                    target={"_blank"}
                    rel="noreferrer noopener"
                    className={styles.linkIcon}
                  >
                    {" "}
                    {IconService.getIcon("google")}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rowCenter} style={{ marginBottom: 0 }}>
            <hr />
          </div>
          <div className={styles.rowSpace}>
            <img src={logoSmall} className={styles.logoSmall} alt={"logo"} />
            <div>
              {IconService.getIcon("copyright", styles.copyrightLogo)} ShotDocMD&nbsp;
              {new Date().getFullYear()}
            </div>
            {/* <div>Privacy Policy</div> */}
          </div>
        </div>
      </footer>
      {showSignInModal && (
        <SignInModal
          show={showSignInModal}
          closeModal={hideSignInModal}
          handleCreateAccount={signInToAccountCreate}
        />
      )}
      {showCreateAccountModal && (
        <SignUpModal
          show={showCreateAccountModal}
          closeModal={hideCreateAccountModal}
          handleSignIn={accountCreateToSignIn}
        />
      )}
    </>
  );
};

export default Footer;
