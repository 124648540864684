import { useHistory } from "react-router-dom";
import Button from "../../../components/button/button";
import { Flex, Title } from "../../../styles/utils";
import SpecialsTable from "./specialsTable";


const SpecialsScreen = () => {
  const history = useHistory();

  return (
    <div className="content-container">
      <div>
        <Flex justifyContent="space-between" alignItems="center">
          <Title>Specials</Title>

          <Button onClick={() => history.push("/specials/new")}>New Special</Button>
        </Flex>
        <SpecialsTable />
      </div>
    </div>
  );
};

export default SpecialsScreen;
