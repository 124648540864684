import React, { useEffect, useState } from "react";
import Modal from "../../modal/modal";
import modalStyles from "../../modal/modal.module.css";
import iconStyles from "../../../styles/icon.module.css";
import Input from "../../input/input";
import Button from "../../button/button";
import LoadingCog from "../../loadingCog";
import { useDispatch, useSelector } from "react-redux";
import IconService from "../../../services/iconService";
import { updateUserProfile } from "../../../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../../../constants/userConstants";

const UserEditModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [originalFirstName, setOriginalFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [originalLastName, setOriginalLastName] = useState("");
  const [canUpdate, setCanUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error,
    loading: loadingUpdate,
  } = userUpdateProfile;

  function hideModal() {
    setEmail("");
    setFirstName("");
    setLastName("");
    setOriginalEmail("");
    setOriginalFirstName("");
    setOriginalLastName("");
    setErrorMessage("");
    props.closeModal();
  }

  useEffect(() => {
    if (userInfo) {
      setEmail(userInfo.email);
      setOriginalEmail(userInfo.email);
      setFirstName(userInfo.firstName);
      setOriginalFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      setOriginalLastName(userInfo.lastName);
    }
  }, [userInfo]);


  useEffect(() => {
    checkIfChanged();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, email]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkIfChanged = () => {
    let hasChanged = false;
    if (originalEmail !== email && email) {
      hasChanged = true;
    } else if (originalFirstName !== firstName && firstName) {
      hasChanged = true;
    } else if (originalLastName !== lastName && lastName) {
      hasChanged = true;
    }
    setCanUpdate(hasChanged);
  };

  function updateUser(e) {
    e.preventDefault();
    setErrorMessage("");
    let emailIsValid = validateEmail(email);
    if (!emailIsValid) {
      setErrorMessage("Email is invalid");
      return;
    }
    dispatch(
      updateUserProfile({
        firstName,
        lastName,
        email,
      })
    );
  }

  useEffect(() => {
    if (successUpdate) {
      hideModal();
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdate]);


  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Edit Account</div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        <form onSubmit={updateUser}>
          <Input
            withLabel={true}
            name={"First Name"}
            type={"text"}
            required={true}
            onChange={setFirstName}
            value={firstName}
            placeholder={"Enter First Name"}
          />
          <div className={modalStyles.paddingY}>
            <Input
              withLabel={true}
              name={"Last Name"}
              type={"text"}
              required={true}
              onChange={setLastName}
              value={lastName}
              placeholder={"Enter Last Name"}
            />
          </div>{" "}
          <div className={modalStyles.paddingY}>
            <Input
              withLabel={true}
              name={"Email Address"}
              type={"email"}
              required={true}
              onChange={setEmail}
              value={email}
              placeholder={"Enter Email"}
            />
          </div>{" "}
          <div className={modalStyles.errorMessageContainer}>
            {errorMessage ? errorMessage : error ? error : null}
          </div>
          <div>
            <Button
              type={"submit"}
              fullWidth={true}
              height={"45px"}
              disabled={!canUpdate || loadingUpdate}
            >
              {loadingUpdate ? <LoadingCog /> : <>Update</>}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserEditModal;
