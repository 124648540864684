import React, { useEffect } from "react";
import UserInfo from "../../components/user/userInfo/userInfo";
import UserOrderList from "../../components/user/userOrderList/userOrderList";
import contentLayoutStyles from "../../styles/contentLayout.module.css";

const MyAccountScreen = () => {
  useEffect(() => {
    document.title = "My Account | Shop at ShotDocMD";
  }, []);
  return (
    <div className={"content-container"}>
      <div className={contentLayoutStyles.grid}>
        <div className={contentLayoutStyles.secondaryContainer}>
          <UserInfo />
        </div>
        <div className={contentLayoutStyles.mainContainer}>
          <UserOrderList />
        </div>
      </div>
    </div>
  );
};

export default MyAccountScreen;
