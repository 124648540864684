import React, { useState } from "react";
import IconService from "../../services/iconService";
import inputStyles from "./input.module.css";

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {props.withLabel && (
        <label htmlFor={props.name} className={inputStyles.label}>
          {props.labelText ? props.labelText : props.name}
        </label>
      )}
      <div className={inputStyles.inputPassword}>
        <input
          type={showPassword ? "text" : "password"}
          required={props.required ? props.required : false}
          onChange={(event) => props.onChange(event?.target.value)}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          className={`${inputStyles.input} ${
            props.withOutline && !props.showError
              ? inputStyles.inputOutline
              : ""
          } ${props.showError ? inputStyles.inputError : ""}`}
          style={props.width ? { width: `${props.width}px` } : {}}
          maxLength={props.maxLength ? props.maxLength : null}
          max={props.max ? props.max : null}
        />
        {showPassword ? (
          <div onClick={toggleVisibility} className={inputStyles.eyeContainer}>
            {IconService.getIcon("eye-closed", inputStyles.eye)}
          </div>
        ) : (
          <div onClick={toggleVisibility} className={inputStyles.eyeContainer}>
            {IconService.getIcon("eye-open", inputStyles.eye)}
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordInput;
