export const contactFormReducer = (state = {}, action) => {
  switch (action.type) {
    case "CONTACT_REQUEST":
      return { loading: true };
    case "CONTACT_SUCCESS":
      return { loading: false, success: true };
    case "CONTACT_FAIL":
      return { loading: false, error: action.payload };
    case "CONTACT_RESET":
      return {};
    default:
      return state;
  }
};

export const getAllContactsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CONTACT_REQUEST":
      return { loading: true };
    case "GET_CONTACT_SUCCESS":
      return { loading: false, contacts: action.payload };
    case "GET_CONTACT_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const contactByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CONTACT_BY_ID_REQUEST":
      return { loading: true };
    case "GET_CONTACT_BY_ID_SUCCESS":
      return { loading: false, contact: action.payload };
    case "GET_CONTACT_BY_ID_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const contactMarkAsReadReducer = (state = {}, action) => {
  switch (action.type) {
    case "MARK_AS_READ_REQUEST":
      return { loading: true };
    case "MARK_AS_READ_SUCCESS":
      return { loading: false, success: true };
    case "MARK_AS_READ_FAIL":
      return { loading: false, error: action.payload };
    case "MARK_AS_READ_RESET":
      return {};
    default:
      return state;
  }
};

export const contactReplyToReducer = (state = {}, action) => {
  switch (action.type) {
    case "REPLY_TO_MESSAGE_REQUEST":
      return { loading: true };
    case "REPLY_TO_MESSAGE_SUCCESS":
      return { loading: false, success: true };
    case "REPLY_TO_MESSAGE_FAIL":
      return { loading: false, error: action.payload };
    case "REPLY_TO_MESSAGE_RESET":
      return {};
    default:
      return state;
  }
};
