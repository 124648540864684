import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { findOrder } from "../../actions/orderActions";
import { ORDER_GET_DETAILS_RESET } from "../../constants/orderConstants";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import Button from "../button/button";
import Input from "../input/input";
import LoadingCog from "../loadingCog";
import modalStyles from "../modal/modal.module.css";

const FindOrderCard = () => {
  const dispatch = useDispatch();
  const orderFind = useSelector((state) => state.orderFind);
  const { order: orderFound, error } = orderFind;
  const [email, setEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    dispatch(findOrder({ email, orderNumber }));
  };

  const history = useHistory();

  useEffect(() => {
    if (orderFound) {
      history.push(
        `/order/${orderFound.order._id}?access_token=${orderFound.order.accessToken}`
      );
      setIsLoading(false);
      dispatch({ type: ORDER_GET_DETAILS_RESET });
    }
    if (error) {
      setIsLoading(false);
    }
  }, [orderFound, dispatch, history, error]);

  return (
    <div className={contentLayoutStyles.card}>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Find Order</div>
        <form onSubmit={submitHandler}>
          <Input
            withLabel={true}
            name={"Email Address"}
            type={"email"}
            required={true}
            onChange={setEmail}
            value={email}
            placeholder={"Enter Email"}
          />
          <div className={modalStyles.paddingY}>
            <Input
              withLabel={true}
              name={"Order Number"}
              type={"text"}
              required={true}
              onChange={setOrderNumber}
              value={orderNumber}
              placeholder={"Enter Order Number"}
            />
          </div>{" "}
          <div className={modalStyles.errorMessageContainer}>
            {error && error}
          </div>
          <div>
            <Button
              type={"submit"}
              fullWidth={true}
              height={"45px"}
              disabled={isLoading}
            >
              {isLoading ? <LoadingCog /> : <>Find Order</>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FindOrderCard;
