import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSpecialDetails } from '../../../actions/specialActions';
import SpecialCreateEditForm from './SpecialCreateEditForm';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SpecialsEditScreen = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const specialId = query.get("id");

    const { special, error } = useSelector(state => state.specialDetails)

    useEffect(() => {
        if (specialId !== special?._id && !error) {
            dispatch(getSpecialDetails(specialId))
        }
    }, [specialId, special, error, dispatch])

    return (
        <SpecialCreateEditForm type="edit" special={special} />
    );
};

export default SpecialsEditScreen;