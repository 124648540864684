import React from "react";
import { Link } from "react-router-dom";
import dateFormat from "../../../dateFormat";
import OrderImage from "../orderImage/orderImage";
import styles from "./orderCard.module.css";

const OrderCard = ({ order }) => {
  return (
    <Link to={`/order/${order._id}`}>
      <div className={styles.grid}>
        <OrderImage {...{ orderItems: order.orderItems }} />
        <div>
          {" "}
          <div className={styles.name}>Order #{order._id}</div>
          <div className={styles.date}>{dateFormat(order.createdAt)} </div>
          <div className={styles.qty}>
            {order.orderItems.reduce((a, c) => a + c.qty, 0)} Item
            {order.orderItems.reduce((a, c) => a + c.qty, 0)!== 1 && 's'}
          </div>
        </div>
        <div className={styles.price}>
          <div>${order.totalPrice.toFixed(2)}</div>
        </div>
      </div>
    </Link>
  );
};

export default OrderCard;
