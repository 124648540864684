import React from "react";
import textAreaStyles from "./textarea.module.css";

const TextArea = (props) => {
  return (
    <div>
      {props.withLabel && (
        <label htmlFor={props.name} className={textAreaStyles.label}>
          {props.labelText ? props.labelText : props.name}
          {props.showStar && <sup>*</sup>}
        </label>
      )}
      <textarea
        required={props.required ? props.required : false}
        onChange={(event) => props.onChange(event?.target.value)}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        className={textAreaStyles.textArea}
        style={props.width ? { width: `${props.width}px` } : {}}
        rows={props.rows ? props.rows : 4}
      />
    </div>
  );
};

export default TextArea;
