import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, userSaveAddress } from "../../actions/userActions";
import checkoutStyles from "../../styles/checkout.module.css";
import OrderSummary from "../../components/checkout/orderSummary/orderSummary";
import CheckoutSignIn from "../../components/checkout/checkoutSignIn/checkoutSignIn";
import CheckoutShipping from "../../components/checkout/checkoutShipping/checkoutShipping";
import CheckoutPayment from "../../components/payments/checkoutPayment";
import EmailCard from "../../components/checkout/checkoutCards/emailCard";
import AddressCard from "../../components/checkout/checkoutCards/addressCard";
import PaymentCard from "../../components/checkout/checkoutCards/paymentCard";
import Button from "../../components/button/button";
import LoadingCog from "../../components/loadingCog";
import { createOrder } from "../../actions/orderActions";
import { ORDER_CREATE_RESET } from "../../constants/orderConstants";
import { CART_EMPTY } from "../../constants/cartConstants";
import MessageBox from "../../components/messageBox";
import { getBaseUrl } from "../../api";

const CheckoutScreen = (props) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { success, order } = orderCreate;

  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order._id}/success?accessToken=${order.accessToken}`);
      dispatch({ type: CART_EMPTY });
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [success, order, props.history, dispatch]);

  useEffect(() => {
    document.title = "Checkout | Shop at ShotDocMD";
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(detailsUser(userInfo._id));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (cartItems?.length === 0) {
      props.history.push("/cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  useEffect(() => {
    document.title = "Checkout | Shop at ShotDocMD";
  }, []);

  const [showType, setShowType] = useState("shipping");

  const handleShipping = (data) => {
    setShippingState({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      isNewAddress: data.isNewAddress,
      checked: data.checked,
      latlng: data.latlng
    });

    if (editFrom) {
      setShowType(editFrom);
      setEditFrom("");
      setIsEdit(false)
    } else {
      setShowType("payment");
    }
  };

  const [shippingState, setShippingState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    isNewAddress: false,
    checked: false,
    latlng: {}
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editFrom, setEditFrom] = useState("");

  const [paymentType, setPaymentType] = useState("");

  const handlePayment = (type) => {
    if (editFrom) {
      setShowType(editFrom);
      setEditFrom("");
    } else {
      setShowType("review");
    }
    setPaymentType(type);
  };

  const [makePayment, setMakePayment] = useState(false);

  const takePayment = () => {
    setIsLoading(true);
    setMakePayment(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const placeOrder = () => {
    let shippingAddress = {
      firstName: shippingState.firstName,
      lastName: shippingState.lastName,
      address: shippingState.address,
      address2: shippingState.address2,
      city: shippingState.city,
      state: shippingState.state,
      zipcode: shippingState.zipcode,
      latlng: shippingState.latlng
    };
    let joinMailList = shippingState.checked;
    dispatch(
      createOrder({
        cartItems,
        shippingAddress,
        clientSecret,
        joinMailList,
        email: shippingState.email,
        user: userInfo ? userInfo : null,

      })
    );

    if (userInfo && shippingState.isNewAddress) {
      dispatch(
        userSaveAddress({
          id: userInfo._id,
          firstName: shippingState.firstName,
          lastName: shippingState.lastName,
          address: shippingState.address,
          address2: shippingState.address2,
          city: shippingState.city,
          state: shippingState.state,
          zipcode: shippingState.zipcode,
          latlng: shippingState.latlng,
        })
      );
    }
  };

  const [error, setError] = useState("");

  const handlePaymentError = (message) => {
    setError(message);
    setIsLoading(false);
  };

  const handleEdit = (type) => {
    setIsEdit(true);
    setEditFrom(showType);
    setShowType(type);
  };

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch(`${getBaseUrl()}/stripe/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: cartItems }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
  }, [cartItems]);

  return (
    <>
      <div className={"content-container"}>
        <div className={checkoutStyles.grid}>
          <div className={checkoutStyles.orderSummaryContainer}>
            <OrderSummary />
          </div>
          <div className={checkoutStyles.mainContainer}>
            {!userInfo && <CheckoutSignIn />}
            <div
              style={
                showType === "shipping"
                  ? { display: "inherit" }
                  : { display: "none" }
              }
            >
              <CheckoutShipping
                handleShipping={handleShipping}
                isEdit={isEdit}
              />
            </div>

            <div
              style={
                showType !== "shipping"
                  ? { display: "inherit" }
                  : { display: "none" }
              }
            >
              <EmailCard email={shippingState.email} handleEdit={handleEdit} />
              <AddressCard
                handleEdit={handleEdit}
                shippingState={shippingState}
              />
            </div>
            <div
              style={
                showType === "review"
                  ? { display: "inherit" }
                  : { display: "none" }
              }
            >
              <PaymentCard paymentType={paymentType} handleEdit={handleEdit} />
            </div>
            <div
              style={
                showType === "payment"
                  ? { display: "inherit" }
                  : { display: "none" }
              }
            >
              {clientSecret && (
                <CheckoutPayment
                  clientSecret={clientSecret}
                  handlePayment={handlePayment}
                  isEdit={isEdit}
                  shippingState={shippingState}
                  makePayment={makePayment}
                  handlePaymentError={handlePaymentError}
                  handlePaymentSuccess={placeOrder}
                />
              )}
            </div>
            {showType === "review" && (
              <>
                <div style={{ marginBottom: "20px" }}>
                  {error && <MessageBox variant="danger">{error}</MessageBox>}
                </div>
                <div
                  className={checkoutStyles.buttonContainer}
                  style={{ width: "100%" }}
                >
                  <Button
                    type="button"
                    onClick={takePayment}
                    fullWidth={true}
                    height={"50px"}
                    disabled={isLoading}
                  >
                    {isLoading ? <LoadingCog /> : "Place Order"}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutScreen;
