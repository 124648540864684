import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconService from "../../../services/iconService";
import contentLayoutStyles from "../../../styles/contentLayout.module.css";
import UserEditModal from "../userEditModal/userEditModal";
import UserEditPasswordModal from "../userEditModal/userEditPasswordModal";
import UserEditTypeModal from "../userEditModal/userEditTypeModal";
import styles from "./userInfo.module.css";
const UserInfo = () => {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showPasswordEditModal, setShowPasswordEditModal] = useState(false);
  const [showEditTypeModal, setShowEditTypeModal] = useState(false);

  function openEditModal() {
    if (showEditTypeModal) {
      hideTypeEditModal();
    }
    setShowEditModal(true);
  }
  function hideEditModal() {
    setShowEditModal(false);
  }

  function openPassEditModal() {
    if (showEditTypeModal) {
      hideTypeEditModal();
    }
    setShowPasswordEditModal(true);
  }
  function hidePassEditModal() {
    setShowPasswordEditModal(false);
  }

  function openTypeEditModal() {
    setShowEditTypeModal(true);
  }
  function hideTypeEditModal() {
    setShowEditTypeModal(false);
  }

  return (
    <>
      <div
        className={
          contentLayoutStyles.backgroundContainer + " " + styles.container
        }
      >
        <div className={styles.titleRow}>
          <div className={contentLayoutStyles.titleSmall}>My Account</div>
          <div onClick={openTypeEditModal}>
            {IconService.getIcon("edit", styles.editIcon)}
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.description}>Name:</div>
          <div className={styles.descriptionValue}>
            {userInfo.firstName} {userInfo.lastName}
          </div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.description}>Email:</div>
          <div className={styles.descriptionValue}>{userInfo.email}</div>
        </div>
      </div>
      {showEditModal && (
        <UserEditModal show={showEditModal} closeModal={hideEditModal} />
      )}
      {showPasswordEditModal && (
        <UserEditPasswordModal
          show={showPasswordEditModal}
          closeModal={hidePassEditModal}
        />
      )}
      {showEditTypeModal && (
        <UserEditTypeModal
          show={showEditTypeModal}
          closeModal={hideTypeEditModal}
          openEditModal={openEditModal}
          openPassEditModal={openPassEditModal}
        />
      )}
    </>
  );
};

export default UserInfo;
