import React from "react";
import screenStyles from '../../../styles/screen.module.css';
import checkoutStyles from '../../../styles/checkout.module.css';
import cardStyles from './checkoutCard.module.css';

const EmailCard = (props) => {
  return (
    <div className={screenStyles.backgroundContainer}>
      <div
        className={checkoutStyles.subheading}
        style={{ marginBottom: "10px" }}
      >
       <div className={"row"}>
        <div className={checkoutStyles.subheading}>Notifications To</div>
        <div className={checkoutStyles.editLink} onClick={() => props.handleEdit('shipping')}>
          edit
        </div>
      </div>
      </div>
      <div className={checkoutStyles.inputPadding + ' ' + cardStyles.row}>
        <div className={cardStyles.key}>Email</div>
        <div className={cardStyles.value}>{props.email}</div>
      </div>
    </div>
  );
};

export default EmailCard;
