import React, { useEffect } from 'react';
import contentLayoutStyles from '../../styles/contentLayout.module.css';
import modalStyles from "../../components/modal/modal.module.css";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledContainer = styled.div`
  margin-top: 15px;
  color: var(--primary-dark);
  text-align: center;
  font-size: 18px;

  div {
    margin-bottom: 10px;
  }
  a {
    color: var(--primary);
    transition: 0.2s;
    &:hover {
      color: var(--primary-hover);
    }
  }
`;

const ReturnScreen = () => {
  useEffect(() => {
    document.title = "Return Information | Shop at ShotDocMD";
  }, []);
  return (
    <div className="content-container">
      <div className="row center" style={{ marginTop: "60px" }}>
        <div className={contentLayoutStyles.card}>
          <div className={modalStyles.modalContent}>
            <div className={modalStyles.title}>Return Policy</div>
            <StyledContainer>
              <div>
                At this time, all sales on Shop at ShotDocMD are final.
              </div>
              <div>
                If you have questions, <Link to={'/contact'}>contact us</Link>
              </div>
              {/* TODO: add info about shipping carrier */}
            </StyledContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnScreen;