import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { cartReducer } from "./reducers/cartReducers";
import {
  contactByIdReducer,
  contactFormReducer,
  contactMarkAsReadReducer,
  contactReplyToReducer,
  getAllContactsReducer,
} from "./reducers/contactReducers";
import { generalSearchReducer } from "./reducers/generalReducers";
import {
  orderCreateReducer,
  orderDeleteReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderFindReducer,
  orderGuestCreateReducer,
  orderGuestDetailsReducer,
  orderListReducer,
  orderMineListReducer,
  orderShipReducer,
} from "./reducers/orderReducers";
import {
  featuredProductListReducer,
  getAreasOfFocusReducer,
  getBrandsReducer,
  getCategoriesReducer,
  getConcernsReducer,
  productCreateReducer,
  productDeleteReducer,
  productDetailsReducer,
  productListReducer,
  productReviewCreateReducer,
  productUpdateReducer,
  relatedProductsReducer,
} from "./reducers/productReducers";
import { searchFilterReducer } from "./reducers/searchReducers";
import { specialCreateReducer, specialDeleteReducer, specialDetailsReducer, specialListReducer, specialUpdateReducer } from "./reducers/specialReducers";
import {
  userDeleteAddressReducer,
  userDeleteReducer,
  userDetailsReducer,
  userForgotPasswordReducer,
  userIsAdminReducer,
  userListReducer,
  userRegisterReducer,
  userResetPasswordReducer,
  userSaveAddressReducer,
  userSigninReducer,
  userUpdatePasswordReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from "./reducers/userReducers";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
    tokenState: localStorage.getItem("tokenState")
      ? JSON.parse(localStorage.getItem("tokenState"))
      : null,
  },
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
  },
};
const reducer = combineReducers({
  cart: cartReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userList: userListReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  userDelete: userDeleteReducer,
  userAddressSave: userSaveAddressReducer,
  userAddressDelete: userDeleteAddressReducer,
  userIsAdmin: userIsAdminReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  productList: productListReducer,
  productCreate: productCreateReducer,
  productDelete: productDeleteReducer,
  productUpdate: productUpdateReducer,
  productDetails: productDetailsReducer,
  productReviewCreate: productReviewCreateReducer,
  productsRelated: relatedProductsReducer,
  featuredProductsList: featuredProductListReducer,
  orderCreate: orderCreateReducer,
  orderGuestCreate: orderGuestCreateReducer,
  orderMineList: orderMineListReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,
  orderDetails: orderDetailsReducer,
  orderGuestDetails: orderGuestDetailsReducer,
  orderShip: orderShipReducer,
  orderDeliver: orderDeliverReducer,
  orderFind: orderFindReducer,
  contactForm: contactFormReducer,
  getAllContacts: getAllContactsReducer,
  getBrands: getBrandsReducer,
  getCategories: getCategoriesReducer,
  getConcerns: getConcernsReducer,
  getAreasOfFocus: getAreasOfFocusReducer,
  generalSearch: generalSearchReducer,
  contactMarkAsRead: contactMarkAsReadReducer,
  contactById: contactByIdReducer,
  contactReplyTo: contactReplyToReducer,
  searchFilter: searchFilterReducer,
  specialList: specialListReducer,
  specialCreate: specialCreateReducer,
  specialUpdate: specialUpdateReducer,
  specialDelete: specialDeleteReducer,
  specialDetails: specialDetailsReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
