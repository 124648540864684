import React, { useState } from "react";
import screenStyles from "../../../styles/screen.module.css";
import checkoutStyles from "../../../styles/checkout.module.css";
import IconService from "../../../services/iconService";
import { useSelector } from "react-redux";
import styles from "./orderSummary.module.css";
import { Link } from "react-router-dom";
import cartStyles from "../../../styles/cartScreen.module.css";
import Tooltip from "../../tooltip/tooltip";
import iconStyles from "../../../styles/icon.module.css";

const OrderSummary = () => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [showCartItems, setShowCartItems] = useState(false);

  const toggleCartDropdown = () => {
    setShowCartItems(!showCartItems);
  };

  return (
    <div className={screenStyles.backgroundContainer}>
      <div className={"row"}>
        <div className={checkoutStyles.subheading}>Order Summary</div>
        <Link to="/cart" className={styles.editLink}>
          edit
        </Link>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <div className="row" onClick={toggleCartDropdown}>
          <div>
            {IconService.getIcon("cart", styles.cartIcon)}
            {showCartItems
              ? IconService.getIcon("down", styles.downIcon)
              : IconService.getIcon("right", styles.downIcon)}
          </div>
          <div className={styles.priceSmall}>
            $
            {(
              cartItems.reduce((a, c) => a + c.price * c.qty, 0) * 1.09 +
              (cartItems.reduce((a, c) => a + c.price * c.qty, 0) >= 150
                ? 0
                : 15)
            ).toFixed(2)}{" "}
          </div>
        </div>
        <hr />
        {showCartItems && (
          <>
            {cartItems?.map((item, index) => {
              return (
                <div className={styles.cartRow} key={index}>
                  <img
                    src={
                      item.images
                        ? item.images[0]
                        : IconService.defaultProductImage
                    }
                    alt={item.name}
                    className={`${styles.itemImage} image-border`}
                  />
                  <div className={styles.cartItemInfo}>
                    <Link to={`/product/${item._id}`}>
                      <div className={styles.productName}>{item.name} </div>
                    </Link>
                    <div className="row">
                      {" "}
                      <div className={styles.quantity}>Quantity {item.qty}</div>
                      <div className={styles.quantity}>
                        ${item.price * item.qty}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <hr />
          </>
        )}
        <div className={cartStyles.infoRow}>
          <div className={cartStyles.infoItem}>Subtotal</div>
          <div className={cartStyles.infoItem}>
            ${cartItems.reduce((a, c) => a + c.price * c.qty, 0).toFixed(2)}
          </div>
        </div>
        <div className={cartStyles.infoRow}>
          <div className={cartStyles.infoItem}>
            Shipping{" "}
            <Tooltip
              content={"Orders over $150 qualify for free shipping"}
              direction="top"
            >
              {IconService.getIcon("info", iconStyles.infoCircle)}
            </Tooltip>
          </div>
          <div className={cartStyles.infoItem}>
            {cartItems.reduce((a, c) => a + c.price * c.qty, 0) >= 150
              ? "FREE"
              : "$15.00"}
          </div>
        </div>
        <div className={cartStyles.infoRow}>
          <div className={cartStyles.infoItem}>Tax</div>
          <div className={cartStyles.infoItem}>
            $
            {(
              cartItems.reduce((a, c) => a + c.price * c.qty, 0) * 0.09
            ).toFixed(2)}{" "}
          </div>
        </div>
        <hr />
        <div className={"row"}>
          <div className={styles.price}>Order Total</div>
          <div className={styles.price}>
            $
            {(
              cartItems.reduce((a, c) => a + c.price * c.qty, 0) * 1.09 +
              (cartItems.reduce((a, c) => a + c.price * c.qty, 0) >= 150
                ? 0
                : 15)
            ).toFixed(2)}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
