import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import RelatedProductCard from "../../relatedProduct/relatedProductCard";

const Container = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--secondary-bg-color);
  margin-bottom: 25px;
  grid-template-columns: 1/3;
`;

const StyledHeader = styled.div`
  font-size: 20px;
  color: var(--primary-dark);
  font-weight: 600;
  text-align: center;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 15px;
  margin-top: 10px;
`;

const RelatedProducts = ({ product, relatedProducts }) => {
  return !!relatedProducts?.length && product ? (
    <Container>
      <StyledHeader>More from {product.brand}</StyledHeader>
      <StyledGrid>
        {relatedProducts.map((related, index) => {
          if (index < 4) {
            return <RelatedProductCard {...{ product: related, noMarginBottom: true }} key={index} />;
          }
          return null;
        })}
      </StyledGrid>
    </Container>
  ) : null;
};

export default connect(({ productsRelated }) => ({
  relatedProducts: productsRelated.relatedProducts,
}))(RelatedProducts);
