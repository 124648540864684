import React from "react";
import { Link } from "react-router-dom";
import contentLayoutStyles from "../../styles/contentLayout.module.css";

const AdminScreen = () => {
  return (
    <div className="content-container">
      <div className={contentLayoutStyles.gridQuad}>
        <Link to="/productlist">
          {" "}
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Products
          </div>
        </Link>
        <Link to="/orderlist">
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Orders
          </div>
        </Link>
        <Link to="/brandslist">
          {" "}
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Brands
          </div>
        </Link>
        <Link to="/userlist">
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Users
          </div>
        </Link>
        <Link to="/contactlist">
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Contact Requests
          </div>
        </Link>
        <Link to="/specials">
          <div
            className={
              contentLayoutStyles.card +
              " " +
              contentLayoutStyles.cardWithHover +
              " " +
              contentLayoutStyles.cardCenter
            }
            style={{ maxWidth: "100%" }}
          >
            Specials
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminScreen;
