import React from "react";
import { useSelector } from "react-redux";

// const formatBrandName = (brand) => {
//   return brand.replaceAll(" ", "_");
// };

const BrandsListScreen = (props) => {
  const getBrands = useSelector((state) => state.getBrands);
  const { brands } = getBrands;

  return (
    <div className="content-container">
      <div className="row">
        <h1>Brands</h1>
      </div>
      <div style={{ overflow: "auto" }}>
        <table className="table">
          <thead>
            <tr>
              <th>Brand Name</th>
              <th>Image</th>
              <th>Upload New Image</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <TableRow {...{ brand }} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TableRow = ({ brand }) => {
  //  const [imageUrl, setImageUrl] = useState(null);

  const uploadFileHandler = (e) => {
    // const file = e.target.files[0];
    // const fileObj = {
    //   file: file,
    //   blob: URL.createObjectURL(file),
    // };
  };

  return (
    <tr key={brand}>
      <td>{brand}</td>
      <td>
        <img src={"/#"} alt={brand} />
      </td>
      <td>
        <input
          style={{ marginTop: "5px" }}
          type="file"
          id="imageFile"
          label="Choose Image"
          accept="image/png, image/jpeg, image/jpg"
          onChange={uploadFileHandler}
        />
      </td>
    </tr>
  );
};

export default BrandsListScreen;
