// External Libraries
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import styled from "styled-components";

// Redux Actions
import {
  checkIfAdmin,
  refreshToken,
  signout,
} from "./actions/userActions";

// Components
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/scrollToTop";

// HOCs
import AdminRoute from "./components/adminRoute";
import UserRoute from "./components/userRoute";

// Screens
import ProductListScreen from "./screens/product/ProductListScreen";
import ProductEditScreen from "./screens/product/ProductEditScreen";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/product/ProductScreen";
import UserListScreen from "./screens/user/UserListScreen";
import UserEditScreen from "./screens/user/UserEditScreen";
import CartScreen from "./screens/checkout/CartScreen";
import OrderSuccessScreen from "./screens/order/OrderSuccessScreen";
import UserOrderScreen from "./screens/user/UserOrderScreen";
import OrderListScreen from "./screens/order/OrderListScreen";
import OrderDetailsScreen from "./screens/order/OrderDetailsScreen";
import FindOrderScreen from "./screens/order/FindOrderScreen";
import CheckoutScreen from "./screens/checkout/CheckoutScreen";
import UnauthorizedScreen from "./screens/UnauthorizedScreen";
import MyAccountScreen from "./screens/user/MyAccountScreen";
import LocationService from "./services/locationService";
import AdminScreen from "./screens/admin/AdminScreen";
import ContactScreen from "./screens/contact/ContactScreen";
import ContactListScreen from "./screens/contact/ContactListScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import BrandsScreen from "./screens/brands/BrandsScreen";
import CategoriesScreen from "./screens/filters/CategoriesScreen";
import ShippingScreen from "./screens/order/ShippingScreen";
import ReturnScreen from "./screens/order/ReturnScreen";
import OrderActionsScreen from "./screens/order/OrderActionsScreen";
// import UploadBannerImagesScreen from "./screens/UploadBannerImagesScreen";
import ContactEditScreen from "./screens/contact/ContactEditScreen";
import ConcernsScreen from "./screens/filters/ConcernsScreen";
import AreasOfFocusScreen from "./screens/filters/AreasOfFocusScreen";
import SearchScreen from "./screens/filters/SearchScreen";
import IconService from "./services/iconService";
import ShopByBrand from "./screens/shopBy/ShopByBrand";
import ShopByCategory from "./screens/shopBy/ShopByCategory";
import ShopByConcern from "./screens/shopBy/ShopByConcern";
import ShopByAreaOfFocus from "./screens/shopBy/ShopByAreaOfFocus";
import ForgotPasswordScreen from "./screens/user/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import BrandsListScreen from "./screens/brands/BrandsListScreen";
import ErrorBoundary from "./utils/ErrorBoundary";
import SpecialsScreen from "./screens/admin/specials/SpecialsScreen";
import SpecialsCreateScreen from "./screens/admin/specials/SpecialsCreateScreen";
import SpecialsEditScreen from "./screens/admin/specials/SpecialsEditScreen";

const ScrollToTopContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  transition: opacity 0.5s;
  opacity: ${({ show }) =>
    show ? 1 : 0};
  visibility: ${({ show }) =>
    show ? "visible" : "hidden"};
  background-color: var(--primary);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--button-text-color);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  z-index: 3;
  @media screen and (min-width: 700px) {
    display: none;
  }
`;

function App() {
  const dispatch = useDispatch();
  const mainRef = useRef(null);
  const [
    showScrollToTop,
    setShowScrollToTop,
  ] = useState(false);
  const loadScript = (
    url,
    callback
  ) => {
    let script =
      document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange =
        function () {
          if (
            script.readyState ===
            "loaded" ||
            script.readyState ===
            "complete"
          ) {
            script.onreadystatechange =
              null;
            callback();
          }
        };
    }

    script.src = url;
    document
      .getElementsByTagName("head")[0]
      .appendChild(script);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${LocationService.googleApiKey}&libraries=places`
    );
  }, []);

  const userIsAdmin = useSelector(
    (state) => state.userIsAdmin
  );
  const { success, loading } =
    userIsAdmin;

  const userSignin = useSelector(
    (state) => state.userSignin
  );
  const {
    tokenState,
    loading: refreshLoading,
    refreshError,
  } = userSignin;

  useEffect(() => {
    if (refreshLoading) return;
    if (
      tokenState?.expires_at &&
      tokenState.expires_at <=
      Date.now()
    ) {
      dispatch(refreshToken());
    }
    if (refreshError) {
      dispatch(signout());
    }
  }, [
    dispatch,
    tokenState,
    refreshLoading,
    refreshError,
  ]);

  useEffect(() => {
    if (!success && !loading) {
      dispatch(checkIfAdmin());
    }
  }, [success, loading, dispatch]);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      handleScroll
    );
    return () =>
      document.removeEventListener(
        "scroll",
        handleScroll
      );
  }, []);

  const handleScroll = () => {
    if (mainRef.current) {
      const mainContent =
        mainRef.current.getBoundingClientRect();
      if (mainContent.top < -1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="grid-container">
        <ErrorBoundary>
          <Navbar />
        </ErrorBoundary>
        <main ref={mainRef}>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/search"
                component={SearchScreen}
                exact
              />

              <Route
                path="/product/:id"
                component={
                  ProductScreen
                }
                exact
              />

              <Route
                path="/cart/:id?"
                component={CartScreen}
              />
              <Route
                path="/find-order"
                component={
                  FindOrderScreen
                }
              />
              <Route
                path="/forgot-password"
                component={
                  ForgotPasswordScreen
                }
              />
              <Route
                path="/reset-password"
                component={
                  ResetPasswordScreen
                }
              />
              <Route
                path="/order/:id/success"
                component={
                  OrderSuccessScreen
                }
                exact
              />
              <Route
                path="/order/:id"
                component={
                  OrderDetailsScreen
                }
                exact
              />
              <Route
                path="/checkout"
                component={
                  CheckoutScreen
                }
                exact
              />
              <Route
                path="/unauthorized"
                component={
                  UnauthorizedScreen
                }
                exact
              />
              <Route
                path="/contact"
                component={
                  ContactScreen
                }
                exact
              />
              <Route
                path="/brands"
                component={BrandsScreen}
                exact
              />
              <Route
                path="/brands/:name"
                component={ShopByBrand}
                exact
              />
              <Route
                path="/categories"
                component={
                  CategoriesScreen
                }
                exact
              />
              <Route
                path="/categories/:name"
                component={
                  ShopByCategory
                }
                exact
              />
              <Route
                path="/concerns"
                component={
                  ConcernsScreen
                }
                exact
              />
              <Route
                path="/concerns/:name"
                component={
                  ShopByConcern
                }
                exact
              />
              <Route
                path="/areas-of-focus"
                component={
                  AreasOfFocusScreen
                }
                exact
              />
              <Route
                path="/areas-of-focus/:name"
                component={
                  ShopByAreaOfFocus
                }
                exact
              />
              <Route
                path="/shipping"
                component={
                  ShippingScreen
                }
                exact
              />
              <Route
                path="/returns"
                component={ReturnScreen}
                exact
              />
              <UserRoute
                path="/my-account"
                component={
                  MyAccountScreen
                }
                exact
              />
              <UserRoute
                path="/orderhistory"
                component={
                  UserOrderScreen
                }
              />

              <AdminRoute
                path="/product/:id/edit"
                component={
                  ProductEditScreen
                }
                exact
              />
              <AdminRoute
                path="/admin"
                component={AdminScreen}
                exact
              />
              <AdminRoute
                path="/contactlist"
                component={
                  ContactListScreen
                }
                exact
              />
              <AdminRoute
                path="/contactlist/:id"
                component={
                  ContactEditScreen
                }
                exact
              />
              <AdminRoute
                path="/productlist"
                component={
                  ProductListScreen
                }
                exact
              />
              <AdminRoute
                path="/brandslist"
                component={
                  BrandsListScreen
                }
                exact
              />
              <AdminRoute
                path="/userlist"
                component={
                  UserListScreen
                }
              />
              <AdminRoute
                path="/user/:id/edit"
                component={
                  UserEditScreen
                }
              />
              <AdminRoute
                path="/orderlist"
                component={
                  OrderListScreen
                }
                exact
              />
              <AdminRoute
                path="/orderlist/:orderId"
                component={
                  OrderActionsScreen
                }
                exact
              />
              <AdminRoute
                path="/specials"
                component={
                  SpecialsScreen
                }
                exact
              />
              <AdminRoute
                path="/specials/new"
                component={
                  SpecialsCreateScreen
                }
                exact
              />
              <AdminRoute
                path="/specials/edit"
                component={
                  SpecialsEditScreen
                }
                exact
              />
              {/* <AdminRoute
                path="/carousel/upload"
                component={
                  UploadBannerImagesScreen
                }
                exact
              /> */}
              <Route
                path="/"
                component={HomeScreen}
                exact
              />
              <Route
                path="*"
                component={
                  NotFoundScreen
                }
              />
            </Switch>
          </ErrorBoundary>
        </main>
        <ScrollToTopContainer
          {...{ show: showScrollToTop }}
          onClick={scrollToTop}
        >
          {IconService.getIcon("up")}
        </ScrollToTopContainer>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
}

export default App;
