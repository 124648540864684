export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_GUEST_CREATE_REQUEST = "ORDER_GUEST_CREATE_REQUEST";
export const ORDER_GUEST_CREATE_SUCCESS = "ORDER_GUEST_CREATE_SUCCESS";
export const ORDER_GUEST_CREATE_FAIL = "ORDER_GUEST_CREATE_FAIL";
export const ORDER_GUEST_CREATE_RESET = "ORDER_GUEST_CREATE_RESET";

export const ORDER_MINE_LIST_REQUEST = "ORDER_MINE_LIST_REQUEST";
export const ORDER_MINE_LIST_SUCCESS = "ORDER_MINE_LIST_SUCCESS";
export const ORDER_MINE_LIST_FAIL = "ORDER_MINE_LIST_FAIL";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_GUEST_DETAILS_REQUEST = "ORDER_GUEST_DETAILS_REQUEST";
export const ORDER_GUEST_DETAILS_SUCCESS = "ORDER_GUEST_DETAILS_SUCCESS";
export const ORDER_GUEST_DETAILS_FAIL = "ORDER_GUEST_DETAILS_FAIL";

export const ORDER_GET_DETAILS_REQUEST = "ORDER_GET_DETAILS_REQUEST";
export const ORDER_GET_DETAILS_SUCCESS = "ORDER_GET_DETAILS_SUCCESS";
export const ORDER_GET_DETAILS_FAIL = "ORDER_GET_DETAILS_FAIL";
export const ORDER_GET_DETAILS_RESET = "ORDER_GET_DETAILS_RESET";

export const ORDER_SHIP_REQUEST = 'ORDER_SHIP_REQUEST';
export const ORDER_SHIP_SUCCESS = 'ORDER_SHIP_SUCCESS';
export const ORDER_SHIP_FAIL = 'ORDER_SHIP_FAIL';
export const ORDER_SHIP_RESET = 'ORDER_SHIP_RESET';

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST';
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS';
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL';
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET';