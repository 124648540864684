import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../../button/button";
import Input from "../../input/input";
import { detailsOrder, shipOrder } from "../../../actions/orderActions";
import inputStyles from "../../input/input.module.css";
import { shippingCarriers } from "../../../interfaces/enums";
import ConfirmModal from "../../utils/confirmModal";
import LoadingCog from "../../loadingCog";
import contentLayoutStyles from "../../../styles/contentLayout.module.css";
import { ORDER_SHIP_RESET } from "../../../constants/orderConstants";

const StyledInput = styled.div`
  margin: 10px 0;
`;

const ShipOrder = ({
  order,
  loading,
  success,
  error,
  shipOrder,
  detailsOrder,
}) => {
    const dispatch = useDispatch();
  const [shippingNumber, setShippingNumber] = useState("");
  const [shippingCarrier, setShippingCarrier] = useState("");

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirm = () => {
    shipOrder({
      id: order._id,
      shippingNumber,
      shippingCarrier,
    });
  };

  useEffect(() => {
    if (success) {
      detailsOrder(order._id);
      dispatch({type: ORDER_SHIP_RESET})
    }
  }, [success, detailsOrder, order._id, dispatch]);

  const openConfirmModal = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };
  return (
    <>
      <form onSubmit={(e) => openConfirmModal(e)}>
        <StyledInput>
          <Input
            name={"Shipping Number"}
            value={shippingNumber}
            onChange={setShippingNumber}
            required
            withLabel
            placeholder={"Enter Shipping Number"}
          />
        </StyledInput>
        <StyledInput>
          <label className={inputStyles.label}>Shipping Carrier</label>
          <select
            className={`${inputStyles.inputSelect} ${inputStyles.inputSelectNormal} ${inputStyles.inputSelectNoBorder}`}
            value={shippingCarrier}
            defaultValue={""}
            onChange={(e) => {
              setShippingCarrier(e.target.value);
            }}
          >
            <option disabled value="">
              Select...
            </option>
            {shippingCarriers.map((carrier, index) => (
              <option key={index} value={carrier}>
                {carrier}
              </option>
            ))}
          </select>
        </StyledInput>
        <div className={contentLayoutStyles.errorMessage}>{error && error}</div>
        <Button
          fullWidth
          type={"submit"}
          disabled={!shippingCarrier || !shippingNumber}
        >
          {loading ? <LoadingCog /> : "Ship Order"}
        </Button>
      </form>
      {showConfirmModal && (
        <ConfirmModal
          {...{
            show: showConfirmModal,
            denyAction: closeConfirmModal,
            confirmAction: handleConfirm,
            confirmText: "Confirm",
            denyText: "Cancel",
            message: "Are you sure you want to ship this order?",
          }}
        />
      )}
    </>
  );
};

export default connect(
  ({ orderShip }) => ({
    loading: orderShip.loading,
    success: orderShip.success,
    error: orderShip.error,
  }),
  { shipOrder, detailsOrder }
)(ShipOrder);
