import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { detailsOrder } from "../../actions/orderActions";
import DeliverOrder from "../../components/order/deliverOrder/deliverOrder";
import ShipOrder from "../../components/order/shipOrder/shipOrder";
import contentLayoutStyles from "../../styles/contentLayout.module.css";

const StyledContainer = styled.div`
  margin-top: 15px;
  color: var(--primary-dark);
  font-size: 18px;
`;
const Title = styled.div`
  color: var(--primary-dark);
  font-size: 24px;
  font-weight: 600;
`;

const OrderActionsScreen = ({ order, detailsOrder, ...props }) => {
  const orderId = props.match.params.orderId;

  useEffect(() => {
    if (!order || order?._id !== orderId) {
      detailsOrder(orderId);
    }
  }, [detailsOrder, order, orderId]);


  return (
    <div className={"contentContainer"}>
      <div className="row center" style={{ marginTop: "60px" }}>
        <div className={contentLayoutStyles.card}>
          <Title>Order #{orderId}</Title>
          <StyledContainer>
            {order && (
              <>
                <div style={{ textAlign: "center" }}>
                  Order Status:&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {order.isDelivered
                      ? "Delivered"
                      : order.isShipped
                        ? "Shipped"
                        : "Awaiting Shipment"}
                  </span>
                </div>
                {!order.isDelivered && !order.isShipped ? (
                  <>
                    <ShipOrder {...{ order }} />
                  </>
                ) : order.isShipped && !order.isDelivered ? (
                  <>
                    <DeliverOrder {...{ order }} />
                  </>
                ) : null}
              </>
            )}
          </StyledContainer>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ orderDetails }) => ({
    order: orderDetails.order,
  }),
  { detailsOrder }
)(OrderActionsScreen);
