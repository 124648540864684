import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { priceToggle } from "../../actions/searchActions";
import { priceRanges } from "../../interfaces/enums";
import IconService from "../../services/iconService";
import CheckboxSmall from "../checkboxSmall";
import { SectionTitle, StyledFilterCount } from "./styles";

const ToggleContainer = styled.div`
  color: var(--primary);
  font-size: 16px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  padding: 3px 0;
`;

const ItemsContainer = styled.div`
  margin-top: ${({ show }) => (show ? "5px" : "0")};
  height: ${({ show }) => (show ? "auto" : "0")};
  transition: opacity 0.1s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

const PriceSection = ({ selectedPrices, priceToggle }) => {
  const [showDropdown, setShowDropdown] = useState(true);
  return (
    <div>
      <div
        className={"row"}
        style={{ cursor: "pointer" }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <SectionTitle>
          Price
          <StyledFilterCount {...{ show: !!selectedPrices.length }}>
            {selectedPrices.length}
          </StyledFilterCount>
        </SectionTitle>
        <ToggleContainer>
          {showDropdown
            ? IconService.getIcon("minus")
            : IconService.getIcon("plus")}
        </ToggleContainer>
      </div>
      <ItemsContainer show={showDropdown}>
        {priceRanges?.map((item, index) => {
          return (
            <CheckboxContainer key={index}>
              <CheckboxSmall
                checked={selectedPrices.includes(item)}
                handleCheck={() => priceToggle(item)}
                label={item.displayName}
                name={item.displayName}
              />
            </CheckboxContainer>
          );
        })}
      </ItemsContainer>
    </div>
  );
};

export default connect(
  ({ searchFilter }) => ({
    selectedPrices: searchFilter.prices,
  }),
  { priceToggle }
)(PriceSection);
