import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "../../button/button";
import { deliverOrder, detailsOrder } from "../../../actions/orderActions";
import ConfirmModal from "../../utils/confirmModal";
import LoadingCog from "../../loadingCog";
import contentLayoutStyles from "../../../styles/contentLayout.module.css";
import { ORDER_DELIVER_RESET } from "../../../constants/orderConstants";

const DeliverOrder = ({
  order,
  loading,
  success,
  error,
  detailsOrder,
  deliverOrder,
}) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirm = () => {
    deliverOrder(order._id);
  };

  useEffect(() => {
    if (success) {
      detailsOrder(order._id);
      dispatch({ type: ORDER_DELIVER_RESET });
    }
  }, [success, detailsOrder, order._id, dispatch]);

  const openConfirmModal = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <div>
        <div className={contentLayoutStyles.errorMessage}>{error && error}</div>
        <Button fullWidth type={"button"} onClick={(e) => openConfirmModal(e)}>
          {loading ? <LoadingCog /> : "Deliver Order"}
        </Button>
      </div>
      {showConfirmModal && (
        <ConfirmModal
          {...{
            show: showConfirmModal,
            denyAction: closeConfirmModal,
            confirmAction: handleConfirm,
            confirmText: "Confirm",
            denyText: "Cancel",
            message: "Are you sure you want to deliver this order?",
          }}
        />
      )}
    </>
  );
};

export default connect(
  ({ orderDeliver }) => ({
    loading: orderDeliver.loading,
    success: orderDeliver.success,
    error: orderDeliver.error,
  }),
  { deliverOrder, detailsOrder }
)(DeliverOrder);
