import React from 'react';
import Tooltip from '../tooltip/tooltip';
import IconService from "../../services/iconService";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import successScreenStyles from "../../styles/successScreen.module.css";
import OrderItem from './orderItem/orderItem';

const OrderShipment = ({order}) => {
    const shipping = order && order.itemsPrice > 150 ? "Free" : "$15.00";

    return (
      <div className={contentLayoutStyles.backgroundContainer}>
        <div className={successScreenStyles.titleSmall}>Items in Shipment</div>
        {order.orderItems.map((item) => {
          return <OrderItem item={item} key={item._id} />;
        })}
        <div className={successScreenStyles.orderInfo}>
          <div className={successScreenStyles.rowPayment}>
            <div className={successScreenStyles.subtotal}>Subtotal</div>
            <div className={successScreenStyles.subtotal}>
              $
              {order.orderItems
                .reduce((a, c) => a + c.qty * c.price, 0)
                .toFixed(2)}
            </div>
          </div>
          <div className={successScreenStyles.rowPayment}>
            <div className={successScreenStyles.subtotal}>
              Shipping
              <Tooltip
                content={"Orders over $150 qualify for free shipping"}
                direction="top"
              >
                {IconService.getIcon("info", successScreenStyles.infoCircle)}
              </Tooltip>
            </div>
            <div className={successScreenStyles.subtotal}>{shipping}</div>
          </div>
          <div className={successScreenStyles.rowPayment}>
            <div className={successScreenStyles.subtotal}>Tax</div>
            <div className={successScreenStyles.subtotal}>
              ${order.taxPrice.toFixed(2)}
            </div>
          </div>
          <div
            className={successScreenStyles.rowPayment}
            style={{ marginBottom: "0px" }}
          >
            <div className={successScreenStyles.total}>Total</div>
            <div className={successScreenStyles.total}>
              ${order.totalPrice.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    );
};

export default OrderShipment;