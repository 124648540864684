import React, { useEffect } from 'react';
import contentLayoutStyles from '../../styles/contentLayout.module.css';
import modalStyles from "../../components/modal/modal.module.css";
import styled from 'styled-components';

const StyledContainer = styled.div`
    margin-top: 15px;
    color: var(--primary-dark);
    text-align: center;
    font-size: 18px;
    div {
        margin-top: 5px;
    }
`

const ShippingScreen = () => {
  useEffect(() => {
    document.title = "Shipping Information | Shop at ShotDocMD";
  }, []);
  return (
    <div className="content-container">
      <div className="row center" style={{ marginTop: "60px" }}>
        <div className={contentLayoutStyles.card}>
          <div className={modalStyles.modalContent}>
            <div className={modalStyles.title}>Shipping Policy</div>
            <StyledContainer>
              All orders with a subtotal of $150 USD and over qualify for free
              shipping.
              <div>
                Standard shipping adds an additional fee of $15 USD.
              </div>
              {/* TODO: add info about shipping carrier */}
            </StyledContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingScreen;