import React from "react";
import styled from "styled-components";
import IconService from "../../../services/iconService";

const Container = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: var(
    --secondary-bg-color
  );
  margin-bottom: 25px;
  grid-template-columns: 1/3;
`;

const StyledHeader = styled.div`
  font-size: 20px;
  color: var(--primary-dark);
  font-weight: 600;
  text-align: center;
`;

const StyledSubheading = styled.div`
  color: var(--primary-dark);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledInfo = styled.div`
  color: var(--primary);
  font-size: 17px;
  font-weight: 500;
`;
const StyledOtherInfo = styled.div`
  color: var(--primary);
  font-size: 17px;
  font-weight: 500;
  white-space: pre-wrap;
  margin-left: 8px;
`;
const InfoContainer = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 5px;
  margin-left: 8px;
`;

const StyledIcon = styled.div`
  color: var(--primary-dark);
  margin: 2px 0;
`;

const MoreInfo = ({ product }) => {
  return product.size ||
    !!product.facts?.length ||
    !!product.includedItems?.length ||
    product.moreInfo ? (
    <Container>
      <StyledHeader>
        More About this Product
      </StyledHeader>
      {product.size && (
        <InfoContainer>
          <StyledSubheading>
            Size
          </StyledSubheading>{" "}
          <SmallGrid>
            <StyledIcon>
              {IconService.getIcon(
                "caretright"
              )}
            </StyledIcon>
            <StyledInfo>
              {product.size}
            </StyledInfo>
          </SmallGrid>{" "}
        </InfoContainer>
      )}
      {!!product.includedItems
        ?.length && (
        <InfoContainer>
          <StyledSubheading>
            Included Items
          </StyledSubheading>
          {product.includedItems.map(
            (item, index) => {
              return (
                <SmallGrid key={index}>
                  <StyledIcon>
                    {IconService.getIcon(
                      "caretright"
                    )}
                  </StyledIcon>
                  <StyledInfo>
                    {item}
                  </StyledInfo>
                </SmallGrid>
              );
            }
          )}
        </InfoContainer>
      )}
      {!!product.facts?.length && (
        <InfoContainer>
          <StyledSubheading>
            Key Benefits
          </StyledSubheading>
          {product.facts.map(
            (fact, index) => {
              return (
                <SmallGrid key={index}>
                  <StyledIcon>
                    {IconService.getIcon(
                      "caretright"
                    )}
                  </StyledIcon>
                  <StyledInfo>
                    {fact}
                  </StyledInfo>
                </SmallGrid>
              );
            }
          )}
        </InfoContainer>
      )}
      {product.moreInfo && (
        <InfoContainer>
          <StyledSubheading>
            Additional Information
          </StyledSubheading>
          <StyledOtherInfo>
            {product.moreInfo}
          </StyledOtherInfo>
        </InfoContainer>
      )}
    </Container>
  ) : null;
};

export default MoreInfo;
