import {
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_SAVE_ADDRESS_REQUEST,
  USER_SAVE_ADDRESS_SUCCESS,
  USER_SAVE_ADDRESS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_DELETE_ADDRESS_REQUEST,
  USER_DELETE_ADDRESS_SUCCESS,
  USER_DELETE_ADDRESS_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
} from "../constants/userConstants";
import { axiosInstance } from "../api";

export const register =
  (firstName, lastName, email, password) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_REQUEST, payload: { email, password } });
    try {
      const { data } = await axiosInstance.post(`/auth/register`, {
        firstName,
        lastName,
        email,
        password,
      });
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
      localStorage.setItem("tokenState", JSON.stringify(data.token));
      dispatch(checkIfAdmin());
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
  try {
    const { data } = await axiosInstance.post(`/auth/signin`, { email, password });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data.userInfo));
    localStorage.setItem("tokenState", JSON.stringify(data.token));
    dispatch(checkIfAdmin());
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const checkIfAdmin = () => async (dispatch, getState) => {
  dispatch({
    type: "USER_IS_ADMIN_LOADING",
  });
  const {
    userSignin: { tokenState },
  } = getState();
  if (!tokenState) {
    dispatch({
      type: "USER_IS_ADMIN",
      payload: false,
    });
    return;
  }

  const { data } = await axiosInstance.get(`/auth/is-admin`, {
    headers: {
      Authorization: `Bearer ${tokenState.token}`,
    },
  });
  if (data && data.isAdmin) {
    dispatch({
      type: "USER_IS_ADMIN",
      payload: data.isAdmin,
    });
  } else {
    dispatch({
      type: "USER_IS_ADMIN",
      payload: false,
    });
  }
};

export const refreshToken = () => async (dispatch) => {
  dispatch({ type: "REFRESH_TOKEN_REQUEST" });
  try {
    const { data } = await axiosInstance.post(`/auth/refresh`);
    console.log(data)
    localStorage.setItem("tokenState", JSON.stringify(data));
    dispatch({ type: "REFRESH_TOKEN_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "REFRESH_TOKEN_FAIL", payload: message });
  }
};
export const signout = () => async (dispatch, getState) => {
  const {
    userSignin: { tokenState },
  } = getState();

  try {
    await axiosInstance.delete(`/auth/logout`, {
      headers: {
        Authorization: `Bearer ${tokenState.token}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
  localStorage.removeItem("userInfo");
  localStorage.removeItem("tokenState");
  localStorage.removeItem("cartItems");
  dispatch({ type: USER_SIGNOUT });
  dispatch({ type: USER_DETAILS_FAIL });
  dispatch({
    type: "USER_IS_ADMIN",
    payload: false,
  });
};

export const listUsers = () => async (dispatch, getState) => {
  dispatch({ type: USER_LIST_REQUEST });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.get(`/users`, {
      headers: {
        Authorization: `Bearer ${tokenState.token}`,
      },
    });
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_LIST_FAIL, payload: message });
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: userId });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.delete(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${tokenState.token}`,
      },
    });
    dispatch({ type: USER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DELETE_FAIL, payload: message });
  }
};

export const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.get(`/users/${userId}`, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_REQUEST, payload: user });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.put(`/users/${user._id}`, user, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_FAIL, payload: message });
  }
};

export const userSaveAddress = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_SAVE_ADDRESS_REQUEST, payload: user });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.post(`/users/shipping/${user.id}`, user, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: USER_SAVE_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_SAVE_ADDRESS_FAIL, payload: message });
  }
};

export const userDeleteAddress =
  (user, address) => async (dispatch, getState) => {
    dispatch({ type: USER_DELETE_ADDRESS_REQUEST, payload: user });
    const {
      userSignin: { tokenState },
    } = getState();
    try {
      const { data } = await axiosInstance.delete(
        `/users/shipping/${user}/${address}`,
        {
          headers: { Authorization: `Bearer ${tokenState.token}` },
        }
      );
      dispatch({ type: USER_DELETE_ADDRESS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: USER_DELETE_ADDRESS_FAIL, payload: message });
    }
  };

export const updateUserProfile = (user) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.put(`/users/profile`, user, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("tokenState", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};

export const updateUserPassword = (passData) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PASSWORD_REQUEST });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.put(`/auth/password`, passData, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: USER_UPDATE_PASSWORD_FAIL, payload: message });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: "USER_FORGOT_PASSWORD_REQUEST" });
  try {
    const { data } = await axiosInstance.post(`/auth/forgot-password`, email);
    dispatch({ type: "USER_FORGOT_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "USER_FORGOT_PASSWORD_FAIL", payload: message });
  }
};

export const resetPassword = (formData) => async (dispatch) => {
  dispatch({ type: "USER_RESET_PASSWORD_REQUEST" });
  try {
    const { data } = await axiosInstance.post(`/auth/reset-password`, formData);
    dispatch({ type: "USER_RESET_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "USER_RESET_PASSWORD_FAIL", payload: message });
  }
};
