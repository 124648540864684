import React from "react";
import styles from './radioSelect.module.css';

const RadioSelect = (props) => {
  return (
    <div>
      <label class={styles.container}>
        {props.children}
        <input type="radio" checked={props.checked} name="radio" />
        <span class={styles.checkmark}></span>
      </label>
    </div>
  );
};

export default RadioSelect;
