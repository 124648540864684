import React, { Component } from "react";
import productCardStyles from "./productCard.module.css";

class ProductCardSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={productCardStyles.skeletonContainer}>
        <div className={productCardStyles.imageContainer}>
          <div className={productCardStyles.imageSkeleton}>
            {" "}
            <div
              className={productCardStyles.shineLarge}
              style={{ height: "100%", width: "100%" }}
            ></div>
          </div>
        </div>
        <div className={productCardStyles.cardTextContainerNoReviews}>
          <div>
            <div
              className={
                productCardStyles.productNameSkeleton +
                " " +
                productCardStyles.shine
              }
            ></div>
            <div
              className={
                productCardStyles.productDescriptionSkeleton +
                " " +
                productCardStyles.shine
              }
            ></div>
          </div>
          <div className={productCardStyles.priceContainer}>
            <div
              className={
                productCardStyles.priceContainerSkeleton +
                " " +
                productCardStyles.shine
              }
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCardSkeleton;
