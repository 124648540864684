import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { checkIfAdmin } from "../actions/userActions";

const AdminRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  const userIsAdmin = useSelector((state) => state.userIsAdmin);
  const { isAdmin, success, loading } = userIsAdmin;

  useEffect(() => {
    if (!success && !loading) {
      dispatch(checkIfAdmin())
    }
  }, [success, loading, dispatch]);


  if (!success) {
    return null
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    ></Route>
  );
};

export default AdminRoute;
