import React from "react";
import ImageGallery from "react-image-gallery";
import IconService from "../../services/iconService";
import styles from "./index.module.css";

import carousel2 from "../../images/carousel/carousel2.png";
import carousel6 from "../../images/carousel/carousel6.png";
import carousel7 from "../../images/carousel/carousel7.png";

const images = [
  {
    original: carousel2,
  },
  {
    original: carousel6,
  },
  {
    original: carousel7,
  },
];

const LeftArrow = ({ onClick }) => {
  return (
    <div className={styles.buttonContainerLeft}>
      <div onClick={onClick}>
        {IconService.getIcon("left-arrow", styles.arrow)}
      </div>
    </div>
  );
};
const RightArrow = ({ onClick }) => {
  return (
    <div className={styles.buttonContainerRight}>
      <div onClick={onClick}>
        {IconService.getIcon("right-arrow", styles.arrow)}
      </div>
    </div>
  );
};

const Carousel = () => {
  return (
    <ImageGallery
      items={images}
      showThumbnails={false}
      showFullscreenButton={false}
      showPlayButton={false}
      autoPlay
      slideInterval={5000}
      renderLeftNav={(onClick, disabled) => (
        <LeftArrow {...{ onClick, disabled }} />
      )}
      renderRightNav={(onClick, disabled) => (
        <RightArrow {...{ onClick, disabled }} />
      )}
    />
  );
};

export default Carousel;
