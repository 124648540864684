import React from "react";
import screenStyles from '../../styles/screen.module.css';

const Select = ({handleChange, value, options, width}) => {
  return (
    <div>
      <select
        className={screenStyles.select}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        style={width ? {width: width} : {width: 'auto'}}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} defaultValue={option.default}>
            {option.text ?? option.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
