export const specialListReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case "SPECIAL_LIST_REQUEST":
            return { loading: true };
        case "SPECIAL_LIST_SUCCESS":
            return { loading: false, specials: action.payload };
        case "SPECIAL_LIST_FAIL":
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const specialDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case "SPECIAL_DETAILS_REQUEST":
            return { loading: true };
        case "SPECIAL_DETAILS_SUCCESS":
            return { loading: false, special: action.payload };
        case "SPECIAL_DETAILS_FAIL":
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const specialCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case "SPECIAL_CREATE_REQUEST":
            return { loading: true };
        case 'SPECIAL_CREATE_SUCCESS':
            return {
                loading: false,
                special: action.payload.special,
                success: true,
            };
        case 'SPECIAL_CREATE_FAIL':
            return { loading: false, error: action.payload, success: false };
        case 'SPECIAL_CREATE_RESET':
            return {};
        default:
            return state;
    }
};

export const specialUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case "SPECIAL_UPDATE_REQUEST":
            return { loading: true };
        case 'SPECIAL_UPDATE_SUCCESS':
            return {
                loading: false,
                special: action.payload.special,
                success: true,
            };
        case 'SPECIAL_UPDATE_FAIL':
            return { loading: false, error: action.payload, success: false };
        case 'SPECIAL_UPDATE_RESET':
            return {};
        default:
            return state;
    }
};

export const specialEditReducer = (state = {}, action) => {
    switch (action.type) {
        case "SPECIAL_EDIT_REQUEST":
            return { loading: true };
        case 'SPECIAL_EDIT_SUCCESS':
            return {
                loading: false,
                special: action.payload.special,
                success: true,
            };
        case 'SPECIAL_EDIT_FAIL':
            return { loading: false, error: action.payload, success: false };
        case 'SPECIAL_EDIT_RESET':
            return {};
        default:
            return state;
    }
};

export const specialDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case "SPECIAL_DELETE_REQUEST":
            return { loading: true };
        case 'SPECIAL_DELETE_SUCCESS':
            return {
                loading: false,
                special: action.payload.special,
                success: true,
            };
        case 'SPECIAL_DELETE_FAIL':
            return { loading: false, error: action.payload, success: false };
        case 'SPECIAL_DELETE_RESET':
            return {};
        default:
            return state;
    }
};