import React, { useState } from "react";
import IconService from "../../../../services/iconService";
import Modal from "../../../modal/modal";
import modalStyles from "../../../modal/modal.module.css";
import iconStyles from "../../../../styles/icon.module.css";
import screenStyles from "../../../../styles/screen.module.css";
import Button from "../../../button/button";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../../actions/cartActions";
import { Link, useHistory } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { Carousel } from "react-responsive-carousel";

const ProductModal = ({ product, show, closeModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [qty, setQty] = useState(1);

  function hideModal() {
    closeModal();
  }

  const addToCartHandler = () => {
    dispatch(addToCart(product._id, qty));
    history.push(`/cart`);
  };
  if (!product) {
    return null;
  }
  return (
    <Modal show={show} isLarge={true} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div>
        <div className={"row center"} style={{ marginBottom: "10px" }}>
          <div className={screenStyles.productName}>{product.name}</div>
        </div>
        <div className={screenStyles.contentModal}>
          <div className="row center">
            {product.images.length === 1 ? (
              <img
                src={
                  product.images
                    ? product.images[0]
                    : IconService.defaultProductImage
                }
                alt={product.name}
                className={screenStyles.image}
              />
            ) : (
              <Carousel showStatus={false} emulateTouch showArrows={false}>
                {product.images?.map((image, index) => {
                  return (
                    <img
                      src={image}
                      alt={product.name}
                      className={screenStyles.image}
                      key={index}
                    />
                  );
                })}
              </Carousel>
            )}
          </div>
          <div className={screenStyles.productInfoContainerModal}>
            <div>
              <div className={screenStyles.productDescriptionModal}>
                {product.description}
              </div>
              <Link to={`/product/${product._id}`}>
                <div className={screenStyles.seeMore}>See More...</div>
              </Link>
              <div className={screenStyles.productPrice}>
                ${product.price} USD
              </div>
            </div>
            <div
              className={
                screenStyles.backgroundContainerModal +
                " " +
                screenStyles.addToCartContainerModal
              }
            >
              <div className={screenStyles.rowSpaced}>
                <div className={screenStyles.subheading}>Status</div>
                <div>
                  {product.countInStock > 0 ? (
                    <span className={screenStyles.inStock}>In Stock</span>
                  ) : (
                    <span className={screenStyles.noStock}>Unavailable</span>
                  )}
                </div>
              </div>
              {product.countInStock > 0 && (
                <>
                  <div>
                    <div className={screenStyles.rowSpaced}>
                      <div className={screenStyles.subheading}>Quantity</div>
                      <div>
                        <select
                          className={screenStyles.select}
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: "10px", paddingBottom: "5px" }}>
                    <Button
                      onClick={addToCartHandler}
                      type={"button"}
                      fullWidth={true}
                      height={"40px"}
                    >
                      Add to Cart
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div
              className={`${screenStyles.backgroundContainerModal} ${screenStyles.shareContainerModal}`}
            >
              <div className={screenStyles.subheading}>SHARE</div>
              <div className={screenStyles.linkContainer}>
                {" "}
                <FacebookShareButton
                  url={`https://shop.shotdocmd.com/product/${product._id}`}
                  quote={"Check out this awesome product!"}
                >
                  <i className="fab fa-facebook-f share-link"></i>
                </FacebookShareButton>
              </div>
              <div className={screenStyles.linkContainer}>
                <TwitterShareButton
                  url={`https://shop.shotdocmd.com/product/${product._id}`}
                  title={`Check out this awesome product!: ${product.name}`}
                >
                  <i className="fab fa-twitter share-link"></i>
                </TwitterShareButton>
              </div>
              <div className={screenStyles.linkContainer}>
                <PinterestShareButton
                  url={`https://shop.shotdocmd.com/product/${product._id}`}
                  media={`https://shop.shotdocmd.com/${product.image}`}
                  description={
                    "Check out this awesome image from Shop at ShotDocMD!"
                  }
                >
                  <i className="fab fa-pinterest share-link"></i>
                </PinterestShareButton>
              </div>
              <div className={screenStyles.linkContainer}>
                <EmailShareButton
                  url={`https://shop.shotdocmd.com/product/${product._id}`}
                  subject={`Check out this product!: ${product.name}`}
                  body={`This awesome product is from shop at ShotDocMD. It is titled ${product.name}. A little bit about it: ${product.description}.  Check it out at:`}
                >
                  <i className="fas fa-envelope share-link"></i>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
