import React, { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import mobileStyles from "./mobileNavbar.module.css";
import logo from "../../assets/images/logo.png";
import logoSmall from "../../assets/images/logo-small.png";
import { signout } from "../../actions/userActions";
import { Link, useHistory } from "react-router-dom";
import IconService from "../../services/iconService";
import { useDispatch, useSelector } from "react-redux";
import Search from "../search/search";
import SignInModal from "../signinModal/signinModal";
import SignUpModal from "../signUpModal/signUpModal";
import {
  getAllAreasOfFocus,
  getAllBrands,
  getAllCategories,
  getAllConcerns,
} from "../../actions/productActions";
import { uppercase } from "../../utils";
import SearchMobile from "../search/searchMobile";
import MobileNavDrawer from "./mobileNavDrawer";

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userIsAdmin = useSelector((state) => state.userIsAdmin);
  const { isAdmin } = userIsAdmin;

  const getBrands = useSelector((state) => state.getBrands);
  const { brands } = getBrands;

  const getCategories = useSelector((state) => state.getCategories);
  const { categories } = getCategories;

  const getConcerns = useSelector((state) => state.getConcerns);
  const { concerns } = getConcerns;

  const getAreasOfFocus = useSelector((state) => state.getAreasOfFocus);
  const { areasOfFocus } = getAreasOfFocus;

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllBrands());
    dispatch(getAllConcerns());
    dispatch(getAllAreasOfFocus());
  }, [dispatch]);

  const [hasOpenedSearch, setHasOpenedSearch] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  function signoutHandler() {
    dispatch(signout());
  }

  function toggleDrawer() {
    setShowDrawer(!showDrawer);
  }

  function toggleSearch() {
    setShowSearch(!showSearch);
    if (!hasOpenedSearch) {
      setHasOpenedSearch(true);
    }
  }

  function openSignInModal() {
    setShowSignInModal(true);
  }
  function hideSignInModal() {
    setShowSignInModal(false);
  }
  function hideCreateAccountModal() {
    setShowCreateAccountModal(false);
  }

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showDrawer]);

  const handleLink = (to) => {
    if (to) {
      history.push(to);
    }
    toggleDrawer();
  };

  const signInToAccountCreate = () => {
    setShowSignInModal(false);
    setShowCreateAccountModal(true);
  };

  const accountCreateToSignIn = () => {
    setShowSignInModal(true);
    setShowCreateAccountModal(false);
  };

  return (
    <>
      <div className={styles.preNavContainer}>
        <div className={styles.preNavContent}>
          <div>
            {userInfo && (
              <div className={styles.preNavItemContainerStatic}>
                <span
                  className={
                    styles.preNavTextStatic + " " + styles.welcomeMessage
                  }
                  style={{ fontSize: "16px" }}
                >
                  Welcome back, {userInfo.firstName}
                </span>
              </div>
            )}
          </div>
          <div className={styles.row}>
            {isAdmin && (
              <Link to="/admin">
                <div className={styles.preNavItemContainer}>
                  {IconService.getIcon("admin", styles.preNavIcon)}{" "}
                  <span className={styles.preNavText}>Admin</span>
                </div>
              </Link>
            )}
            <a
              href="https://www.shotdocmd.com"
              rel="noreferrer"
              target="_blank"
            >
              <div className={styles.preNavItemContainer}>
                {IconService.getIcon("calendar", styles.preNavIcon)}{" "}
                <span className={styles.preNavText}>Book Now</span>
              </div>
            </a>
            <div>
              {userInfo ? (
                <div className={styles.row}>
                  <Link to="/my-account">
                    <div className={styles.preNavItemContainer}>
                      {IconService.getIcon("user", styles.preNavIcon)}{" "}
                      <span className={styles.preNavText}>My Account</span>
                    </div>
                  </Link>
                  <div>
                    <div
                      className={styles.preNavItemContainer}
                      onClick={signoutHandler}
                    >
                      {IconService.getIcon("signout", styles.preNavIcon)}{" "}
                      <span className={styles.preNavText}>Sign Out</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={styles.preNavItemContainer}
                  onClick={openSignInModal}
                >
                  {IconService.getIcon("user", styles.preNavIcon)}{" "}
                  <span className={styles.preNavText}>Sign In</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* web nav */}
      <div className={styles.navbarContainer}>
        <div className={styles.navbarContent}>
          <div className={styles.row}>
            <Link to="/">
              <img src={logo} className={styles.logo} alt={"logo"} />
            </Link>

            <div className={styles.navItem}>
              <div className={styles.dropdownOpenContainer}>
                <Link to="/search">
                  {" "}
                  <div className={`${styles.navItemText} ${styles.noWrap}`}>
                    shop
                  </div>
                </Link>
                <div className={styles.dropdown}>
                  <div className={styles.dropdownContainer}>
                    <div className={styles.dropdownContent}>
                      <div className={styles.dropdownSectionContainer}>
                        {" "}
                        <Link to="/brands">
                          <div className={styles.dropdownTitle}>
                            Shop By Brand
                          </div>
                        </Link>
                        {brands?.map((brand, index) => {
                          if (index < 4) {
                            return (
                              <Link to={`/brands/${brand}`} key={index}>
                                <div className={styles.dropdownLink}>
                                  {brand}
                                </div>
                              </Link>
                            );
                          }
                          if (index === 4) {
                            return (
                              <Link to={`/brands`} key={index}>
                                <div className={styles.dropdownLink}>
                                  +{brands.length - 4} more
                                </div>
                              </Link>
                            );
                          } else return null;
                        })}
                      </div>
                      <div className={styles.dropdownSectionContainer}>
                        {" "}
                        <Link to="/categories">
                          <div className={styles.dropdownTitle}>
                            Shop By Category
                          </div>
                        </Link>
                        {categories?.map((category, index) => {
                          if (index < 4) {
                            return (
                              <Link to={`/categories/${category}`} key={index}>
                                <div className={styles.dropdownLink}>
                                  {uppercase(category)}
                                </div>
                              </Link>
                            );
                          }
                          if (index === 4) {
                            return (
                              <Link to={`/categories`} key={index}>
                                <div className={styles.dropdownLink}>
                                  +{categories.length - 4} more
                                </div>
                              </Link>
                            );
                          } else return null;
                        })}
                      </div>{" "}
                      <div className={styles.dropdownSectionContainer}>
                        {" "}
                        <Link to="/concerns">
                          <div className={styles.dropdownTitle}>
                            Shop By Concern
                          </div>
                        </Link>
                        {concerns?.map((concern, index) => {
                          if (index < 4) {
                            return (
                              <Link to={`/concerns/${concern}`} key={index}>
                                <div className={styles.dropdownLink}>
                                  {uppercase(concern)}
                                </div>
                              </Link>
                            );
                          }
                          if (index === 4) {
                            return (
                              <Link to={`/concerns`} key={index}>
                                <div className={styles.dropdownLink}>
                                  +{concerns.length - 4} more
                                </div>
                              </Link>
                            );
                          } else return null;
                        })}
                      </div>
                      <div className={styles.dropdownSectionContainer}>
                        {" "}
                        <Link to="/areas-of-focus">
                          <div className={styles.dropdownTitle}>
                            Shop By Area of Focus
                          </div>
                        </Link>
                        {areasOfFocus?.map((area, index) => {
                          if (index < 4) {
                            return (
                              <Link to={`/areas-of-focus/${area}`} key={index}>
                                <div className={styles.dropdownLink}>
                                  {uppercase(area)}
                                </div>
                              </Link>
                            );
                          }
                          if (index === 4) {
                            return (
                              <Link to={`/areas-of-focus`} key={index}>
                                <div className={styles.dropdownLink}>
                                  +{areasOfFocus.length - 4} more
                                </div>
                              </Link>
                            );
                          } else return null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.navItem}>
              {" "}
              <Link to="/find-order">
                <div className={`${styles.navItemText} ${styles.noWrap}`}>
                  Find Order
                </div>
              </Link>
            </div>
          </div>
          <div className={`${styles.row} ${styles.searchCartContainer}`}>
            <Search name={"search"} placeholder={"Search"} />
            <div className={styles.cartContainer}>
              <Link to="/cart">
                {IconService.getIcon("cart", styles.cart)}
                {cartItems?.length > 0 && (
                  <div className={styles.cartCount}>
                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                  </div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.navPlaceholder}
        style={{ height: "8rem", width: "100%" }}
      ></div>
      {/* mobile nav */}
      <div className={mobileStyles.navbarContainer}>
        <div className={mobileStyles.navbarContent}>
          <div className={mobileStyles.navbarRow}>
            <div className={mobileStyles.sideContainer}>
              <div onClick={toggleDrawer}>
                {IconService.getIcon("menu", mobileStyles.menu)}
              </div>
            </div>
            <Link to="/">
              <img src={logo} className={mobileStyles.logo} alt={"logo"} />
              <img
                src={logoSmall}
                className={mobileStyles.logoSmallNav}
                alt={"logo"}
              />
            </Link>
            <div className={mobileStyles.sideContainer}>
              <div onClick={toggleSearch}>
                {IconService.getIcon("search", mobileStyles.search)}
              </div>
              <Link to="/cart">
                <div className={mobileStyles.cartContainer}>
                  {IconService.getIcon(
                    "cart",
                    styles.cart + " " + mobileStyles.cart
                  )}
                  {cartItems?.length > 0 && (
                    <div className={styles.cartCount}>
                      {cartItems.reduce((a, c) => a + c.qty, 0)}
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </div>
          <div
            className={`${hasOpenedSearch ? mobileStyles.navSearch : mobileStyles.hide
              } ${showSearch ? mobileStyles.searchShow : mobileStyles.searchHide
              }`}
          >
            <SearchMobile
              name={"search"}
              placeholder={"Search"}
              isMobile={true}
              closeSearch={() => setShowSearch(false)}
            />
          </div>
        </div>
      </div>
      <MobileNavDrawer {...{ showDrawer, toggleDrawer, handleLink }} />
      <div
        className={mobileStyles.navPlaceholder}
        style={{ height: "85px", width: "100%" }}
      ></div>
      {showSignInModal && (
        <SignInModal
          show={showSignInModal}
          closeModal={hideSignInModal}
          handleCreateAccount={signInToAccountCreate}
        />
      )}
      {showCreateAccountModal && (
        <SignUpModal
          show={showCreateAccountModal}
          closeModal={hideCreateAccountModal}
          handleSignIn={accountCreateToSignIn}
        />
      )}
    </>
  );
};

export default Navbar;
