import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import IconService from "../../services/iconService";
import iconStyles from "../../styles/icon.module.css";
import HorizontalLine from "../horizontalLine";
import FilterSection, { ItemsContainer } from "./filterSection";
import {
  getAllBrands,
  getAllCategories,
  getAllAreasOfFocus,
  getAllConcerns,
} from "../../actions/productActions";
import {
  brandToggle,
  categoryToggle,
  concernToggle,
  areaOfFocusToggle,
  clearFilters,
} from "../../actions/searchActions";
import {
  ContentContainer,
  ContentStickyContainer,
  StyledButton,
  StyledContainer,
  Title,
} from "./styles";
import PriceSection from "./priceSection";
import ButtonSmall from "../button/buttonSmall";

const Filter = ({
  brands,
  getAllBrands,
  categories,
  getAllCategories,
  concerns,
  getAllConcerns,
  areasOfFocus,
  getAllAreasOfFocus,
  selectedBrands,
  selectedCategories,
  selectedConcerns,
  selectedAreasOfFocus,
  brandToggle,
  categoryToggle,
  concernToggle,
  areaOfFocusToggle,
  sortType,
  prices,
  search,
  searchBrands,
  searchCategories,
  searchConcerns,
  searchAreas,
  clearFilters,
  omitType,
}) => {
  const [open, setOpen] = useState(false);

  const togglePanel = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (!brands) {
      getAllBrands();
    }
    if (!categories) {
      getAllCategories();
    }
    if (!concerns) {
      getAllConcerns();
    }
    if (!areasOfFocus) {
      getAllAreasOfFocus();
    }
    if (brands && categories && concerns && areasOfFocus) {
      setOpen(true);
    }
  }, [
    areasOfFocus,
    brands,
    categories,
    concerns,
    getAllAreasOfFocus,
    getAllBrands,
    getAllCategories,
    getAllConcerns,
  ]);

  const handleContainerClick = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const checkShow = useMemo(() => {
    return (
      (omitType === "brands" ? "" : !!searchBrands.length) ||
      (omitType === "categories" ? "" : !!searchCategories.length) ||
      (omitType === "concerns" ? "" : !!searchConcerns.length) ||
      (omitType === "areas" ? "" : !!searchAreas.length) ||
      !!prices.length ||
      !!sortType.length ||
      !!search.length
    );
  }, [
    searchBrands,
    searchCategories,
    searchConcerns,
    searchAreas,
    prices,
    sortType,
    search,
    omitType,
  ]);

  return (
    <StyledContainer {...{ open }} onClick={handleContainerClick}>
      <ContentStickyContainer>
        <StyledButton onClick={togglePanel} {...{ open }}>
          {open
            ? IconService.getIcon("left", iconStyles.toggleIcon)
            : IconService.getIcon("right", iconStyles.toggleIcon)}
        </StyledButton>
        <ContentContainer {...{ open }}>
          <Title>Filters</Title>
          <ItemsContainer show={checkShow}>
            <HorizontalLine />
            <div style={{ marginBottom: "10px" }}>
              <ButtonSmall
                variant="only-border"
                height="30px"
                fullWidth
                onClick={clearFilters}
              >
                Clear Filters
              </ButtonSmall>
            </div>
          </ItemsContainer>
          <HorizontalLine />
          {omitType !== "brands" && (
            <>
              <FilterSection
                {...{
                  title: "Brands",
                  items: brands,
                  handleCheck: brandToggle,
                  selected: selectedBrands,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "categories" && (
            <>
              <FilterSection
                {...{
                  title: "Categories",
                  items: categories,
                  handleCheck: categoryToggle,
                  selected: selectedCategories,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "concerns" && (
            <>
              <FilterSection
                {...{
                  title: "Concerns",
                  items: concerns,
                  handleCheck: concernToggle,
                  selected: selectedConcerns,
                }}
              />
              <HorizontalLine />
            </>
          )}
          {omitType !== "areas" && (
            <>
              <FilterSection
                {...{
                  title: "Areas of Focus",
                  items: areasOfFocus,
                  handleCheck: areaOfFocusToggle,
                  selected: selectedAreasOfFocus,
                }}
              />
              <HorizontalLine />
            </>
          )}
          <PriceSection />
          <HorizontalLine />
        </ContentContainer>
      </ContentStickyContainer>
    </StyledContainer>
  );
};

export default connect(
  ({
    getBrands,
    getCategories,
    getConcerns,
    getAreasOfFocus,
    searchFilter,
  }) => ({
    brands: getBrands.brands,
    categories: getCategories.categories,
    concerns: getConcerns.concerns,
    areasOfFocus: getAreasOfFocus.areasOfFocus,
    selectedBrands: searchFilter.brands,
    selectedCategories: searchFilter.categories,
    selectedConcerns: searchFilter.concerns,
    selectedAreasOfFocus: searchFilter.areasOfFocus,
    sortType: searchFilter.sortType,
    prices: searchFilter.prices,
    search: searchFilter.search,
    searchBrands: searchFilter.brands,
    searchCategories: searchFilter.categories,
    searchConcerns: searchFilter.concerns,
    searchAreas: searchFilter.areasOfFocus,
  }),
  {
    getAllBrands,
    getAllCategories,
    getAllConcerns,
    getAllAreasOfFocus,
    brandToggle,
    categoryToggle,
    concernToggle,
    areaOfFocusToggle,
    clearFilters,
  }
)(Filter);
