import React from "react";
import styled from "styled-components";
import SectionCard from "./sectionCard";
import brandsImage from "../../../images/banners/brandsImage.png";
import categoriesImage from "../../../images/banners/categoriesBanner.png";

const StyledContainer = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 10px;
`;

const ShopByContainer = () => {
  return (
    <StyledContainer>
      <SectionCard {...{ type: "Brand", image: brandsImage }} />
      <SectionCard {...{ type: "Category", image: categoriesImage }} />
    </StyledContainer>
  );
};

export default ShopByContainer;