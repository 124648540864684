import React, { useEffect, useState } from "react";
import IconService from "../../services/iconService";
import Modal from "../modal/modal";
import modalStyles from "../modal/modal.module.css";
import iconStyles from "../../styles/icon.module.css";
import Input from "../input/input";
import Button from "../button/button";
import LoadingCog from "../loadingCog";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../actions/userActions";
import PasswordInput from "../input/passwordInput";
import { Link } from "react-router-dom";

const SignInModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  function handleEmailChange(email) {
    setEmail(email);
  }
  function handlePasswordChange(pass) {
    setPassword(pass);
  }

  function hideModal() {
    setEmail("");
    setPassword("");
    props.closeModal();
  }

  useEffect(() => {
    if (userInfo) {
      hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  function signInHandler(e) {
    e.preventDefault();
    if (loading) return;
    dispatch(signin(email, password));
  }

  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Sign In</div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        <form onSubmit={signInHandler}>
          <Input
            withLabel={true}
            name={"Email Address"}
            type={"email"}
            required={true}
            onChange={handleEmailChange}
            value={email}
            placeholder={"Enter Email"}
          />
          <div className={modalStyles.paddingY}>
            <PasswordInput
              withLabel={true}
              name={"Password"}
              required={true}
              onChange={handlePasswordChange}
              value={password}
              placeholder={"Enter Password"}
            />
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <span className={modalStyles.text}>Forgot password?</span>&nbsp;
            <Link
              to="/forgot-password"
              onClick={hideModal}
              className={modalStyles.link}
            >
              Reset Password
            </Link>
          </div>
          <div className={modalStyles.errorMessageContainer}>
            {error && error}
          </div>
          <div>
            <Button
              type={"submit"}
              fullWidth={true}
              height={"45px"}
              disabled={loading}
            >
              {loading ? <LoadingCog /> : <>Sign In</>}
            </Button>
          </div>
        </form>
        <div className={modalStyles.linkRow}>
          New to Shop at ShotDocMD?{" "}
          <div className={modalStyles.link} onClick={props.handleCreateAccount}>
            Create an Account
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignInModal;
