function dateFormat(date, isShort) {
  let short = false;
  if (isShort) {
    short = true;
  }
  const dateElement = date.substring(0, 10);
  const year = dateElement.substring(0, 4);
  const month = dateElement.substring(5, 7);
  const day = dateElement.substring(8, 10);
  let formattedDate = "";
  if (month === "01") {
    if (short) {
      formattedDate += "Jan";
    } else {
      formattedDate += "January";
    }
  } else if (month === "02") {
    if (short) {
      formattedDate += "Feb";
    } else {
      formattedDate += "February";
    }
  } else if (month === "03") {
    if (short) {
      formattedDate += "Mar";
    } else {
      formattedDate += "March";
    }
  } else if (month === "04") {
    if (short) {
      formattedDate += "Apr";
    } else {
      formattedDate += "April";
    }
  } else if (month === "05") {
    formattedDate += "May";
  } else if (month === "06") {
    if (short) {
      formattedDate += "Jun";
    } else {
      formattedDate += "June";
    }
  } else if (month === "07") {
    if (short) {
      formattedDate += "Jul";
    } else {
      formattedDate += "July";
    }
  } else if (month === "08") {
    if (short) {
      formattedDate += "Aug";
    } else {
      formattedDate += "August";
    }
  } else if (month === "09") {
    if (short) {
      formattedDate += "Sep";
    } else {
      formattedDate += "September";
    }
  } else if (month === "10") {
    if (short) {
      formattedDate += "Oct";
    } else {
      formattedDate += "October";
    }
  } else if (month === "11") {
    if (short) {
      formattedDate += "Nov";
    } else {
      formattedDate += "November";
    }
  } else if (month === "12") {
    if (short) {
      formattedDate += "Dec";
    } else {
      formattedDate += "December";
    }
  }
  if (day.slice(0, 1) === "0") {
    formattedDate += " " + day.slice(1, 2) + ", " + year;
  } else {
    formattedDate += " " + day + ", " + year;
  }
  return formattedDate;
}

export default dateFormat;
