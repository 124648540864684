const INITIAL_STATE = {
  search: "",
  brands: [],
  categories: [],
  concerns: [],
  areasOfFocus: [],
  prices: [],
  sortType: "",
};

export const searchFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SEARCH_VALUE":
      return { ...state, search: action.payload };
    case "SET_BRANDS":
      return { ...state, brands: action.payload };
    case "SET_BRANDS_REFRESH":
      return { ...INITIAL_STATE, brands: action.payload };
    case "SET_CATEGORIES":
      return { ...state, categories: action.payload };
    case "SET_CATEGORIES_REFRESH":
      return { ...INITIAL_STATE, categories: action.payload };
    case "SET_CONCERNS":
      return { ...state, concerns: action.payload };
    case "SET_CONCERNS_REFRESH":
      return { ...INITIAL_STATE, concerns: action.payload };
    case "SET_AREAS_OF_FOCUS":
      return { ...state, areasOfFocus: action.payload };
    case "SET_AREAS_OF_FOCUS_REFRESH":
      return { ...INITIAL_STATE, areasOfFocus: action.payload };
    case "SET_PRICES":
      return { ...state, prices: action.payload };
    case "SET_SORT_FILTER":
      return { ...state, sortType: action.payload };
    case "RESET_FILTERS_KEEP_SEARCH":
        return {...INITIAL_STATE, search: state.search};
    case "RESET_FILTERS":
        return INITIAL_STATE;
    default:
      return state;
  }
};
