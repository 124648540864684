import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsProduct, getRelatedProducts } from "../../actions/productActions";
import MessageBox from "../../components/messageBox";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { addToCart } from "../../actions/cartActions";
import screenStyles from "../../styles/screen.module.css";
import LoadingCog from "../../components/loadingCog";
import IconService from "../../services/iconService";
import Button from "../../components/button/button";
import ReviewsModal from "../../components/reviewsModal/reviewsModal";
import SignInModal from "../../components/signinModal/signinModal";
import RelatedProductCard from "../../components/relatedProduct/relatedProductCard";
import { Link } from "react-router-dom";
import ReviewsCard from "../../components/product/reviews/reviewsCard";
import MoreInfo from "../../components/product/productScreen/moreInfo";
import RelatedProducts from "../../components/product/productScreen/relatedProducts";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function ProductScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const [qty, setQty] = useState(1);
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { success: successReviewCreate } = productReviewCreate;

  const productsRelated = useSelector((state) => state.productsRelated);
  const { relatedProducts } = productsRelated;

  useEffect(() => {
    if (!product || product._id !== productId || successReviewCreate) {
      dispatch(detailsProduct(productId));
      dispatch(getRelatedProducts(productId));
    }
  }, [dispatch, productId, product, successReviewCreate]);

  useEffect(() => {
    if (product) {
      document.title = `${product.name} | Shop at ShotDocMd`;
    }
  }, [product]);

  const addToCartHandler = () => {
    dispatch(addToCart(productId, qty));
    props.history.push(`/cart`);
  };

  const handleReviewButtonClick = () => {
    if (!userInfo) {
      setShowSignInModal(true);
    } else {
      setShowReviewsModal(true);
    }
  };

  const hideReviewsModal = () => {
    setShowReviewsModal(false);
  };
  const hideSignInModal = () => {
    setShowSignInModal(false);
  };

  useEffect(() => {
    if (showSignInModal && userInfo) {
      hideSignInModal();
      setShowReviewsModal(true);
    }
  }, [showSignInModal, userInfo]);

  return (
    <>
      <div className={"content-container"}>
        {loading ? (
          <div className="row center" style={{ height: '100%' }}>
            <LoadingCog isLarge={true} />
          </div>
        ) : error ? (
          <MessageBox variant={"danger"}>{error}</MessageBox>
        ) : (
          <>
            <div className={screenStyles.content}>
              <div>
                {product.images.length === 1 ? (
                  <img
                    src={
                      product.images
                        ? product.images[0]
                        : IconService.defaultProductImage
                    }
                    alt={product.name}
                    className={screenStyles.image}
                  />
                ) : (
                  <Carousel showStatus={false} emulateTouch showArrows={false}>
                    {product.images?.map((image, index) => {
                      return (
                        <img
                          src={image}
                          alt={product.name}
                          className={screenStyles.image}
                          key={index}
                        />
                      );
                    })}
                  </Carousel>
                )}
              </div>
              <div className={screenStyles.productInfoContainer}>
                <div>
                  <div className={screenStyles.productName}>{product.name}</div>
                  <div className={screenStyles.productDescription}>
                    {product.description}
                  </div>
                  <div className={screenStyles.productPrice}>
                    ${product.price} USD
                  </div>
                  <div className={screenStyles.backgroundContainer}>
                    <div className={screenStyles.rowSpaced}>
                      <div className={screenStyles.subheading}>Status</div>
                      <div>
                        {product.countInStock > 0 ? (
                          <span className={screenStyles.inStock}>In Stock</span>
                        ) : (
                          <span className={screenStyles.noStock}>
                            Unavailable
                          </span>
                        )}
                      </div>
                    </div>
                    {product.countInStock > 0 && (
                      <>
                        <div>
                          <div className={screenStyles.rowSpaced}>
                            <div className={screenStyles.subheading}>
                              Quantity
                            </div>
                            <div>
                              <select
                                className={screenStyles.select}
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              >
                                {[...Array(product.countInStock).keys()].map(
                                  (x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ paddingTop: "10px", paddingBottom: "5px" }}
                        >
                          <Button
                            onClick={addToCartHandler}
                            type={"button"}
                            fullWidth={true}
                            height={"40px"}
                          >
                            Add to Cart
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={`${screenStyles.backgroundContainer} ${screenStyles.shareContainer}`}
                  >
                    <div className={screenStyles.subheading}>SHARE</div>
                    <div className={screenStyles.linkContainer}>
                      {" "}
                      <FacebookShareButton
                        url={`https://shop.shotdocmd.com/product/${productId}`}
                        quote={"Check out this awesome product!"}
                      >
                        <i className="fab fa-facebook-f share-link"></i>
                      </FacebookShareButton>
                    </div>
                    <div className={screenStyles.linkContainer}>
                      <TwitterShareButton
                        url={`https://shop.shotdocmd.com/product/${productId}`}
                        title={`Check out this awesome product!: ${product.name}`}
                      >
                        <i className="fab fa-twitter share-link"></i>
                      </TwitterShareButton>
                    </div>
                    <div className={screenStyles.linkContainer}>
                      <PinterestShareButton
                        url={`https://shop.shotdocmd.com/product/${productId}`}
                        media={`https://shop.shotdocmd.com/${product.images[0]}`}
                        description={
                          "Check out this awesome product from Shop at ShotDocMD!"
                        }
                      >
                        <i className="fab fa-pinterest share-link"></i>
                      </PinterestShareButton>
                    </div>
                    <div className={screenStyles.linkContainer}>
                      <EmailShareButton
                        url={`https://shop.shotdocmd.com/product/${productId}`}
                        subject={`Check out this product!: ${product.name}`}
                        body={`This awesome product is from shop at ShotDocMD. It is titled ${product.name}. A little bit about it: ${product.description}.  Check it out at:`}
                      >
                        <i className="fas fa-envelope share-link"></i>
                      </EmailShareButton>
                    </div>
                  </div>
                  <ReviewsCard
                    {...{
                      product,
                      handleReviewButtonClick,
                    }}
                  />
                </div>
                <div className={screenStyles.relatedProducts}>
                  {relatedProducts?.length > 0 && (
                    <>
                      <Link to={`/brands/${product.brand}`}>
                        <div className={screenStyles.moreFrom}>
                          Also from {product.brand}
                        </div>
                      </Link>
                      {relatedProducts.map((related, index) => {
                        if (index > 2) return null;
                        return (
                          <RelatedProductCard product={related} key={index} />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={screenStyles.bottomContent}>
              <MoreInfo {...{ product }} />
              <div className={screenStyles.relatedProductsMobile}>
                <RelatedProducts {...{ product }} />
              </div>
            </div>
          </>
        )}
      </div>
      {showSignInModal && (
        <SignInModal
          show={showSignInModal}
          closeModal={hideSignInModal}
          subheading={"You must be signed in to review a product"}
        />
      )}
      {showReviewsModal && (
        <ReviewsModal
          show={showReviewsModal}
          closeModal={hideReviewsModal}
          product={product}
          userInfo={userInfo}
        />
      )}
    </>
  );
}
