import React from "react";
import { Link } from "react-router-dom";
import styles from "./relatedProductCard.module.css";

const RelatedProductCard = ({ product, noMarginBottom }) => {
  return (
    <Link to={`/product/${product._id}`}>
      <div
        className={styles.container}
        style={noMarginBottom ? { marginBottom: 0 } : {}}
      >
        <img
          src={product.images[0]}
          alt={product.name}
          className={styles.image}
        />
        <div className={`row center ${styles.nameContainer}`}>
          {" "}
          <div className={styles.name}>{product.name}</div>
        </div>
      </div>
    </Link>
  );
};

export default RelatedProductCard;
