import styled from "styled-components";
import COLORS from "../colors";

export const Flex = styled.div`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
    width: ${({ width }) => width ?? "auto"};
    justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};
    align-items: ${({ alignItems }) => alignItems ?? "stretch"};
    flex-wrap: ${({ flexWrap }) => flexWrap ?? "nowrap"};
`

export const Box = styled.div`
    display: block;
    margin: ${({ margin }) => margin ?? "auto"};
    padding: ${({ padding }) => padding ?? "auto"};
`


export const Title = styled.h1`
  font-size: 30px;
  color: ${COLORS.primary};
`

export const NoData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
  font-size: 18px;
  color: ${COLORS.primaryHover};
`