import axios from 'axios';
import React, { useState } from 'react';
import MediaService from '../../services/mediaService';
import { Box } from '../../styles/utils';
import LoadingCog from '../loadingCog';
import ImageCropModal from './imageCropModal';
import ImageDND from './imageDND';
import inputStyles from "../../components/input/input.module.css";
import MessageBox from '../messageBox';


const uploadUrl = "https://api.cloudinary.com/v1_1/laabroms/image/upload";

const ImageUploader = ({ multiple = true, images, setImages }) => {
    const [imageBlobs, setImageBlobs] = useState("");
    const [showImageModal, setShowImageModal] = useState(false);

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [uploadError, setUploadError] = useState("");

    const uploadFileHandler = (e) => {
        const fileArray = [];
        for (let x = 0; x < e.target.files?.length; x++) {
            fileArray.push({
                file: e.target.files[x],
                blob: URL.createObjectURL(e.target.files[x]),
            });
        }
        setImageBlobs(fileArray);
        setShowImageModal(true);
        setUploadError("");
    };

    const hideImageModal = () => {
        setShowImageModal(false);
    };

    const uploadImage = async (image) => {
        const data = new FormData();
        data.append("file", image.file);
        data.append("upload_preset", MediaService.cloudinaryUploadPreset);
        data.append("cloud_name", "laabroms");
        try {
            const res = await axios.post(uploadUrl, data);
            if (res) {
                const croppedImage = cropImageReturn(res, image);
                return croppedImage;
            }
        } catch (err) {
            console.error(err);
            setUploadError("Image is too large; choose a smaller image (<10MB)");
            return false;
        }
    };

    const cropImageReturn = (res, image) => {
        const returnedUrl = res.data.url;
        let urlSuffix = returnedUrl.split("/upload/")[1];

        const urlPrefix = "https://res.cloudinary.com/laabroms/image/upload";
        const croppedUrl =
            urlPrefix +
            `/c_crop,h_${image.croppedArea.height},w_${image.croppedArea.width},x_${image.croppedArea.x},y_${image.croppedArea.y}/` +
            urlSuffix;

        return croppedUrl;
    };

    const handleImageCrop = async (croppedImages) => {
        hideImageModal();
        setLoadingUpload(true);
        const imageUpload = await Promise.all(
            croppedImages.map(async (image) => {
                return await uploadImage(image);
            })
        );
        setImages(imageUpload);
        setLoadingUpload(false);
    };

    return (
        <>
            <Box>
                <label htmlFor="imageFile" className={inputStyles.label}>
                    Image Files
                </label>
                <input
                    style={{ marginTop: "5px", display: "block" }}
                    type="file"
                    id="imageFile"
                    label="Choose Image"
                    multiple={multiple}
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={uploadFileHandler}
                />
                {multiple &&
                    <div className={inputStyles.infoLabel}>
                        Please do not upload more than 5 images
                    </div>
                }
                {loadingUpload && <Box margin="5px 0"><LoadingCog /></Box>}
                {uploadError && <Box margin="5px 0"><MessageBox variant="danger">{uploadError}</MessageBox></Box>}
                {!!images.length && (
                    images.length > 1 ?
                        <ImageDND
                            images={images}
                            updateImages={(images) => setImages(images)}
                        />
                        :
                        <Box margin="5px 0">
                            <img src={images[0]} alt="uploaded" width="200px" />
                        </Box>
                )}
            </Box>
            {showImageModal && (
                <ImageCropModal
                    {...{
                        show: showImageModal,
                        closeModal: hideImageModal,
                        images: imageBlobs,
                        handleImageCrop: handleImageCrop,
                        showOrderStep: multiple
                    }}
                />
            )}
        </>
    );
};

export default ImageUploader;