import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconService from '../../../services/iconService';
import CheckoutInput from '../checkoutInput/checkoutInput';
import LoadingCog from '../../loadingCog';
import Button from '../../button/button';

// styles
import screenStyles from "../../../styles/screen.module.css";
import checkoutStyles from "../../../styles/checkout.module.css";
import modalStyles from '../../../styles/modal.module.css';
import { detailsUser, signin } from '../../../actions/userActions';

const CheckoutSignIn = () => {
    const dispatch = useDispatch();
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, loading, error } = userSignin;

    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const toggleUserDropdown = () => {
      setShowUserDropdown(!showUserDropdown);
    };

    useEffect(() => {
      if (userInfo) {
        dispatch(detailsUser(userInfo._id));
        setEmail("");
        setPassword("");
      }
    }, [userInfo, dispatch]);

    function signInHandler(e) {
      e.preventDefault();
      dispatch(signin(email, password));
    }

    return (
      <div>
        {!userInfo && (
          <div className={screenStyles.backgroundContainer}>
            <div
              className="row"
              style={{ cursor: "pointer" }}
              onClick={toggleUserDropdown}
            >
              <div className={screenStyles.subheading}>
                Sign In for faster checkout
              </div>
              <div className={"row"}>
                {showUserDropdown ? (
                  <>{IconService.getIcon("up", screenStyles.reviewsIcon)}</>
                ) : (
                  <>{IconService.getIcon("down", screenStyles.reviewsIcon)}</>
                )}
              </div>
            </div>
            {showUserDropdown && (
              <>
                <form onSubmit={signInHandler}>
                  <div className="row">
                    <div className={checkoutStyles.inputContainer}>
                      <CheckoutInput
                        withLabel={true}
                        name={"Email Address"}
                        type={"email"}
                        required={true}
                        onChange={(val) => setEmail(val)}
                        value={email}
                        placeholder={"Enter Email"}
                      />
                    </div>
                    <div className={checkoutStyles.inputContainer}>
                      <CheckoutInput
                        withLabel={true}
                        name={"Password"}
                        type={"password"}
                        required={true}
                        onChange={(val) => setPassword(val)}
                        value={password}
                        placeholder={"Enter Password"}
                      />
                    </div>
                  </div>
                  <div className={modalStyles.errorMessageContainer}>
                    {error && error}
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      type={"submit"}
                      fullWidth={true}
                      height={"45px"}
                      disabled={loading}
                    >
                      {loading ? <LoadingCog /> : <>Sign In</>}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        )}
      </div>
    );
};

export default CheckoutSignIn;
