import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";

//styles
import screenStyles from "../../styles/screen.module.css";
import checkoutStyles from "../../styles/checkout.module.css";
import Button from "../button/button";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutPayment = (props) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: props.clientSecret,
    theme: "stripe",
  };

  const [isComplete, setIsComplete] = useState(false);
  const [paymentType, setPaymentType] = useState('');

  const handleComplete = (val, type) => {
    setIsComplete(val)
    setPaymentType(type)
  }

  const handlePayment = () => {
    if (isComplete) {
      props.handlePayment(paymentType);
    }
  }
  return (
    <>
      <div className={screenStyles.backgroundContainer}>
        <div
          className={checkoutStyles.subheading}
          style={{ marginBottom: "10px" }}
        >
          Payment
        </div>
        <div className={checkoutStyles.inputPadding}>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm handleComplete={handleComplete} shippingState={props.shippingState} makePayment={props.makePayment} handlePaymentError={props.handlePaymentError} handlePaymentSuccess={props.handlePaymentSuccess} />
          </Elements>
        </div>
      </div>
      <div className={checkoutStyles.buttonContainer}>
        <div className={checkoutStyles.columnRight}>
          {" "}
          <Button
            type="button"
            onClick={handlePayment}
            padding={"10px 20px"}
            disabled={!isComplete}
          >
            {props.isEdit ? "Update" : "Review Your Order"}
          </Button>
          {!props.isEdit && (
            <div className={checkoutStyles.stepsText}>
              Proceed to step 3 of 3
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutPayment;
