import React from "react";
import Modal from "../../modal/modal";
import modalStyles from "../../modal/modal.module.css";
import iconStyles from "../../../styles/icon.module.css";
import Button from "../../button/button";

import IconService from "../../../services/iconService";


const UserEditTypeModal = (props) => {

  function hideModal() {
    props.closeModal();
  }

  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Edit Account</div>
        <div className={modalStyles.subheading}>What do you want to edit?</div>
        <div style={{ marginTop: "15px" }}>
          <Button
            type={"submit"}
            fullWidth={true}
            height={"45px"}
            onClick={props.openEditModal}
          >
            Account Information
          </Button>
        </div>
        <div style={{ marginTop: "15px" }}>
          <Button
            type={"submit"}
            fullWidth={true}
            height={"45px"}
            onClick={props.openPassEditModal}
          >
            Password
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserEditTypeModal;
