import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteSpecial, listSpecials } from '../../../actions/specialActions';
import Button from '../../../components/button/button';
import LoadingBox from '../../../components/loadingBox';
import MessageBox from '../../../components/messageBox';
import { Box, NoData } from '../../../styles/utils';
import { formatDate } from "../../../utils/date"

const SpecialsTable = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, specials, error } = useSelector((state) => state.specialList);

    const { success, error: errorDelete } = useSelector((state) => state.specialDelete);

    const deleteHandler = (specialId) => {
        if (window.confirm("Are you sure you want to delete this special?")) {
            dispatch(deleteSpecial(specialId));
        }
    };

    useEffect(() => {
        dispatch(listSpecials())
    }, [dispatch])

    useEffect(() => {
        if (success) {
            dispatch({ type: "SPECIAL_DELETE_RESET" });
            dispatch(listSpecials())
        }
    }, [success, dispatch])

    return loading ? (
        <LoadingBox />
    ) : error ? (
        <MessageBox variant="danger">
            {error}
        </MessageBox>
    ) : (
        <Box>
            <Box margin="5px 0">
                {errorDelete && <MessageBox variant="danger">{error}</MessageBox>}
            </Box>
            <div
                style={{ overflow: "auto" }}
            >
                {!!specials.length ?
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Visible</th>
                                <th>Visible On</th>
                                <th>Expires On</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {specials.map(
                                (special) => (
                                    <tr key={special._id}>
                                        <td
                                            style={{
                                                textAlign:
                                                    "center",
                                            }}
                                        >
                                            <img
                                                src={
                                                    special
                                                        .image
                                                }
                                                alt={
                                                    special.name
                                                }
                                                style={{
                                                    width: "80px",
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {special._id}
                                        </td>

                                        <td>
                                            {special.title}
                                        </td>
                                        <td>
                                            {special.description}
                                        </td>
                                        <td>
                                            {
                                                special.price
                                            }
                                        </td>
                                        <td>
                                            {special.visible
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                        <td>
                                            {special.visibleOn ? formatDate(special.visibleOn) : "N/A"}
                                        </td>
                                        <td>
                                            {formatDate(special.expiresOn)}
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    margin:
                                                        "5px 0",
                                                }}
                                            >
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        history.push(
                                                            `/specials/edit?id=${special._id}`
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                            <Button
                                                variant={
                                                    "alert"
                                                }
                                                type="button"
                                                onClick={() => deleteHandler(special._id)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    : <NoData>No specials</NoData>}
            </div>
        </Box>
    )
};

export default SpecialsTable;