import React, { useEffect } from "react";

import ForgotPasswordCard from "../../components/password/forgotPasswordCard";

const ForgotPasswordScreen = () => {
  useEffect(() => {
    document.title = "Forgot Password | Shop at ShotDocMD";
  }, []);
  return (
    <div className={"content-container"}>
      <div className="row center" style={{ marginTop: "60px" }}>
        <ForgotPasswordCard />
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
