import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../actions/userActions";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import Button from "../button/button";
import Input from "../input/input";
import LoadingCog from "../loadingCog";
import modalStyles from "../modal/modal.module.css";

const ForgotPasswordCard = () => {
  const dispatch = useDispatch();
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { error, success, loading } = userForgotPassword;
  const [email, setEmail] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (loading) return;
    dispatch(forgotPassword({ email }));
  };

  return (
    <div className={contentLayoutStyles.card}>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Forgot Password</div>
        {success ? (
          <div className={modalStyles.successMessage}>
            A password reset link has been sent to {email}
          </div>
        ) : (
          <form onSubmit={submitHandler}>
            <Input
              withLabel={true}
              name={"Email Address"}
              type={"email"}
              required={true}
              onChange={setEmail}
              value={email}
              placeholder={"Enter Email"}
            />
            <div className={modalStyles.errorMessageContainer}>
              {error && error}
            </div>
            <div>
              <Button
                type={"submit"}
                fullWidth={true}
                height={"45px"}
                disabled={loading}
              >
                {loading ? <LoadingCog /> : <>Send Reset Link</>}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordCard;
