import React, { useState } from "react";
import styled from "styled-components";
import IconService from "../../services/iconService";
import Checkbox from "../checkbox/checkbox";
import { MobileSectionTitle } from "./styles";



const ToggleContainer = styled.div`
  color: var(--primary);
  font-size: 20px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  padding: 3px 0;
`;

const ItemsContainer = styled.div` 
  margin-top: ${({ show }) => (show ? "5px" : "0")};
  height: ${({ show }) => (show ? "auto" : "0")};
  transition: opacity 0.1s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

const MobileSortSection = ({ items, handleCheck, selected }) => {
  const [showDropdown, setShowDropdown] = useState(true);
  return (
    <div>
      <div
        className={"row"}
        style={{ cursor: "pointer", marginTop: '10px' }}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <MobileSectionTitle>
          Sort By
        </MobileSectionTitle>
        <ToggleContainer>
          {showDropdown
            ? IconService.getIcon("minus")
            : IconService.getIcon("plus")}
        </ToggleContainer>
      </div>
      <ItemsContainer show={showDropdown}>
        {items?.map((item, index) => {
          return (
            <CheckboxContainer key={index}>
              <Checkbox
                checked={selected === item.value}
                handleCheck={() => handleCheck(item.value)}
                label={item.text}
                name={item.text}
              />
            </CheckboxContainer>
          );
        })}
      </ItemsContainer>
    </div>
  );
};

export default MobileSortSection;
