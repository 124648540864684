import React, {
  useEffect,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  createProduct,
  deleteProduct,
  listAllProducts,
} from "../../actions/productActions";
import Button from "../../components/button/button";
import LoadingBox from "../../components/loadingBox";
import MessageBox from "../../components/messageBox";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
} from "../../constants/productConstants";
import { Title } from "../../styles/utils";

const ProductListScreen = (props) => {
  const productList = useSelector(
    (state) => state.productList
  );
  const { loading, error, products } =
    productList;

  const productCreate = useSelector(
    (state) => state.productCreate
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productDelete = useSelector(
    (state) => state.productDelete
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const dispatch = useDispatch();

  useEffect(() => {
    if (successCreate) {
      dispatch({
        type: PRODUCT_CREATE_RESET,
      });
      props.history.push(
        `/product/${createdProduct._id}/edit`
      );
    }
    if (successDelete) {
      dispatch({
        type: PRODUCT_DELETE_RESET,
      });
    }
    dispatch(listAllProducts());
  }, [
    createdProduct,
    dispatch,
    props.history,
    successCreate,
    successDelete,
  ]);

  const deleteHandler = (product) => {
    if (
      window.confirm(
        "Are you sure you want to delete?"
      )
    ) {
      dispatch(
        deleteProduct(product._id)
      );
    }
  };
  const createHandler = () => {
    dispatch(createProduct());
  };
  return (
    <div className="content-container">
      <div className="row">
        <Title>Products</Title>
        <Button
          type="button"
          className="primary"
          onClick={createHandler}
        >
          Create Product
        </Button>
      </div>
      {loadingDelete && <LoadingBox />}
      {errorDelete && (
        <MessageBox variant="danger">
          {errorDelete}
        </MessageBox>
      )}
      {loadingCreate && <LoadingBox />}
      {errorCreate && (
        <MessageBox variant="danger">
          {errorCreate}
        </MessageBox>
      )}
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">
          {error}
        </MessageBox>
      ) : (
        <div
          style={{ overflow: "auto" }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>IMAGE</th>
                <th>ID</th>
                <th>NAME</th>
                <th>BRAND</th>
                <th># IN STOCK</th>
                <th>VISIBLE</th>
                <th>FEATURED</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {products.map(
                (product) => (
                  <tr key={product._id}>
                    <td
                      style={{
                        textAlign:
                          "center",
                      }}
                    >
                      <img
                        src={
                          product
                            .images[0]
                        }
                        alt={
                          product.name
                        }
                        style={{
                          width: "80px",
                        }}
                      />
                    </td>
                    <td>
                      {product._id}
                    </td>

                    <td>
                      {product.name}
                    </td>
                    <td>
                      {product.brand}
                    </td>
                    <td>
                      {
                        product.countInStock
                      }
                    </td>
                    <td>
                      {product.visible
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>
                      {product.isFeatured
                        ? "Yes"
                        : "No"}
                    </td>
                    <td>
                      <div
                        style={{
                          margin:
                            "5px 0",
                        }}
                      >
                        <Button
                          type="button"
                          onClick={() =>
                            props.history.push(
                              `/product/${product._id}/edit`
                            )
                          }
                        >
                          Edit
                        </Button>
                      </div>
                      <Button
                        variant={
                          "alert"
                        }
                        type="button"
                        onClick={() =>
                          deleteHandler(
                            product
                          )
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductListScreen;
