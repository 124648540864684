import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getContactRequestById,
  markMessageAsRead,
} from "../../../actions/contactActions";
import Button from "../../button/button";
import dateFormat from "../../dateFormat";
import LoadingCog from "../../loadingCog";
import MessageBox from "../../messageBox";
import ConfirmModal from "../../utils/confirmModal";
import Title from "../../utils/title";
import styles from "./styles.module.css";
import contentLayoutStyles from "../../../styles/contentLayout.module.css";
import ReplyModal from "../replyModal/replyModal";
import SubTitle from "../../utils/subTitle";
const ContactView = ({
  loading,
  error,
  contact,
  markMessageAsRead,
  getContactRequestById,
  markError,
  markSuccess,
  contactId,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!contact || contact?._id !== contactId) {
      getContactRequestById(contactId);
    }
  }, [contact, contactId, getContactRequestById]);

  useEffect(() => {
    if (markSuccess) {
      getContactRequestById(contactId);
      setTimeout(() => {
        dispatch({ type: "MARK_AS_READ_RESET" });
      }, 2000);
    }
  }, [markSuccess, dispatch, getContactRequestById, contactId]);
  const markAsRead = () => {
    setShowConfirmModal(false);

    markMessageAsRead(contact._id);
  };

  const openConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const openReplyModal = () => {
    setShowReplyModal(true);
  };
  const closeReplyModal = () => {
    setShowReplyModal(false);
  };

  return (
    <>
      <div className="content-container">
        {loading ? (
          <div className={styles.center}>
            <LoadingCog />
          </div>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : contact ? (
          <div>
            <Title text={`Message #${contactId}`} />
            <div className={styles.center}>
              <div className={styles.row}>
                {!contact.isRead ? (
                  <Button
                    type="button"
                    onClick={() => openConfirmModal(contact)}
                  >
                    Mark as Read
                  </Button>
                ) : (
                  <div className={styles.readText}>Read</div>
                )}
                <Button type="button" onClick={openReplyModal}>
                  Reply
                </Button>{" "}
              </div>
            </div>
            <div className={styles.center}>
              <div className={styles.row}>
                {markError && (
                  <div style={{ margin: "10px 0" }}>
                    <MessageBox variant="danger">{markError}</MessageBox>
                  </div>
                )}
                {markSuccess && (
                  <div style={{ margin: "10px 0" }}>
                    <MessageBox variant="success">
                      Successfully marked as read
                    </MessageBox>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.center}>
              <div className={contentLayoutStyles.card}>
                <div className={styles.row}>
                  <div>
                    <div className={styles.infoItem}>{contact.name}</div>
                    <div className={styles.infoItem}>{contact.email}</div>
                  </div>
                  <div className={styles.infoItem}>
                    {dateFormat(contact.createdAt)}
                  </div>
                </div>

                <div className={styles.infoItem} style={{ margin: "20px 0" }}>
                  <strong>Subject:</strong> {contact.subject}
                </div>
                <div className={styles.infoItem}>
                  <strong>Message:</strong> {contact.message}
                </div>
              </div>
            </div>
            {!!contact.replies.length && (
              <div className={styles.center}>
                <div style={{ width: "100%" }}>
                  <SubTitle text={`Replies`} />
                  {contact.replies.map((reply, index) => {
                    return (
                      <div className={styles.center}>
                        <div className={contentLayoutStyles.card} key={index}>
                          <div className={styles.row}>
                            <div className={styles.infoItem}>
                              {reply.user && (
                                <>
                                  Reply by: {reply.user.firstName}{" "}
                                  {reply.user.lastName}
                                </>
                              )}
                            </div>
                            <div className={styles.infoItem}>
                              {dateFormat(reply.createdAt)}
                            </div>
                          </div>

                          <div
                            className={styles.infoItem}
                            style={{ margin: "20px 0" }}
                          >
                            <strong>Subject:</strong> {reply.subject}
                          </div>
                          <div className={styles.infoItem}>
                            <strong>Message:</strong> {reply.message}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {showConfirmModal && (
        <ConfirmModal
          {...{
            show: showConfirmModal,
            confirmAction: markAsRead,
            denyAction: closeConfirmModal,
            message: "Are you sure you want to mark this message as read?",
          }}
        />
      )}
      {showReplyModal && (
        <ReplyModal
          {...{
            show: showReplyModal,
            closeModal: closeReplyModal,
            contact,
          }}
        />
      )}
    </>
  );
};

export default connect(
  ({ contactById, contactMarkAsRead }) => ({
    loading: contactById.loading,
    contact: contactById.contact,
    error: contactById.error,
    markLoading: contactMarkAsRead.loading,
    markSuccess: contactMarkAsRead.success,
    markError: contactMarkAsRead.error,
  }),
  {
    getContactRequestById,
    markMessageAsRead,
  }
)(ContactView);
