import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllConcerns } from "../../actions/productActions";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import CategoryCard from "../../components/categoryCard/categoryCard";
import screenStyles from "../../styles/screen.module.css";

const Concerns = ({ concerns, getAllConcerns }) => {
  useEffect(() => {
    if (!concerns) {
      getAllConcerns();
    }
  }, [concerns, getAllConcerns]);

  useEffect(() => {
    document.title = "Shop By Concern | Shop at ShotDocMD";
  }, []);



  return (
    <div className={"content-container"}>
      <div>
        <Breadcrumb
          options={[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "Concerns",
              path: "/concerns",
            },
          ]}
        />
        <div className={screenStyles.brandName}>Shop by Concern</div>
        <div className={screenStyles.results}>
          {concerns ? (
            <>
              {concerns.length} result{concerns?.length !== 1 && "s"}
            </>
          ) : (
            <>&nbsp;</>
          )}{" "}
        </div>
      </div>
      <div className="brands-grid">
        {concerns?.map((concern, index) => {
          return (
            <CategoryCard
              {...{ name: concern, baseRoute: "concerns" }}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default connect(
  ({ getConcerns }) => ({
    concerns: getConcerns.concerns,
  }),
  { getAllConcerns }
)(Concerns);
