import React from 'react';

const LoadingCog = (props) => {
    return (
        <div className={props.isLarge ? 'loaderLarge' : 'loader'}>
            
        </div>
    );
};

export default LoadingCog;