
const getMonth = (month) => {
    switch (month) {
        case "01":
            return "January"
        case "02":
            return "February"
        case "03":
            return "March"
        case "04":
            return "April"
        case "05":
            return "May"
        case "06":
            return "June"
        case "07":
            return "July"
        case "08":
            return "August"
        case "09":
            return "September"
        case "10":
            return "October"
        case "11":
            return "November"
        case "12":
            return "December"
        default:
            return "Invalid month"
    }
}

export const formatDate = (date) => {
    const string = date.split("T")[0];
    const year = string.substring(0, 4);
    const month = string.substring(5, 7);
    let day = string.substring(8, 10)
    if (day.split("")[0] === "0") {
        day = day.split("")[1];
    }
    return `${getMonth(month)} ${day}, ${year}`;
}