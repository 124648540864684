import React from "react";
import { Link } from "react-router-dom";
import styles from "./orderItem.module.css";

const OrderItem = ({ item }) => {
  return (
    <div className={styles.grid}>
      <img src={item.images[0]} alt={item.name} className={styles.image} />
      <div>
        <Link to={`/product/${item.product}`}>
          {" "}
          <div className={styles.name}>{item.name} </div>
        </Link>
        <div className={styles.qty}>Quantity {item.qty}</div>
      </div>
      <div className={styles.price}>
          ${item.price * item.qty}
      </div>
    </div>
  );
};

export default OrderItem;
