const {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,

  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_RESET,

  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
  PRODUCT_REVIEW_CREATE_RESET,
} = require("../constants/productConstants");

export const productListReducer = (
  state = {
    loading: true,
    products: null,
    params: {
      searchString: "",
      brands: [],
      categories: [],
      concerns: [],
      areasOfFocus: [],
      prices: [],
      order: "",
    },
  },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.data,
        params: action.payload.params,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const featuredProductListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case "PRODUCT_FEATURED_LIST_REQUEST":
      return { loading: true };
    case "PRODUCT_FEATURED_LIST_SUCCESS":
      return { loading: false, products: action.payload };
    case "PRODUCT_FEATURED_LIST_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case PRODUCT_REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const relatedProductsReducer = (state = {}, action) => {
  switch (action.type) {
    case "RELATED_PRODUCTS_REQUEST":
      return { loading: true };
    case "RELATED_PRODUCTS_SUCCESS":
      return { loading: false, relatedProducts: action.payload };
    case "RELATED_PRODUCTS_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getBrandsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_BRANDS_REQUEST":
      return { loading: true };
    case "GET_BRANDS_SUCCESS":
      return { loading: false, brands: action.payload };
    case "GET_BRANDS_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CATEGORIES_REQUEST":
      return { loading: true };
    case "GET_CATEGORIES_SUCCESS":
      return { loading: false, categories: action.payload, success: true };
    case "GET_CATEGORIES_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getConcernsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CONCERNS_REQUEST":
      return { loading: true };
    case "GET_CONCERNS_SUCCESS":
      return { loading: false, concerns: action.payload, success: true };
    case "GET_CONCERNS_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getAreasOfFocusReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_AREAS_OF_FOCUS_REQUEST":
      return { loading: true };
    case "GET_AREAS_OF_FOCUS_SUCCESS":
      return { loading: false, areasOfFocus: action.payload, success: true };
    case "GET_AREAS_OF_FOCUS_FAIL":
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};