import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { detailsOrder } from "../../actions/orderActions";
import LoadingCog from "../../components/loadingCog";
import MessageBox from "../../components/messageBox";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import OrderInfo from "../../components/order/orderInfo";
import OrderShipment from "../../components/order/orderShipment";

const OrderDetailsScreen = (props) => {
  const orderId = props.match.params.id;
  const { search } = useLocation();
  const accessToken = search.split("=")[1];

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const dispatch = useDispatch();
  useEffect(() => {
    if (order) {
      document.title = `Order #${order._id} | Shop at ShotDocMD`;
    }
  }, [order]);
  useEffect(() => {
    if (!order || order._id !== orderId) {
      dispatch(detailsOrder(orderId));
    }
  }, [dispatch, order, orderId]);

  useEffect(() => {
    if (order?._id === orderId) {
      if (
        order?.accessToken !== accessToken &&
        order?.user._id !== userInfo?._id
      ) {
        props.history.push("/unauthorized");
      }
    }
  }, [order, accessToken, userInfo, orderId, props.history]);

  return (
    <div className="content-container">
      {loading ? (
        <div className="row center" style={{ height: '100%' }}>
          <LoadingCog isLarge={true} />
        </div>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className={contentLayoutStyles.gridReverse}>
          <div className={contentLayoutStyles.mainContainer}>
            <OrderInfo {...{ order, userInfo, isDetails: true }} />
          </div>
          <div className={contentLayoutStyles.secondaryContainer}>
            <OrderShipment {...{ order }} />
          </div>
          {/*  */}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsScreen;
