import React, { useState } from "react";
import IconService from "../../../services/iconService";
import screenStyles from "../../../styles/screen.module.css";
import Button from "../../button/button";
import dateFormat from "../../dateFormat";
import Rating from "../../rating";

const ReviewsCard = ({product, handleReviewButtonClick}) => {
  const [showReviews, setShowReviews] = useState(false);
  const toggleReviewsDropdown = () => {
    setShowReviews(!showReviews);
  };
  
  return (
    <div className={screenStyles.backgroundContainer}>
      <div
        className="row"
        style={{ cursor: "pointer" }}
        onClick={toggleReviewsDropdown}
      >
        <div className={screenStyles.subheading}>
          Reviews ({product.reviews.length})
        </div>
        <div className={"row"}>
          <Rating rating={product.rating} hideReviews={true} />
          &nbsp;&nbsp;
          {showReviews ? (
            <>{IconService.getIcon("up", screenStyles.reviewsIcon)}</>
          ) : (
            <>{IconService.getIcon("down", screenStyles.reviewsIcon)}</>
          )}
        </div>
      </div>
      {showReviews && (
        <div className={screenStyles.reviewsContainer}>
          {product.reviews.length >= 0 ? (
            <div>
              <Button onClick={handleReviewButtonClick}>Write a Review</Button>
              {product.reviews.map((review, index) => {
                if (review.visible) {
                  return (
                    <div
                      className={screenStyles.reviewItem}
                      key={`product-${product._id}-review-${index}`}
                    >
                      <div className={screenStyles.row}>
                        {" "}
                        <Rating rating={review.rating} hideReviews={true} />
                        <div className={screenStyles.reviewInfo}>
                          {review.user.firstName}{" "}
                          {review.user.lastName.slice(0, 1)}. -{" "}
                          {dateFormat(review.createdAt, true)}
                        </div>
                      </div>
                      <div className={screenStyles.reviewComment}>
                        {review.comment}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : (
            <div className={screenStyles.noData}>No Reviews</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewsCard;
