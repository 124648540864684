import React, { useEffect, useState } from "react";
import mobileStyles from "./mobileNavbar.module.css";
import logoSmall from "../../assets/images/logo-small.png";
import IconService from "../../services/iconService";

const MobileNavDrawer = ({
  showDrawer,
  toggleDrawer,
  handleLink,
}) => {
  const [showShop, setShowShop] = useState(false);

  const handleClick = (to) => {
    handleLink(to);
    setShowShop(false);
  };
  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showDrawer]);

  return (
    <div
      className={`${mobileStyles.drawer} ${showDrawer ? mobileStyles.drawerOpen : ''}`}
    >
      <div
        className={`${mobileStyles.drawerRow} ${mobileStyles.drawerContainerTopRow}`}
      >
        {showShop ? (
          <div onClick={() => setShowShop(false)}>
            {IconService.getIcon("back", mobileStyles.closeIcon)}
          </div>
        ) : (
          <img
            src={logoSmall}
            className={mobileStyles.drawerLogo}
            alt={"logo"}
          />
        )}
        <div onClick={toggleDrawer}>
          {IconService.getIcon("close", mobileStyles.closeIcon)}
        </div>
      </div>
      {showShop ? (
        <div className={mobileStyles.linkContainer}>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("search")}
          >
            <span className={mobileStyles.drawerLinkText}>Shop All</span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/brands")}
          >
            <span className={mobileStyles.drawerLinkText}>Shop by Brand</span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/categories")}
          >
            <span className={mobileStyles.drawerLinkText}>
              Shop by Category
            </span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/concerns")}
          >
            <span className={mobileStyles.drawerLinkText}>Shop by Concern</span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/areas-of-focus")}
          >
            <span className={mobileStyles.drawerLinkText}>
              Shop by Area of Focus
            </span>
          </div>
        </div>
      ) : (
        <div className={mobileStyles.linkContainer}>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/")}
          >
            <span className={mobileStyles.drawerLinkText}>Home</span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => setShowShop(true)}
          >
            <span className={mobileStyles.drawerLinkText}>Shop</span>
          </div>
          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/cart")}
          >
            <span className={mobileStyles.drawerLinkText}>Cart</span>
          </div>

          <div
            className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            onClick={() => handleClick("/find-order")}
          >
            <span className={mobileStyles.drawerLinkText}>Find Order</span>
          </div>
          {/* TODO: Decide if link is wanted here */}
          {/* <a
            href="https://www.shotdocmd.com"
            rel="noreferrer"
            target="_blank"
            onClick={() => handleClick("")}
          >
            <div
              className={`${mobileStyles.drawerRow} ${mobileStyles.drawerLink}`}
            >
              <span className={mobileStyles.drawerLinkText}>
                Book Appointment
              </span>
            </div>
          </a> */}
        </div>
      )}
    </div>
  );
};

export default MobileNavDrawer;
