import React, { useEffect } from "react";
import Carousel from "../components/carousel";
import ProductHorizontalScroll from "../components/product/productHorizontalScroll";
import ShopByContainer from "../components/home/shopBySection";



export default function HomeScreen() {
  useEffect(() => {
    document.title = "Home | Shop at ShotDocMD";
  }, []);

  return (
    <div className={'column'}>
      <div className={'row-container'}>
        <Carousel />
      </div>
      <div className={"content-container"}>
        <ProductHorizontalScroll />
      </div>
      <div className="content-container">
        <ShopByContainer />
      </div>
    </div>
  );
}
