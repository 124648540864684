import React, { useState } from "react";
import IconService from "../../../services/iconService";
import ratingStyles from "./starRating.module.css";

const StarRating = (props) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

    const handleChange = (index) => {
        setRating(index);
        props.handleChange(index)
    }

  return (
    <div className={ratingStyles.container}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <div
            key={index}
            onClick={() => handleChange(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            {index <= (hover || rating) ? (
              <> {IconService.getIcon("star-solid", ratingStyles.star)}</>
            ) : (
              <> {IconService.getIcon("star-outline", ratingStyles.star)}</>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;