import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../components/button/button";
import logo from "../images/logo.png";

const UnauthorizedScreen = () => {
  useEffect(() => {
    document.title = "Unauthorized | Shop at ShotDocMD";
  }, []);
  return (
    <div className={"content-container row center"}>
      <div className={"column-center"}>
        <img src={logo} className={"logo-unauthorized"} alt={"logo"} />
        <div className={"text-unauthorized"}>
          You are not authorized to visit this page
        </div>
        <Link to="/">
          <Button height={"40px"} width={"120px"}>
            Go To Home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default UnauthorizedScreen;
