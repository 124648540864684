import React from "react";
import { connect } from "react-redux";
import Select from "../select";
import { changeFilter } from "../../actions/searchActions";
import styled from "styled-components";

const StyledText = styled.div`
    margin-right: 5px;
    color: var(--primary);
    font-weight: 600;
    font-size: 18px;
`


const SortingButton = ({ sortType, changeFilter }) => {
  return (
    <div className={"row"}>
      <StyledText>Sort By</StyledText>
      <Select
        {...{
          value: sortType,
          handleChange: changeFilter,
          options: [
            {
              value: "",
              text: "None",
              default: true,
            },
            {
              value: "lowest",
              text: "Price: Low-High",
            },
            {
              value: "highest",
              text: "Price: High-Low",
            },
            {
              value: "toprated",
              text: "Rating",
            },
          ],
          width: '120px'
        }}
      />
    </div>
  );
};

export default connect(({ searchFilter }) => ({
  sortType: searchFilter.sortType,
}), {changeFilter})(SortingButton);
