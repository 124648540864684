import React, { useEffect, useState } from "react";
import IconService from "../../services/iconService";
import Modal from "../modal/modal";
import modalStyles from "../modal/modal.module.css";
import iconStyles from "../../styles/icon.module.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../button/button";
import LoadingCog from "../loadingCog";
import StarRating from "./starRating/starRating";
import TextArea from "../textarea/textarea";
import { createReview } from "../../actions/productActions";
import { PRODUCT_REVIEW_CREATE_RESET } from "../../constants/productConstants";

const ReviewsModal = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    error: errorReviewCreate,
    success: successReviewCreate,
  } = productReviewCreate;

  const reviewHandler = (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    if (message && rating) {
      dispatch(
        createReview(product._id, { rating, comment: message, name: props.userInfo.name })
      );
    } else {
    }
  };


  useEffect(() => {
    if (errorReviewCreate) {
      setIsLoading(false);
    }
  }, [errorReviewCreate])


  function hideModal() {
    setRating(0)
    setMessage('')
    setIsLoading(false);
    props.closeModal();
  }

  useEffect(() => {
    dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    if (successReviewCreate) {
      hideModal();
      dispatch({ type: PRODUCT_REVIEW_CREATE_RESET });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, successReviewCreate]);



  const handleRatingChange = (index) => {
    setRating(index);
  };


  const { product } = props;
  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.titleSmall}>
          Review {product ? product.name : "Product"}
        </div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        <form onSubmit={reviewHandler}>
          <div className={modalStyles.rowCenter} style={{ marginTop: "10px" }}>
            <StarRating handleChange={handleRatingChange} />
          </div>
          <TextArea
            withLabel={true}
            labelText={"Comment"}
            name={"comment"}
            onChange={setMessage}
            required={true}
          />
          <div className={modalStyles.errorMessageContainer}>
            {errorReviewCreate && errorReviewCreate}
          </div>
          <div>
            <Button
              type={"submit"}
              fullWidth={true}
              height={"45px"}
              disabled={isLoading || !message || rating === 0}
            >
              {isLoading ? <LoadingCog /> : <>Submit</>}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReviewsModal;
