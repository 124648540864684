import React from "react";
import { Link } from "react-router-dom";
import styles from "./categoryCard.module.css";

const CategoryCard = ({ name, baseRoute }) => {
  return (
    <Link to={`/${baseRoute}/${name}`}>
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
      </div>
    </Link>
  );
};

export default CategoryCard;
