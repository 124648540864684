/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckoutInput from "../checkoutInput/checkoutInput";
import { USER_DELETE_ADDRESS_RESET } from "../../../constants/userConstants";
import { detailsUser, userDeleteAddress } from "../../../actions/userActions";
import RadioSelect from "../../radioSelect/radioSelect";
import Checkbox from "../../checkbox/checkbox";
import IconService from "../../../services/iconService";
import AddressSelector from "../../addressSelector/addressSelector";
import RemoveModal from "../../removeModal/removeModal";

// styles
import screenStyles from "../../../styles/screen.module.css";
import checkoutStyles from "../../../styles/checkout.module.css";
import inputStyles from "../../checkout/checkoutInput/input.module.css";
import { states } from "../../../interfaces/enums";
import Button from "../../button/button";

const CheckoutShipping = (props) => {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const [emailForOrder, setEmailForOrder] = useState("");
  const [addedAddress, setAddedAddress] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [latlng, setLatLng] = useState({
    lat: '',
    lng: ''
  })
  const [showSecondAddress, setShowSecondAddress] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [showNewAddressDropdown, setShowNewAddressDropdown] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const [formError, setFormError] = useState("");
  const [errorArr, setErrorArr] = useState([]);
const [checked, setChecked] = useState(false)

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [addressToRemove, setAddressToRemove] = useState("");

  const removeAddress = () => {
    dispatch(userDeleteAddress(user._id, addressToRemove));
    closeRemoveModal();
  };

  const openRemoveModal = (addressId) => {
    setAddressToRemove(addressId);
    setShowRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setAddressToRemove("");
    setShowRemoveModal(false);
  };

  useEffect(() => {
    if (user) {
      setEmailForOrder(user.email);
    } else {
      setEmailForOrder("");
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setEmailForOrder(user.email);
    } else {
      setEmailForOrder("");
    }
    // sets shipping address if user already has one saved
    if (user?.shippingAddress?.length > 0) {
      let firstAddress = user.shippingAddress[0];
      setFirstName(firstAddress.firstName);
      setLastName(firstAddress.lastName);
      setAddress(firstAddress.address);
      setAddress2(firstAddress.address2);
      setCity(firstAddress.city);
      setState(firstAddress.state);
      setZipcode(firstAddress.zipcode);
      setLatLng(firstAddress.latlng)
      setIsNewAddress(false);
    }
  }, [user]);

  useEffect(() => {
    // used to update the error outline when a new address is added
    if (addedAddress) {
      updateErrorArr(["address", "city", "state", "zipcode"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAddress]);

  const updateErrorArr = (type) => {
    if (errorArr.length === 0) return;
    let arrCopy = [...errorArr];
    type.forEach((item) => {
      let index = arrCopy.indexOf(item);
      if (index > -1) {
        arrCopy.splice(index, 1);
      }
    });
    setErrorArr(arrCopy);
    if (arrCopy.length === 0) {
      setFormError("");
    }
    setAddedAddress(false);
  };

  const handleAddressSelection = (place) => {
    let addressComponents = place.address_components;
    let strAddress = "";
    let toUpdate = [];
    addressComponents.forEach((item, index) => {
      if (item.types.includes("street_number")) {
        strAddress += item.short_name;
      } else if (item.types.includes("route")) {
        strAddress += " " + item.short_name;
        toUpdate.push("address");
      } else if (item.types.includes("locality")) {
        setCity(item.short_name);
        toUpdate.push("city");
      } else if (item.types.includes("administrative_area_level_1")) {
        setState(item.short_name);
        toUpdate.push("state");
      } else if (item.types.includes("postal_code")) {
        setZipcode(item.short_name);
        toUpdate.push("zipcode");
      }
    });
    let location = place.geometry.location;
    let lat = location.lat();
    let lng = location.lng();
    setLatLng({
      lat: lat,
      lng: lng
    })
    setAddress(strAddress);
    setAddedAddress(true);
  };

  const handleAddressChange = (address, index) => {
    setSelectedAddressIndex(index);
    setShowNewAddressDropdown(false);
    setFirstName(address.firstName);
    setLastName(address.lastName);
    setAddress(address.address);
    setAddress2(address.address2);
    setCity(address.city);
    setState(address.state);
    setZipcode(address.zipcode);
    setLatLng(address.latlng)
    setIsNewAddress(false);
  };

  const showNewAddress = () => {
    if (selectedAddressIndex === -1) return;
    setSelectedAddressIndex(-1);
    setShowNewAddressDropdown(true);
    setFirstName("");
    setLastName("");
    setAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setZipcode("");
    setLatLng({});
    setIsNewAddress(true);
  };

  const userAddressDelete = useSelector((state) => state.userAddressDelete);
  const { success: addressDeleteSuccess } = userAddressDelete;

  useEffect(() => {
    if (addressDeleteSuccess) {
      dispatch(detailsUser(userInfo._id));
      dispatch({ type: USER_DELETE_ADDRESS_RESET });
    }
  }, [addressDeleteSuccess, dispatch, userInfo]);

  const handleShipping = () => {
    let errors = [];
    if (!firstName) {
      errors.push("firstName");
    }
    if (!lastName) {
      errors.push("lastName");
    }
    if (!address) {
      errors.push("address");
    }
    if (!city) {
      errors.push("city");
    }
    if (!state) {
      errors.push("state");
    }
    if (!zipcode) {
      errors.push("zipcode");
    }
    if (!emailForOrder) {
      errors.push("emailForOrder");
    }
    if (errors.length > 0) {
      setErrorArr(errors);
      setFormError("There are one or more errors with your information.");
      return;
    } else {
      setErrorArr([]);
      setFormError("");
    }
    let data = {
      email: emailForOrder,
      firstName: firstName,
      lastName: lastName,
      address: address,
      address2: address2,
      city: city,
      state: state,
      zipcode: zipcode,
      isNewAddress: isNewAddress,
      checked: checked,
      latlng: latlng,
    };

    props.handleShipping(data);
  };


  const handleAddressLineOneChange = (val) => {
    setAddress(val)
  }

  return (
    <div>
      {/* contact info container */}
      <div className={screenStyles.backgroundContainer}>
        <div className={checkoutStyles.subheading}>Contact Information</div>
        <div className={checkoutStyles.inputPadding}>
          <CheckoutInput
            withLabel={true}
            name={"Email Address (for order notification)"}
            type={"email"}
            required={true}
            onChange={(val) => {
              setEmailForOrder(val);
              updateErrorArr(["emailForOrder"]);
            }}
            value={emailForOrder}
            placeholder={"Enter Email"}
            showError={errorArr.includes("emailForOrder")}
          />
        </div>
        <div
          className={checkoutStyles.inputPadding}
          style={{ marginTop: "15px" }}
        >
          <Checkbox
            label={"Sign me up for ShotDocMD emails"}
            checked={checked}
            handleCheck={() => setChecked(!checked)}
          />
        </div>
      </div>
      {/* shipping address container */}
      <div className={screenStyles.backgroundContainer}>
        <div className={checkoutStyles.subheading}>Shipping Address</div>{" "}
        {userInfo && user?.shippingAddress?.length > 0 ? (
          <>
            {user.shippingAddress.map((address, index) => {
              return (
                <div className={checkoutStyles.addressContainer}>
                  <div onClick={() => handleAddressChange(address, index)}>
                    <RadioSelect checked={selectedAddressIndex === index}>
                      {address.firstName} {address.lastName}
                      <br />
                      {address.address}
                      {address.address2 && ","} {address.address2}
                      <br />
                      {address.city}, {address.state} {address.zipcode}
                    </RadioSelect>
                  </div>
                  <div
                    className={checkoutStyles.removeIcon}
                    onClick={() => openRemoveModal(address._id)}
                  >
                    {IconService.getIcon("close")}
                  </div>
                </div>
              );
            })}
            <div onClick={showNewAddress} style={{ marginTop: "10px" }}>
              <RadioSelect checked={selectedAddressIndex === -1}>
                Add a new address
              </RadioSelect>
            </div>
            {showNewAddressDropdown && (
              <>
                <div className={checkoutStyles.nameGrid}>
                  <div className={checkoutStyles.inputPadding}>
                    <CheckoutInput
                      withLabel={true}
                      name={"First Name"}
                      type={"text"}
                      required={true}
                      onChange={(val) => {
                        setFirstName(val);
                        updateErrorArr(["firstName"]);
                      }}
                      value={firstName}
                      placeholder={"Enter First Name"}
                      showError={errorArr.includes("firstName")}
                    />
                  </div>
                  <div className={checkoutStyles.inputPadding}>
                    <CheckoutInput
                      withLabel={true}
                      name={"Last Name"}
                      type={"text"}
                      required={true}
                      onChange={(val) => {
                        setLastName(val);
                        updateErrorArr(["lastName"]);
                      }}
                      value={lastName}
                      placeholder={"Enter First Name"}
                      showError={errorArr.includes("lastName")}
                    />
                  </div>
                </div>
                <div className={checkoutStyles.inputPadding}>
                  <AddressSelector
                    handleAddressSelection={handleAddressSelection}
                    showError={errorArr.includes("address")}
                    handleChange={handleAddressLineOneChange}
                  />
                  {showSecondAddress ? (
                    <div className={checkoutStyles.inputPaddingNoLabel}>
                      <CheckoutInput
                        placeholder={"Apartment, suite, unit etc. (optional)"}
                        onChange={(val) => setAddress2(val)}
                        value={address2}
                      />
                      <div
                        className={checkoutStyles.showSecondAddress}
                        onClick={() => setShowSecondAddress(false)}
                      >
                        {IconService.getIcon("minus")} Hide
                      </div>
                    </div>
                  ) : (
                    <div
                      className={checkoutStyles.showSecondAddress}
                      onClick={() => setShowSecondAddress(true)}
                    >
                      {IconService.getIcon("plus")} Add Apartment Number
                    </div>
                  )}
                  <div className={checkoutStyles.addressGrid}>
                    <div className={checkoutStyles.inputPadding}>
                      <CheckoutInput
                        withLabel={true}
                        name={"City"}
                        placeholder={"City"}
                        onChange={(val) => {
                          setCity(val);
                          updateErrorArr(["city"]);
                        }}
                        value={city}
                        showError={errorArr.includes("city")}
                      />
                    </div>
                    <div className={checkoutStyles.inputPadding}>
                      <label className={inputStyles.label}>State</label>
                      <select
                        className={`${inputStyles.inputSelect} ${
                          errorArr.includes("state")
                            ? inputStyles.inputError
                            : inputStyles.inputSelectNormal
                        }`}
                        value={state}
                        defaultValue={""}
                        onChange={(e) => {
                          setState(e.target.value);
                          updateErrorArr(["state"]);
                        }}
                      >
                        <option disabled value="">
                          Select...
                        </option>
                        {states.map((state, index) => (
                          <option key={index} value={state.abbreviation}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={checkoutStyles.inputPadding}>
                      <CheckoutInput
                        withLabel={true}
                        type={"number"}
                        name={"Zip code"}
                        placeholder={"Zip code"}
                        onChange={(val) => {
                          if (val.length > 5) return;
                          setZipcode(val);
                          updateErrorArr(["zipcode"]);
                        }}
                        value={zipcode}
                        showError={errorArr.includes("zipcode")}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={checkoutStyles.nameGrid}>
              <div className={checkoutStyles.inputPadding}>
                <CheckoutInput
                  withLabel={true}
                  name={"First Name"}
                  type={"text"}
                  required={true}
                  onChange={(val) => {
                    setFirstName(val);
                    updateErrorArr(["firstName"]);
                  }}
                  value={firstName}
                  placeholder={"Enter First Name"}
                  showError={errorArr.includes("firstName")}
                />
              </div>
              <div className={checkoutStyles.inputPadding}>
                <CheckoutInput
                  withLabel={true}
                  name={"Last Name"}
                  type={"text"}
                  required={true}
                  onChange={(val) => {
                    setLastName(val);
                    updateErrorArr(["lastName"]);
                  }}
                  value={lastName}
                  placeholder={"Enter First Name"}
                  showError={errorArr.includes("lastName")}
                />
              </div>
            </div>
            <div className={checkoutStyles.inputPadding}>
              <AddressSelector
                handleAddressSelection={handleAddressSelection}
                showError={errorArr.includes("address")}
                handleChange={handleAddressLineOneChange}
              />
              {showSecondAddress ? (
                <div className={checkoutStyles.inputPaddingNoLabel}>
                  <CheckoutInput
                    placeholder={"Apartment, suite, unit etc. (optional)"}
                    onChange={(val) => setAddress2(val)}
                    value={address2}
                  />
                  <div
                    className={checkoutStyles.showSecondAddress}
                    onClick={() => setShowSecondAddress(false)}
                  >
                    {IconService.getIcon("minus")} Hide
                  </div>
                </div>
              ) : (
                <div
                  className={checkoutStyles.showSecondAddress}
                  onClick={() => setShowSecondAddress(true)}
                >
                  {IconService.getIcon("plus")} Add Apartment Number
                </div>
              )}
              <div className={checkoutStyles.addressGrid}>
                <div className={checkoutStyles.inputPadding}>
                  <CheckoutInput
                    withLabel={true}
                    name={"City"}
                    placeholder={"City"}
                    onChange={(val) => {
                      setCity(val);
                      updateErrorArr(["city"]);
                    }}
                    value={city}
                    showError={errorArr.includes("city")}
                  />
                </div>
                <div className={checkoutStyles.inputPadding}>
                  <label className={inputStyles.label}>State</label>
                  <select
                    className={`${inputStyles.inputSelect} ${
                      errorArr.includes("state")
                        ? inputStyles.inputError
                        : inputStyles.inputSelectNormal
                    }`}
                    defaultValue={""}
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      updateErrorArr(["state"]);
                    }}
                  >
                    <option disabled value="">
                      Select...
                    </option>
                    {states.map((state, index) => (
                      <option key={index} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={checkoutStyles.inputPadding}>
                  <CheckoutInput
                    withLabel={true}
                    type={"number"}
                    name={"Zip code"}
                    placeholder={"Zip code"}
                    onChange={(val) => {
                      if (val.length > 5) return;
                      setZipcode(val);
                      updateErrorArr(["zipcode"]);
                    }}
                    value={zipcode}
                    showError={errorArr.includes("zipcode")}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={checkoutStyles.buttonContainer}>
        <div className={checkoutStyles.columnRight}>
          {" "}
          <Button type="button" onClick={handleShipping} padding={"10px 20px"}>
            {props.isEdit ? "Update" : "Go To Payment"}
          </Button>
          {!props.isEdit && (
            <div className={checkoutStyles.stepsText}>
              Proceed to step 2 of 3
            </div>
          )}
        </div>
      </div>
      {showRemoveModal && (
        <RemoveModal
          show={showRemoveModal}
          closeModal={closeRemoveModal}
          handleRemove={removeAddress}
          message={"Are you sure you want to remove this address?"}
          confirmButton={"Yes, remove this addres"}
          rejectButton={"No, keep this address"}
        />
      )}
    </div>
  );
};

export default CheckoutShipping;
