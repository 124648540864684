import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { listFeaturedProducts } from "../../../actions/productActions";
import IconService from "../../../services/iconService";
import MessageBox from "../../messageBox";
import SubTitle from "../../utils/subTitle";
import ProductCard from "../productCard/productCard";
import ProductCardSkeleton from "../productCard/productCardSkeleton";
import ProductModal from "../productCard/productModal/productModal";
import styles from "./index.module.css";

const ProductHorizontalScroll = ({
  loading,
  error,
  products,
  listFeaturedProducts,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const productRef = useRef(null);

  useEffect(() => {
    listFeaturedProducts();
  }, [listFeaturedProducts]);

  function openModal(product) {
    setShowModal(true);
    setSelectedProduct(product);
  }
  function hideModal() {
    setShowModal(false);
    setSelectedProduct(null);
  }
  const scroll = (scrollOffset) => {
    if (productRef.current) {
      productRef.current.scrollLeft += scrollOffset;
      setScrollX(scrollX + scrollOffset);
      if (
        Math.floor(
          productRef.current.scrollWidth - productRef.current.scrollLeft
        ) <= productRef.current.offsetWidth
      ) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    }
  };

  const scrollCheck = useCallback(() => {
    if (productRef.current) {
      setScrollX(productRef.current.scrollLeft);
      if (
        Math.floor(
          productRef.current.scrollWidth - productRef.current.scrollLeft
        ) <= productRef.current.offsetWidth
      ) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    }
  }, []);

  useEffect(() => {
    const containerRef = productRef.current;

    containerRef.addEventListener("resize", scrollCheck);

    return () => {
      containerRef.removeEventListener("resize", scrollCheck);
    };
  }, [scrollCheck]);

  useEffect(() => {
    if (products) {
      scrollCheck();
    }
  }, [scrollCheck, products]);

  return (
    <>
      <div className={styles.container}>
        {(loading || !!products?.length) && (
          <SubTitle>Featured Products</SubTitle>
        )}
        {error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            {scrollX !== 0 && (
              <div className={styles.leftArrowContainer}>
                <div
                  className={styles.arrowCircle}
                  onClick={() => scroll(-440)}
                >
                  {IconService.getIcon("left-arrow", styles.arrow)}
                </div>
              </div>
            )}
            <div
              className={styles.scrollContainer}
              ref={productRef}
              onScroll={scrollCheck}
              style={{ scrollBehavior: "smooth" }}
            >
              {loading && (
                <>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={styles.cardContainer}>
                    <ProductCardSkeleton />
                  </div>
                </>
              )}
              {products &&
                products.map((product, index) => {
                  return (
                    <div className={styles.cardContainer} key={index}>
                      <ProductCard
                        key={product._id}
                        product={product}
                        showModal={openModal}
                      />
                    </div>
                  );
                })}
              {!scrollEnd && (
                <div className={styles.rightArrowContainer}>
                  <div
                    className={styles.arrowCircle}
                    onClick={() => scroll(440)}
                  >
                    {IconService.getIcon("right-arrow", styles.arrow)}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showModal && (
        <ProductModal
          show={showModal}
          product={selectedProduct}
          closeModal={hideModal}
        />
      )}
    </>
  );
};

export default connect(
  ({ featuredProductsList }) => ({
    loading: featuredProductsList.loading,
    error: featuredProductsList.error,
    products: featuredProductsList.products,
  }),
  { listFeaturedProducts }
)(ProductHorizontalScroll);
