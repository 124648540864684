import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleSearch } from "../../actions/generalActions";
import IconService from "../../services/iconService";
import searchStyles from "./search.module.css";
import SearchDropdown from "./searchDropdown";

const Search = ({ handleSearch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [searchExpanded, setSearchExpanded] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSearchExpanded(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue) {
        handleSearch(searchValue);
      } else {
        dispatch({ type: "SEARCH_RESET" });
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, handleSearch, dispatch]);

  useEffect(() => {
    if (!searchExpanded) {
      dispatch({ type: "SEARCH_RESET" });
      clearSearch();
    }
  }, [searchExpanded, dispatch]);

  const clearSearch = () => {
    setSearchValue("");
  };

  const redirectToSearchPage = (event) => {
    if (event.key === "Enter") {
      dispatch({ type: "SET_SEARCH_VALUE", payload: searchValue });
      history.push(`/search`);
      setSearchExpanded(false);
      setSearchValue("");
      if (inputRef?.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <div
      className={searchStyles.searchContainer}
      onClick={() => setSearchExpanded(true)}
    >
      <div
        className={
          searchExpanded
            ? searchStyles.containerExpanded
            : searchStyles.container
        }
        ref={dropdownRef}
      >
        {IconService.getIcon("search", searchStyles.searchIcon)}
        <input
          ref={inputRef}
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
          name={"search"}
          placeholder={"Search"}
          type="text"
          className={searchStyles.search}
          onKeyPress={redirectToSearchPage}
        />
        <SearchDropdown {...{ clearSearch, searchValue }} />
      </div>
    </div>
  );
};

export default connect(null, { handleSearch })(Search);
