import { axiosInstance } from "../api";

export const handleSearch = (searchVal) => async (dispatch) => {
  dispatch({ type: "SEARCH_REQUEST" });
  try {
    const { data } = await axiosInstance.get(`/products/search/${searchVal}`);
    dispatch({ type: "SEARCH_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "SEARCH_FAIL", payload: message });
  }
};
