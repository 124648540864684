import React, { useEffect, useState } from "react";
import IconService from "../../../services/iconService";
import Modal from "../../modal/modal";
import modalStyles from "../../modal/modal.module.css";
import iconStyles from "../../../styles/icon.module.css";
import { connect, useDispatch } from "react-redux";
import Button from "../../button/button";
import LoadingCog from "../../loadingCog";
import TextArea from "../../textarea/textarea";
import Input from "../../input/input";
import {
  replyToContact,
  getContactRequestById,
} from "../../../actions/contactActions";

const ReplyModal = ({ contact, loading, success, error, replyToContact, getContactRequestById, ...props }) => {
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = () => {
    if (!subject || !message) {
      return;
    }
    const reply = {
      subject,
      message,
    };
    replyToContact({ reply, contact });
  };

  function hideModal() {
    setSubject("");
    setMessage("");
    props.closeModal();
  }

  useEffect(() => {
    if (contact && props.show) {
      setSubject(`Re: ${contact.subject}`);
    }
  }, [contact, props.show]);

  useEffect(() => {
    if (success) {
      getContactRequestById(contact._id);
      dispatch({ type: "REPLY_TO_MESSAGE_RESET" });
      hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contact, success]);

  return (
    <Modal show={props.show} onHide={hideModal} noOutsideClick>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.titleSmall}>
          Reply to Message #{contact?._id}
        </div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        <div>
          <div style={{ margin: "10px 0" }}>
            <Input
              {...{
                name: "Subject",
                withLabel: true,
                value: subject,
                onChange: setSubject,
              }}
            />
          </div>
          <TextArea
            withLabel={true}
            name={"Message"}
            onChange={setMessage}
            required={true}
          />
          <div className={modalStyles.errorMessageContainer}></div>
          {error && error}
          <div>
            <Button
              type={"button"}
              onClick={submitHandler}
              fullWidth={true}
              height={"45px"}
              disabled={!message || !subject}
            >
              {loading ? <LoadingCog /> : <>Send Reply</>}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  ({ contactReplyTo }) => ({
    loading: contactReplyTo.loading,
    success: contactReplyTo.success,
    error: contactReplyTo.error,
  }),
  {
    replyToContact,
    getContactRequestById,
  }
)(ReplyModal);
