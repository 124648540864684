import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllCategories } from "../../actions/productActions";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import CategoryCard from "../../components/categoryCard/categoryCard";
import screenStyles from "../../styles/screen.module.css";

const Categories = ({ categories, getAllCategories }) => {
  useEffect(() => {
    if (!categories) {
      getAllCategories();
    }
  }, [categories, getAllCategories]);

  useEffect(() => {
    document.title = "Shop By Category | Shop at ShotDocMD";
  }, []);

  return (
    <div className={"content-container"}>
      <div>
        <Breadcrumb
          options={[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "Categories",
              path: "/categories",
            },
          ]}
        />
        <div className={screenStyles.brandName}>Shop by Category</div>
        <div className={screenStyles.results}>
          {categories ? (
            <>
              {categories.length} result{categories?.length !== 1 && "s"}
            </>
          ) : (
            <>&nbsp;</>
          )}{" "}
        </div>
      </div>
      <div className="brands-grid">
        {categories?.map((category, index) => {
          return (
            <CategoryCard
              {...{ name: category, baseRoute: "categories" }}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default connect(
  ({ getCategories }) => ({
    categories: getCategories.categories,
  }),
  { getAllCategories }
)(Categories);
