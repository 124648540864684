import React, { Component } from "react";
import Modal from "../modal/modal";
import modalStyles from "../modal/modal.module.css";
import styles from "./imageModal.module.css";
import Cropper from "react-easy-crop";
import Button from "../button/button";
import styled from "styled-components";
import ImageDND from "./imageDND";
import IconService from "../../services/iconService";
import iconStyles from "../../styles/icon.module.css";
const ImageRow = styled.div`
  display: flex;
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 100px;
  height: 100%;
  object-fit: contain;
  margin: 5px;
  cursor: pointer;
  ${({ isSelected }) => isSelected && `border: 1px solid var(--primary)`};
  &:hover {
    background-color: var(--search-background);
  }
`;

class ImageCropModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedArea: {},
      images: this.props.images,
      imageIndex: 0,
      showCropperScreen: false,
      canSave: false,
    };
    this.onCropComplete = this.onCropComplete.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.updateImageOrder = this.updateImageOrder.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.canSaveImages = this.canSaveImages.bind(this);
    this.updateCurrentImage = this.updateCurrentImage.bind(this);
  }

  componentDidMount() {
    if (!this.props.showOrderStep) {
      this.setState({
        showCropperScreen: true
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.images !== prevProps.images && this.props.images) {
      this.setState({
        images: this.props.images,
      });
    }

    if (this.props.showOrderStep !== prevProps.showOrderStep) {
      this.setState({
        showCropperScreen: !this.props.showOrderStep,
      })
    }
  }

  onCropComplete(croppedArea, croppedAreaPixels) {
    this.setState(
      {
        croppedArea: croppedAreaPixels,
      },
      () => this.updateCurrentImage()
    );
  }

  hideModal() {
    this.props.closeModal();
  }

  handleSave() {
    this.props.handleImageCrop(this.state.images);
  }

  updateImageOrder(images) {
    this.setState({
      images: images,
    });
  }

  updateCurrentImage() {
    let updatedImage = this.state.images[this.state.imageIndex];
    updatedImage.zoom = this.state.zoom;
    updatedImage.crop = this.state.crop;
    updatedImage.croppedArea = this.state.croppedArea;

    const imageArr = [...this.state.images];

    const updatedImages = imageArr.filter((image) => {
      if (image.blob === updatedImage.blob) {
        return updatedImage;
      } else {
        return image;
      }
    });
    this.setState(
      {
        images: updatedImages,
      },
      () => this.canSaveImages()
    );
  }

  handleImageChange(image, index) {
    this.setState(
      {
        imageIndex: index,
      },
      () => {
        const newImage = this.state.images[this.state.imageIndex];
        if (newImage.zoom) {
          this.setState({
            zoom: Number(newImage.zoom),
          });
        } else {
          this.setState({
            zoom: 1,
          });
        }
        if (newImage.crop) {
          this.setState({
            crop: newImage.crop,
          });
        } else {
          this.setState({
            crop: { x: 0, y: 0 },
          });
        }
        this.canSaveImages();
      }
    );
  }

  canSaveImages() {
    let canSave = true;
    for (let x = 0; x < this.state.images.length; x++) {
      if (!this.state.images[x].croppedArea) {
        canSave = false;
        break;
      }
    }

    this.setState({
      canSave,
    });
  }

  render() {
    const { crop, zoom, images, imageIndex, showCropperScreen, canSave } =
      this.state;
    const { showOrderStep = true } = this.props;
    const { onCropComplete } = this;
    return (
      <Modal show={this.props.show} onHide={this.hideModal} noOutsideClick>
        <div className={modalStyles.headerNoTitle}></div>
        {showCropperScreen ? (
          <div className={modalStyles.modalContent}>
            <div className={modalStyles.title}>Crop the Image</div>
            {showOrderStep &&
              <div
                onClick={() =>
                  this.setState({
                    showCropperScreen: false,
                  })
                }
              >
                {IconService.getIcon("back", iconStyles.closeIcon)}
              </div>
            }
            <div className={styles.imageContainer}>
              <Cropper
                image={images[imageIndex].blob}
                crop={crop}
                zoom={zoom}
                aspect={3 / 3}
                onCropChange={(val) => this.setState({ crop: val })}
                onCropComplete={onCropComplete}
                onZoomChange={(val) => this.setState({ zoom: val })}
              />
            </div>
            <div className={styles.controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  this.setState({ zoom: e.target.value });
                }}
                className={styles.zoomRange}
              />
            </div>
            <div>
              <ImageRow>
                {images &&
                  images.map((image, index) => {
                    return (
                      <StyledImg
                        key={index}
                        src={image.blob}
                        isSelected={index === imageIndex}
                        onClick={() => this.handleImageChange(image, index)}
                      />
                    );
                  })}
              </ImageRow>
            </div>
            <div>
              {" "}
              <Button onClick={this.handleSave} fullWidth disabled={!canSave}>
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div className={modalStyles.modalContent}>
            <div className={modalStyles.title}>Order the Images</div>
            <ImageDND {...{ images, updateImages: this.updateImageOrder }} />
            <div>
              {" "}
              <Button
                onClick={() => this.setState({ showCropperScreen: true })}
                fullWidth
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default ImageCropModal;
