import React, { useEffect, useState } from "react";
import IconService from "../../services/iconService";
import Modal from "../modal/modal";
import modalStyles from "../modal/modal.module.css";
import iconStyles from "../../styles/icon.module.css";
import Input from "../input/input";
import Button from "../button/button";
import LoadingCog from "../loadingCog";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/userActions";
import PasswordInput from "../input/passwordInput";
import { USER_REGISTER_RESET } from "../../constants/userConstants";

const SignUpModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showFirstPage, setShowFirstPage] = useState(true);
  const [errorArr, setErrorArr] = useState([]);

  const userRegister = useSelector((state) => state.userRegister);
  const { userInfo, loading, error } = userRegister;

  function handleEmailChange(email) {
    setEmail(email);
    updateErrorArr("email");
  }
  function handlePasswordChange(pass) {
    setPassword(pass);
    updateErrorArr("password");
  }
  function handleConfirmPasswordChange(pass) {
    setConfirmPassword(pass);
    updateErrorArr("confirmPassword");
  }

  function hideModal() {
    if (error) {
      dispatch({ type: USER_REGISTER_RESET });
    }
    setErrorArr([]);
    setErrorMessage("");
    setShowFirstPage(true);
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    props.closeModal();
  }

  useEffect(() => {
    if (userInfo) {
      hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const submitHandler = (e) => {
    setErrorMessage("");
    let errorMessage = "";
    let error = [];
    if (!validateEmail(email)) {
      errorMessage = "Your email is invalid.";
      error.push("email");
    }
    if (password.length < 8) {
      error.push("password");
      if (!errorMessage) {
        errorMessage = "Your password must be at least 8 characters.";
      }
    }
    if (confirmPassword.length < 8) {
      error.push("confirmPassword");
    }
    if (password !== confirmPassword) {
      if (error.indexOf("confirmPassword") === -1) {
        error.push("confirmPassword");
      }
      if (error.indexOf("password") === -1) {
        error.push("password");
      }
      if (!errorMessage) {
        errorMessage = "Your passwords do not match.";
      }
    }
    if (error.length) {
      setErrorArr(error);
      setErrorMessage(errorMessage);
    } else {
      dispatch(register(firstName, lastName, email, password));
    }
  };

  const handleGoToNext = () => {
    let errors = [];
    if (!firstName) {
      errors.push("firstName");
    }
    if (!lastName) {
      errors.push("lastName");
    }
    if (errors.length) {
      setErrorArr(errors);
    } else {
      setShowFirstPage(false);
    }
  };

  const updateErrorArr = (type) => {
    if (errorArr.length === 0) return;
    let arrCopy = [...errorArr];
    let index = arrCopy.indexOf(type);
    if (index > -1) {
      arrCopy.splice(index, 1);
    }
    setErrorArr(arrCopy);
  };

  const handleBack = () => {
    setShowFirstPage(true);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.header}>
        {!showFirstPage && (
          <div onClick={handleBack}>
            {IconService.getIcon("back", iconStyles.closeIcon)}
          </div>
        )}
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Create an Account</div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        {showFirstPage ? (
          <>
            <Input
              withLabel={true}
              name={"First Name"}
              type={"text"}
              required={true}
              onChange={(val) => {
                setFirstName(val);
                updateErrorArr("firstName");
              }}
              value={firstName}
              placeholder={"Enter First Name"}
              showError={errorArr.includes("firstName")}
            />
            <div className={modalStyles.paddingY}>
              <Input
                withLabel={true}
                name={"Last Name"}
                type={"text"}
                required={true}
                onChange={(val) => {
                  setLastName(val);
                  updateErrorArr("lastName");
                }}
                value={lastName}
                placeholder={"Enter Last Name"}
                showError={errorArr.includes("lastName")}
              />
            </div>
            <div className={modalStyles.flexEnd}>
              <Button type="button" onClick={handleGoToNext}>
                Next
              </Button>
            </div>
          </>
        ) : (
          <div>
            <div className={modalStyles.paddingY}>
              <Input
                withLabel={true}
                name={"Email Address"}
                type={"email"}
                required={true}
                onChange={handleEmailChange}
                value={email}
                placeholder={"Enter Email"}
                showError={errorArr.includes("email")}
              />
            </div>
            <div className={modalStyles.paddingWithHelperText}>
              <PasswordInput
                withLabel={true}
                name={"Password"}
                required={true}
                onChange={handlePasswordChange}
                value={password}
                placeholder={"Enter Password"}
                showError={errorArr.includes("password")}
              />
              <div className={modalStyles.helperText}>
                Password must be at least 8 characters.
              </div>
            </div>{" "}
            <div className={modalStyles.paddingY}>
              <PasswordInput
                withLabel={true}
                name={"Confirm Password"}
                required={true}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                placeholder={"Enter Password"}
                showError={errorArr.includes("confirmPassword")}
              />
            </div>{" "}
            <div className={modalStyles.errorMessageContainer}>
              {errorMessage
                ? errorMessage
                : error
                ? "That email is taken. Please try again with a different email."
                : null}
            </div>
            <div>
              <Button
                type={"button"}
                onClick={submitHandler}
                height={"45px"}
                fullWidth
                disabled={loading}
              >
                {loading ? <LoadingCog /> : <>Create Account</>}
              </Button>
            </div>
          </div>
        )}
        <div className={modalStyles.linkRow}>
          Already have an account?{" "}
          <div className={modalStyles.link} onClick={props.handleSignIn}>
            Sign In
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
