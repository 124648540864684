import { axiosInstance } from "../api";
import {
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_REVIEW_CREATE_REQUEST,
  PRODUCT_REVIEW_CREATE_SUCCESS,
  PRODUCT_REVIEW_CREATE_FAIL,
} from "../constants/productConstants";

export const listProducts =
  ({
    name = "",
    brands = [],
    categories = [],
    concerns = [],
    areasOfFocus = [],
    prices = [],
    order = "",
    rating = 0,
  }) =>
    async (dispatch) => {
      dispatch({
        type: PRODUCT_LIST_REQUEST,
      });
      try {
        const { data } = await axiosInstance.get(
          `/products?&name=${name}&brands=${encodeURIComponent(
            JSON.stringify(brands)
          )}&categories=${encodeURIComponent(
            JSON.stringify(categories)
          )}&concerns=${encodeURIComponent(
            JSON.stringify(concerns)
          )}&areasOfFocus=${encodeURIComponent(
            JSON.stringify(areasOfFocus)
          )}&prices=${encodeURIComponent(
            JSON.stringify(prices)
          )}&rating=${rating}&order=${order}`
        );
        dispatch({
          type: PRODUCT_LIST_SUCCESS,
          payload: {
            data,
            params: {
              searchString: name,
              brands,
              categories,
              concerns,
              areasOfFocus,
              prices,
              order
            },
          },
        });
      } catch (error) {
        dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
      }
    };

export const listAllProducts = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_LIST_REQUEST,
  });
  try {
    const { data } = await axiosInstance.get(`/products/productlist`);
    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
  }
};
export const listFeaturedProducts = () => async (dispatch) => {
  dispatch({
    type: "PRODUCT_FEATURED_LIST_REQUEST",
  });
  try {
    const { data } = await axiosInstance.get(`/products/featured`);
    dispatch({ type: "PRODUCT_FEATURED_LIST_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "PRODUCT_FEATURED_LIST_FAIL", payload: error.message });
  }
};

export const getAllBrands = () => async (dispatch) => {
  dispatch({
    type: "GET_BRANDS_REQUEST",
  });
  try {
    const { data } = await axiosInstance.get(`/products/brands/all`);
    dispatch({ type: "GET_BRANDS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_BRANDS_FAIL", payload: error.message });
  }
};
export const getAllCategories = () => async (dispatch) => {
  dispatch({
    type: "GET_CATEGORIES_REQUEST",
  });
  try {
    const { data } = await axiosInstance.get(`/products/categories/all`);
    dispatch({ type: "GET_CATEGORIES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_CATEGORIES_FAIL", payload: error.message });
  }
};
export const getAllConcerns = () => async (dispatch) => {
  dispatch({
    type: "GET_CONCERNS_REQUEST",
  });
  try {
    const { data } = await axiosInstance.get(`/products/concerns/all`);
    dispatch({ type: "GET_CONCERNS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_CONCERNS_FAIL", payload: error.message });
  }
};
export const getAllAreasOfFocus = () => async (dispatch) => {
  dispatch({
    type: "GET_AREAS_OF_FOCUS_REQUEST",
  });
  try {
    const { data } = await axiosInstance.get(`/products/areas_of_focus/all`);
    dispatch({ type: "GET_AREAS_OF_FOCUS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_AREAS_OF_FOCUS_FAIL", payload: error.message });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_CREATE_REQUEST });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.post(
      `/products`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
      }
    );
    dispatch({ type: PRODUCT_CREATE_SUCCESS, payload: data.product });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_CREATE_FAIL, payload: message });
  }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_DELETE_REQUEST, payload: productId });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    axiosInstance.delete(`/products/${productId}`, {
      headers: { Authorization: `Bearer ${tokenState.token}` },
    });
    dispatch({ type: PRODUCT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_DELETE_FAIL, payload: message });
  }
};

export const detailsProduct = (productId) => async (dispatch) => {
  dispatch({
    type: PRODUCT_DETAILS_REQUEST,
    payload: productId,
  });
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRelatedProducts = (productId) => async (dispatch) => {
  dispatch({
    type: "RELATED_PRODUCTS_REQUEST",
    payload: productId,
  });
  try {
    const { data } = await axiosInstance.get(`/products/${productId}/related`);
    dispatch({
      type: "RELATED_PRODUCTS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "RELATED_PRODUCTS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  dispatch({ type: PRODUCT_UPDATE_REQUEST, payload: product });
  const {
    userSignin: { tokenState },
  } = getState();
  try {
    const { data } = await axiosInstance.put(`/products/${product._id}`, product, {
      headers: {
        Authorization: `Bearer ${tokenState.token}`,
      },
    });
    dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: PRODUCT_UPDATE_FAIL, payload: message });
  }
};

export const createReview =
  (productId, review) => async (dispatch, getState) => {
    dispatch({ type: PRODUCT_REVIEW_CREATE_REQUEST });
    const {
      userSignin: { tokenState },
    } = getState();
    try {
      const { data } = await axiosInstance.post(
        `/products/${productId}/reviews`,
        review,
        {
          headers: { Authorization: `Bearer ${tokenState.token}` },
        }
      );
      dispatch({ type: PRODUCT_REVIEW_CREATE_SUCCESS, payload: data.review });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload: message });
    }
  };
