import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrder, listOrders } from "../../actions/orderActions";
import Button from "../../components/button/button";
import dateFormat from "../../components/dateFormat";
import LoadingBox from "../../components/loadingBox";
import MessageBox from "../../components/messageBox";
import { ORDER_DELETE_RESET } from "../../constants/orderConstants";
import { Title } from "../../styles/utils";

const OrderListScreen = (props) => {
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;
  const orderDelete = useSelector((state) => state.orderDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = orderDelete;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listOrders());
    dispatch({ type: ORDER_DELETE_RESET });
  }, [dispatch, successDelete]);

  const deleteHandler = (order) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteOrder(order._id));
    }
  };

  return (
    <div className="content-container">
      <Title>Orders</Title>
      {loadingDelete && <LoadingBox />}
      {errorDelete && <MessageBox variant="danger">{errorDelete}</MessageBox>}
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div style={{ overflow: "auto" }}>
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>USER</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>SHIPPED</th>
                <th>DELIVERED</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>
                    {order.user
                      ? order.user.firstName + " " + order.user.lastName
                      : order.email}
                  </td>
                  <td>{dateFormat(order.createdAt)}</td>
                  <td>{order.totalPrice.toFixed(2)}</td>
                  <td>
                    {order.isShipped ? dateFormat(order.shippedAt) : "No"}
                  </td>
                  <td>
                    {order.isDelivered ? dateFormat(order.deliveredAt) : "No"}
                  </td>
                  <td>
                    <div style={{ margin: "5px 0" }}>
                      <Button
                        type="button"
                        onClick={() => {
                          props.history.push(`/orderlist/${order._id}`);
                        }}
                      >
                        Details
                      </Button>
                    </div>
                    <Button type="button" onClick={() => deleteHandler(order)} variant={'alert'}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OrderListScreen;
