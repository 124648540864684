import React from 'react';
import styles from './checkbox.module.css';
const Checkbox = (props) => {
  return (
    <label className={styles.container}>
      {props.label}
      <input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        onChange={props.handleCheck}
        disabled={props.disabled}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
};

export default Checkbox;