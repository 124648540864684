import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllBrands } from "../../actions/productActions";
import BrandCard from "../../components/brandCard/brandCard";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import screenStyles from "../../styles/screen.module.css";

const BrandsScreen = ({ brands, getAllBrands }) => {
  useEffect(() => {
    if (!brands) {
      getAllBrands();
    }
  }, [brands, getAllBrands]);

  useEffect(() => {
    document.title = "Shop By Brand | Shop at ShotDocMD";
  }, []);



  return (
    <div className={"content-container"}>
      <div>
        <Breadcrumb
          options={[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "Brands",
              path: "/brands",
            },
          ]}
        />
        <div className={screenStyles.brandName}>Shop by Brand</div>
        <div className={screenStyles.results}>
          {brands ? (
            <>
              {brands.length} result{brands?.length !== 1 && "s"}
            </>
          ) : (
            <>&nbsp;</>
          )}{" "}
        </div>
      </div>
      <div className="brands-grid">
        {brands?.map((brand, index) => {
          return <BrandCard {...{ brandName: brand }} key={index} />;
        })}
      </div>
    </div>
  );
};

export default connect(
  ({ getBrands }) => ({
    brands: getBrands.brands,
  }),
  { getAllBrands }
)(BrandsScreen);
