import { axiosInstance } from "../api";

export const listSpecials = () => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_LIST_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.get(`/specials`, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_LIST_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_LIST_FAIL", payload: message });
    }
};

export const getSpecialDetails = (specialId) => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_DETAILS_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.get(`/specials/${specialId}`, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_DETAILS_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_DETAILS_FAIL", payload: message });
    }
};

export const createSpecial = (special) => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_CREATE_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.post(`/specials`, special, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_CREATE_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_CREATE_FAIL", payload: message });
    }
}

export const updateSpecial = (special) => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_UPDATE_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.put(`/specials/${special._id}`, special, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_UPDATE_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_UPDATE_FAIL", payload: message });
    }
}

export const editSpecial = (special) => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_EDIT_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.delete(`/specials/${special._id}`, special, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_EDIT_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_EDIT_FAIL", payload: message });
    }
}

export const deleteSpecial = (specialId) => async (dispatch, getState) => {
    dispatch({ type: "SPECIAL_DELETE_REQUEST" });
    const {
        userSignin: { tokenState },
    } = getState();
    try {
        const { data } = await axiosInstance.delete(`/specials/${specialId}`, {
            headers: {
                Authorization: `Bearer ${tokenState.token}`,
            },
        });
        dispatch({ type: "SPECIAL_DELETE_SUCCESS", payload: data });
    } catch (error) {
        const message = error.response?.data.message ?? error.message;
        dispatch({ type: "SPECIAL_DELETE_FAIL", payload: message });
    }
}