import React, { memo, useCallback, useEffect, useState } from "react";
import { addToCart, removeFromCart } from "../../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import MessageBox from "../../components/messageBox";
import { Link } from "react-router-dom";
import LoadingCog from "../../components/loadingCog";
import Button from "../../components/button/button";
import cartStyles from "../../styles/cartScreen.module.css";
import screenStyles from "../../styles/screen.module.css";
import IconService from "../../services/iconService";
import RemoveModal from "../../components/removeModal/removeModal";
import iconStyles from '../../styles/icon.module.css';
import Tooltip from '../../components/tooltip/tooltip';

const CartScreen = (props) => {
  const cart = useSelector((state) => state.cart);
  const { cartItems, error, loading } = cart;
  const dispatch = useDispatch();

  const [productToRemove, setProductToRemove] = useState(null);

  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const openRemoveModal = useCallback((item) => {
    setProductToRemove(item);
    setShowRemoveModal(true);
  }, []);

  const closeRemoveModal = useCallback(() => {
    setShowRemoveModal(false);
    setProductToRemove(null);
  }, []);

  const removeFromCartHandler = useCallback(() => {
    dispatch(removeFromCart(productToRemove));
    closeRemoveModal();
  }, [productToRemove, closeRemoveModal, dispatch]);


  useEffect(() => {
    document.title = "Cart | Shop at ShotDocMD";
  }, []);

  const checkoutHandler = () => {
    props.history.push("/checkout");
  };

  return (
    <>
      <div className={"content-container"}>
        {error && <MessageBox variant="danger">{error}</MessageBox>}
        {loading ? (
          <div className="row center">
            <LoadingCog isLarge />
          </div>
        ) : cartItems.length === 0 ? (
          <div className={cartStyles.noItemsContainer}>
            <div className={cartStyles.emptyCart}>Your cart is empty</div>
            <Link to="/search">
              <Button type={"button"} width={"150px"} height={"50px"}>
                Go Shopping
              </Button>
            </Link>
          </div>
        ) : (
          <div className={cartStyles.grid}>
            <div className={cartStyles.cartItemsContainer}>
              <div className={cartStyles.rowAligned}>
                <div className={cartStyles.title}>Your Cart</div>
                <div className={cartStyles.cartCount}>
                  ({cartItems.reduce((a, c) => a + c.qty, 0)} item
                  {cartItems.reduce((a, c) => a + c.qty, 0) !== 1 && "s"})
                </div>
              </div>
              <hr />
              <div>
                {cartItems.map((item, index) => {
                  return (
                    <>
                      <div className={cartStyles.cartItem} key={index}>
                        <div
                          className={cartStyles.row}
                          style={{ width: "100%" }}
                        >
                          <img
                            src={
                              item.images
                                ? item.images[0]
                                : IconService.defaultProductImage
                            }
                            alt={item.name}
                            className={cartStyles.image}
                          />
                          <div className={cartStyles.cartItemInfo}>
                            <Link to={`/product/${item.product}`}>
                              <div className={cartStyles.itemName}>
                                {item.name}
                              </div>
                            </Link>
                            <div>
                              <div className={cartStyles.infoRowMobile}>
                                <div className={cartStyles.rowAligned}>
                                  <div
                                    className={
                                      cartStyles.cartItemSubheadingSmall
                                    }
                                  >
                                    Quantity
                                  </div>
                                  <select
                                    style={{ marginTop: "2px" }}
                                    value={item.qty}
                                    className={screenStyles.selectSmall}
                                    onChange={(e) =>
                                      dispatch(
                                        addToCart(
                                          item._id,
                                          Number(e.target.value),
                                          item.material,
                                          item.size
                                        )
                                      )
                                    }
                                  >
                                    {[...Array(item.countInStock).keys()].map(
                                      (x) => (
                                        <option key={x + 1} value={x + 1}>
                                          {x + 1}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div
                                  className={
                                    cartStyles.cartItemSubheadingContent
                                  }
                                  style={{ marginTop: "0" }}
                                >
                                  ${item.price * item.qty}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            cartStyles.row + " " + cartStyles.itemPrice
                          }
                        >
                          <div className={cartStyles.columnInfo}>
                            <div className={cartStyles.cartItemSubheading}>
                              Item Price
                            </div>
                            <div
                              className={cartStyles.cartItemSubheadingContent}
                            >
                              ${item.price}
                            </div>
                          </div>
                          <div className={cartStyles.columnInfo}>
                            <div className={cartStyles.cartItemSubheading}>
                              Quantity
                            </div>
                            <select
                              style={{ marginTop: "5px" }}
                              value={item.qty}
                              className={screenStyles.select}
                              onChange={(e) =>
                                dispatch(
                                  addToCart(
                                    item._id,
                                    Number(e.target.value),
                                    item.material,
                                    item.size
                                  )
                                )
                              }
                            >
                              {[...Array(item.countInStock).keys()].map((x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className={cartStyles.columnInfo}>
                            <div className={cartStyles.cartItemSubheading}>
                              Total Price
                            </div>
                            <div
                              className={cartStyles.cartItemSubheadingContent}
                            >
                              ${item.price * item.qty}
                            </div>
                          </div>
                        </div>
                        <div className={cartStyles.removeButtonContainer}>
                          <Button
                            type="button"
                            onClick={() => openRemoveModal(item.product)}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                })}
              </div>
            </div>
            <div className={cartStyles.orderSummaryContainer}>
              <div className={cartStyles.title}>Order Summary</div>
              <div className={cartStyles.infoRow}>
                <div className={cartStyles.infoItem}>Subtotal</div>
                <div className={cartStyles.infoItem}>
                  ${cartItems.reduce((a, c) => a + c.price * c.qty, 0)} USD
                </div>
              </div>
              <hr />
              <div className={cartStyles.infoRow}>
                <div className={cartStyles.infoItem}>
                  Shipping{" "}
                  <Tooltip
                    content={"Orders over $150 qualify for free shipping"}
                    direction="top"
                  >
                    {IconService.getIcon("info", iconStyles.infoCircle)}
                  </Tooltip>
                </div>
                <div className={cartStyles.infoItem}>
                  {cartItems.reduce((a, c) => a + c.price * c.qty, 0) >= 150
                    ? "FREE"
                    : "$15 USD"}
                </div>
              </div>
              <hr />
              <div className={cartStyles.infoRow}>
                <div className={cartStyles.infoItem}>Tax</div>
                <div className={cartStyles.infoItem}>
                  $
                  {(
                    cartItems.reduce((a, c) => a + c.price * c.qty, 0) * 0.09
                  ).toFixed(2)}{" "}
                  USD
                </div>
              </div>
              <hr />
              <div className={cartStyles.infoRow}>
                <div className={cartStyles.infoItemLarge}>Estimated Total</div>
                <div className={cartStyles.infoItemLarge}>
                  $
                  {(
                    cartItems.reduce((a, c) => a + c.price * c.qty, 0) * 1.09 +
                    (cartItems.reduce((a, c) => a + c.price * c.qty, 0) >= 150
                      ? 0
                      : 15)
                  ).toFixed(2)}{" "}
                  USD
                </div>
              </div>
              <Button
                type="button"
                onClick={checkoutHandler}
                fullWidth={true}
                disabled={cartItems.length === 0}
              >
                Proceed to Checkout
              </Button>
            </div>
          </div>
        )}
      </div>
      {showRemoveModal && (
        <RemoveModal
          show={showRemoveModal}
          closeModal={closeRemoveModal}
          handleRemove={removeFromCartHandler}
          message={"Are you sure you want to remove this item from your cart?"}
          confirmButton={"Yes, remove this item"}
          rejectButton={"No, keep this item"}
        />
      )}
    </>
  );
};

export default memo(CartScreen);
