import React, { useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

const CheckoutForm = ({
  makePayment,
  handleComplete,
  handlePaymentSuccess,
  handlePaymentError,
  shippingState,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (makePayment) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makePayment]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://www.shotdocmd.com",
        shipping: {
          name:
            shippingState.firstName + " " + shippingState.lastName,
          address: {
            line1: shippingState.address,
            line2: shippingState.address2,
            city: shippingState.city,
            state: shippingState.state,
            postal_code: shippingState.zipcode,
            country: "US",
          },
        },
        receipt_email: shippingState.email,
      },
    });

    if (error) {
      handlePaymentError(error.message);
    } else {
      handlePaymentSuccess();
    }
  };

  const handleChange = (e) => {
    if (e.complete) {
      handleComplete(true, e.value?.type);
    } else {
      handleComplete(false, e.value?.type);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={handleChange} />
    </form>
  );
};

export default CheckoutForm;
