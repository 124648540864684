import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllAreasOfFocus } from "../../actions/productActions";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import CategoryCard from "../../components/categoryCard/categoryCard";
import screenStyles from "../../styles/screen.module.css";

const AreasOfFocus = ({ areasOfFocus, getAllAreasOfFocus }) => {
  useEffect(() => {
    if (!areasOfFocus) {
      getAllAreasOfFocus();
    }
  }, [areasOfFocus, getAllAreasOfFocus]);

  useEffect(() => {
    document.title = "Shop By Area of Focus | Shop at ShotDocMD";
  }, []);



  return (
    <div className={"content-container"}>
      <div>
        <Breadcrumb
          options={[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "Areas of Focus",
              path: "/areas-of-focus",
            },
          ]}
        />
        <div className={screenStyles.brandName}>Shop by Area of Focus</div>
        <div className={screenStyles.results}>
          {areasOfFocus ? (
            <>
              {areasOfFocus.length} result{areasOfFocus?.length !== 1 && "s"}
            </>
          ) : (
            <>&nbsp;</>
          )}{" "}
        </div>
      </div>
      <div className="brands-grid">
        {areasOfFocus?.map((area, index) => {
          return (
            <CategoryCard
              {...{ name: area, baseRoute: "areas-of-focus" }}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default connect(
  ({ getAreasOfFocus }) => ({
    areasOfFocus: getAreasOfFocus.areasOfFocus,
  }),
  { getAllAreasOfFocus }
)(AreasOfFocus);
