import React from "react";
import inputStyles from "./input.module.css";

const CheckoutInput = (props) => {
  return (
    <>
      {props.withLabel && (
        <label htmlFor={props.name} className={inputStyles.label}>
          {props.labelText ? props.labelText : props.name}
        </label>
      )}
      <input
        type={props.type}
        required={props.required ? props.required : false}
        min={props.min ? props.min : undefined}
        onChange={(event) => props.onChange(event?.target.value)}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        className={`${inputStyles.input} ${props.showError ? inputStyles.inputError : ''}`}
        style={props.width ? {width: `${props.width}px`} : {}}
        maxLength={props.maxLength ? props.maxLength : null}
        max={props.max ? props.max : null}
      />
    </>
  );
};

export default CheckoutInput;
