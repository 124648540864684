import React from 'react';

function MessageBox(props) {
    return (
        <div className={`alert alert-${props.variant || 'info'}`} style={{width: '100%', textAlign: 'center'}}>
            {props.children}
        </div>
    );
};

export default MessageBox;