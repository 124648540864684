import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_RESET,
  ORDER_DELETE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_MINE_LIST_FAIL,
  ORDER_MINE_LIST_REQUEST,
  ORDER_MINE_LIST_SUCCESS,
  ORDER_SHIP_FAIL,
  ORDER_SHIP_REQUEST,
  ORDER_SHIP_SUCCESS,
  ORDER_SHIP_RESET,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_RESET,
  ORDER_GUEST_CREATE_REQUEST,
  ORDER_GUEST_CREATE_SUCCESS,
  ORDER_GUEST_CREATE_FAIL,
  ORDER_GUEST_CREATE_RESET,
  ORDER_GUEST_DETAILS_REQUEST,
  ORDER_GUEST_DETAILS_SUCCESS,
  ORDER_GUEST_DETAILS_FAIL,
  ORDER_GET_DETAILS_REQUEST,
  ORDER_GET_DETAILS_SUCCESS,
  ORDER_GET_DETAILS_FAIL,
  ORDER_GET_DETAILS_RESET,
} from "../constants/orderConstants";

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderGuestCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_GUEST_CREATE_REQUEST:
      return { loading: true };
    case ORDER_GUEST_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_GUEST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_GUEST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderMineListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_MINE_LIST_REQUEST:
      return { loading: true };
    case ORDER_MINE_LIST_SUCCESS:
      return { loading: false, orders: action.payload };
    case ORDER_MINE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true };
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload };
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true };
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderGuestDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ORDER_GUEST_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_GUEST_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_GUEST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const orderFindReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case ORDER_GET_DETAILS_REQUEST:
      return { loading: true };
    case ORDER_GET_DETAILS_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_GET_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_GET_DETAILS_RESET:
        return {}
    default:
      return state;
  }
};

export const orderShipReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_SHIP_REQUEST:
      return { loading: true };
    case ORDER_SHIP_SUCCESS:
      return { loading: false, success: true };
    case ORDER_SHIP_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_SHIP_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return { loading: true };
    case ORDER_DELIVER_SUCCESS:
      return { loading: false, success: true };
    case ORDER_DELIVER_FAIL:
      return { loading: false, error: action.payload};
    case ORDER_DELIVER_RESET:
      return {};
    default:
      return state;
  }
};