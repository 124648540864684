import React, { useEffect, useState } from "react";
import Modal from "../../modal/modal";
import modalStyles from "../../modal/modal.module.css";
import iconStyles from "../../../styles/icon.module.css";
import Button from "../../button/button";
import LoadingCog from "../../loadingCog";
import { useDispatch, useSelector } from "react-redux";
import IconService from "../../../services/iconService";
import { USER_UPDATE_PASSWORD_RESET } from "../../../constants/userConstants";
import PasswordInput from "../../input/passwordInput";
import { updateUserPassword } from "../../../actions/userActions";

const UserEditPasswordModal = (props) => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
  const {
    success: successUpdate,
    error,
    loading: loadingUpdate,
  } = userUpdatePassword;

  function hideModal() {
    setOldPassword("");
    setPassword("");
    setPasswordConfirm("");
    props.closeModal();
  }

  function updatePassword(e) {
    e.preventDefault();
    setErrorMessage("");
    if (password.length < 8) {
      setErrorMessage("Password must be at least 8 characters");
      return;
    } else if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }
    dispatch(
      updateUserPassword({
        currentPassword: oldPassword,
        newPassword: password,
      })
    );
  }

  useEffect(() => {
    if (successUpdate) {
      hideModal();
      dispatch({ type: USER_UPDATE_PASSWORD_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdate]);

  return (
    <Modal show={props.show} onHide={hideModal}>
      <div className={modalStyles.headerNoTitle}>
        <span onClick={hideModal}>
          {IconService.getIcon("close", iconStyles.closeIcon)}
        </span>
      </div>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Edit Password</div>
        {props.subheading && (
          <div className={modalStyles.subheading}>{props.subheading}</div>
        )}
        <form onSubmit={updatePassword}>
          <PasswordInput
            withLabel={true}
            name={"Current Password"}
            required={true}
            onChange={setOldPassword}
            value={oldPassword}
            placeholder={""}
          />
          <div className={modalStyles.paddingY}>
            <PasswordInput
              withLabel={true}
              name={"New Password"}
              required={true}
              onChange={setPassword}
              value={password}
              placeholder={""}
            />
          </div>{" "}
          <div className={modalStyles.paddingY}>
            <PasswordInput
              withLabel={true}
              name={"Confirm New Password"}
              required={true}
              onChange={setPasswordConfirm}
              value={passwordConfirm}
              placeholder={""}
            />
          </div>{" "}
          <div className={modalStyles.errorMessageContainer}>
            {errorMessage ? errorMessage : error ? error : null}
          </div>
          <div>
            <Button
              type={"submit"}
              fullWidth={true}
              height={"45px"}
              disabled={loadingUpdate}
            >
              {loadingUpdate ? <LoadingCog /> : <>Update Password</>}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserEditPasswordModal;
