import React from 'react';
import styled from 'styled-components';


// card outline color
const DEFAULT_COLOR = "#e6e6e6";


const StyledLine = styled.div`
  margin: 10px 0;
  height: 0.1rem;
  width: 100%;
  background-color: ${({ color }) => (color ? color : DEFAULT_COLOR)};
  border-radius: 25%;
  border: ${({ color }) => (color ? color : DEFAULT_COLOR)};
`;


const HorizontalLine = ({color}) => {
    return (
        <StyledLine {...{color}} />
    );
};

export default HorizontalLine;