import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 10px;
  padding: 8px;
  transition: 0.2s;
  margin: 7px 0;
  border-radius: 5px;
  &:hover {
    background-color: var(--search-background);
  }
  &:last-child {
      margin-bottom: 0;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled.div`
  font-size: 16px;
  color: var(--primary);
`;

const ProductSearch = ({ product, clearSearch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    history.push(`/product/${product._id}`);
    clearSearch();
    dispatch({ type: "SEARCH_RESET" });
  };

  return (
      <Container onClick={handleClick}>
        <Image src={product.images[0]} alt={product.name} />
        <div>
          <Text>{product.name}</Text>
          <Text>${product.price}</Text>
        </div>
      </Container>
  );
};

export default ProductSearch;
