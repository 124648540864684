import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import Button from "../button/button";
import Input from "../input/input";
import LoadingCog from "../loadingCog";
import modalStyles from "../modal/modal.module.css";  
import TextArea from "../textarea/textarea";
import {sendContactForm} from '../../actions/contactActions';

const ContactForm = () => {
  const dispatch = useDispatch();


  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const contactForm = useSelector((state) => state.contactForm);
  const {success, error, loading} = contactForm

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (loading) return;
    dispatch(sendContactForm({ name, email, phone, subject, message }));
  };

  useEffect(() => {
    if (userInfo) {
        setName(userInfo.firstName + ' ' + userInfo.lastName)
        setEmail(userInfo.email)
    } else {
        setName('')
        setEmail('')
    }
  }, [userInfo])

  return (
    <div className={contentLayoutStyles.card}>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Contact Us</div>
        {success ? (
          <div className={modalStyles.successMessage}>Your message has been received.  We will get back to you in a few business days.</div>
        ) : (
          <form onSubmit={submitHandler}>
            <Input
              withLabel={true}
              name={"Name"}
              type={"text"}
              required={true}
              onChange={setName}
              value={name}
              placeholder={"Enter Name"}
            />
            <div className={modalStyles.paddingY}>
              <Input
                withLabel={true}
                name={"Email"}
                type={"email"}
                required={true}
                onChange={setEmail}
                value={email}
                placeholder={"Enter Order Number"}
              />
            </div>{" "}
            <div className={modalStyles.paddingY}>
              <Input
                withLabel={true}
                name={"Phone (optional)"}
                type={"tel"}
                required={false}
                onChange={setPhone}
                value={phone}
                placeholder={"Enter Phone Number"}
              />
            </div>{" "}
            <div className={modalStyles.paddingY}>
              <Input
                withLabel={true}
                name={"Subject"}
                type={"text"}
                required={true}
                onChange={setSubject}
                value={subject}
                placeholder={"Enter Subject"}
              />
            </div>{" "}
            <div className={modalStyles.paddingY}>
              <TextArea
                withLabel={true}
                name={"Message"}
                type={"text"}
                required={true}
                onChange={setMessage}
                value={message}
                placeholder={"Enter Message"}
              />
            </div>{" "}
            <div className={modalStyles.errorMessageContainer}>
              {error && error}
            </div>
            <div>
              <Button
                type={"submit"}
                fullWidth={true}
                height={"45px"}
                disabled={loading}
              >
                {loading ? <LoadingCog /> : <>Submit</>}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
