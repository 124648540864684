import inputStyles from "./input.module.css";

const Input = (props) => {
  return (
    <>
      {props.withLabel && (
        <label htmlFor={props.name} className={inputStyles.label}>
          {props.labelText ? props.labelText : props.name}
          {props.showStar && <sup>*</sup>}
        </label>
      )}
      <input
        type={props.type}
        required={props.required ? props.required : false}
        min={props.min ? props.min : undefined}
        onChange={(event) => props.onChange(event?.target.value)}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        className={`${inputStyles.input} ${props.withOutline && !props.showError ? inputStyles.inputOutline : ""
          } ${props.showError ? inputStyles.inputError : ""}`}
        style={
          props.width && props.height
            ? { width: `${props.width}px`, height: `${props.height}px` }
            : props.width
              ? { width: `${props.width}px` }
              : props.height
                ? { height: `${props.height}px` }
                : {}
        }
        maxLength={props.maxLength ? props.maxLength : null}
        max={props.max ? props.max : null}
        onKeyDown={props.onKeyPress ?? null}
      />
    </>
  );
};

export default Input;
