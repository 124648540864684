/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  detailsProduct,
  getAllBrands,
  updateProduct,
  getAllCategories,
  getAllConcerns,
  getAllAreasOfFocus,
} from "../../actions/productActions";
import MessageBox from "../../components/messageBox";
import { PRODUCT_UPDATE_RESET } from "../../constants/productConstants";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import TextArea from "../../components/textarea/textarea";
import Checkbox from "../../components/checkbox/checkbox";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import IconService from "../../services/iconService";
import { defaultAreasOfFocus, defaultCategories, defaultConcerns } from "../../interfaces/enums";
import screenStyles from "../../styles/screen.module.css";
import LoadingCog from "../../components/loadingCog";
import ImageUploader from "../../components/image/ImageUploader";


const StyledImageContainer = styled.div`
  display: grid;
  ${({ length }) => `grid-template-columns: repeat(${length}, 1fr)`};
  grid-gap: 20px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

const ProductEditScreen = (props) => {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  const [name, setName] = useState("");
  const [images, setImages] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [price, setPrice] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [size, setSize] = useState("");
  const [facts, setFacts] = useState([]);
  const [factText, setFactText] = useState("");
  const [includedItems, setIncludedItems] = useState([]);
  const [includedItemsText, setIncludedItemsText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [selectedConcerns, setSelectedConcerns] = useState([]);
  const [allConcerns, setAllConcerns] = useState([]);
  const [showConcernInput, setShowConcernInput] = useState(false);
  const [concernInputValue, setConcernInputValue] = useState("");
  const [selectedAreasOfFocus, setSelectedAreasOfFocus] = useState([]);
  const [allAreasOfFocus, setAllAreasOfFocus] = useState([]);
  const [showAreaOfFocusInput, setShowAreaOfFocusInput] = useState(false);
  const [areaOfFocusInputValue, setAreaOfFocusInputValue] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [isFeatured, setIsFeatured] = useState(false)

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const getCategories = useSelector((state) => state.getCategories);
  const { categories } = getCategories;
  const getConcerns = useSelector((state) => state.getConcerns);
  const { concerns } = getConcerns;
  const getAreasOfFocus = useSelector((state) => state.getAreasOfFocus);
  const { areasOfFocus } = getAreasOfFocus;

  useEffect(() => {
    if (!categories) {
      dispatch(getAllCategories());
    }
    if (!concerns) {
      dispatch(getAllConcerns())
    }
    if (!areasOfFocus) {
      dispatch(getAllAreasOfFocus())
    }
  }, [categories, concerns, areasOfFocus, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch(getAllBrands());
      dispatch(getAllCategories());
      props.history.push("/productlist");
    }

    if (!product || product._id !== productId || successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch(detailsProduct(productId));
    } else {
      setName(product.name);
      setImages(product.images);
      setBrand(product.brand);
      setDescription(product.description);
      setCountInStock(product.countInStock);
      setPrice(product.price);
      setVisible(product.visible);
      !!product.size && setSize(product.size);
      !!product.facts?.length && setFacts(product.facts);
      !!product.includedItems?.length &&
        setIncludedItems(product.includedItems);
      !!product.categories?.length && setSelectedCategories(product.categories);
      !!product.concerns?.length && setSelectedConcerns(product.concerns);
      !!product.areasOfFocus?.length && setSelectedAreasOfFocus(product.areasOfFocus);
      !!product.moreInfo && setMoreInfo(product.moreInfo);
      setIsFeatured(product.isFeatured ? product.isFeatured : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, productId, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        images,
        brand: brand.trim(),
        description,
        countInStock,
        price,
        visible: isVisible,
        size,
        facts: !!facts.length ? facts : null,
        includedItems: !!includedItems.length ? includedItems : [],
        categories: !!selectedCategories?.length ? selectedCategories : [],
        concerns: !!selectedConcerns?.length ? selectedConcerns : [],
        areasOfFocus: !!selectedAreasOfFocus?.length ? selectedAreasOfFocus : [],
        moreInfo: moreInfo,
        isFeatured: isFeatured,
      })
    );
  };

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState("");

  const combinedCategories = useMemo(() => {
    if (!categories) {
      return defaultCategories;
    }
    const categorySet = new Set([...defaultCategories, ...categories]);
    return Array.from(categorySet);
  }, [categories]);

  useEffect(() => {
    setAllCategories(combinedCategories);
  }, [combinedCategories]);

  const combinedConcerns = useMemo(() => {
    if (!concerns) {
      return defaultConcerns;
    }
    const concernSet = new Set([...defaultConcerns, ...concerns]);
    return Array.from(concernSet);
  }, [concerns]);

  useEffect(() => {
    setAllConcerns(combinedConcerns);
  }, [combinedConcerns]);

  const combinedAreasOfFocus = useMemo(() => {
    if (!areasOfFocus) {
      return defaultAreasOfFocus;
    }
    const areaSet = new Set([...defaultAreasOfFocus, ...areasOfFocus]);
    return Array.from(areaSet);
  }, [areasOfFocus]);

  useEffect(() => {
    setAllAreasOfFocus(combinedAreasOfFocus);
  }, [combinedAreasOfFocus]);

  const removeFact = (fact) => {
    const updatedFacts = facts.filter((f) => f !== fact);
    setFacts(updatedFacts);
  };
  const addFact = () => {
    if (factText) {
      setFacts([...facts, factText]);
      setFactText("");
    }
  };
  const handleFactKeyPress = (event) => {
    if (event.key === "Enter") {
      addFact();
    }
  };
  const removeIncludedItem = (item) => {
    const updatedItems = includedItems.filter((i) => i !== item);
    setIncludedItems(updatedItems);
  };
  const addIncludedItem = () => {
    if (includedItemsText) {
      setIncludedItems([...includedItems, includedItemsText]);
      setIncludedItemsText("");
    }
  };
  const handleIncludedItemKeyPress = (event) => {
    if (event.key === "Enter") {
      addIncludedItem();
    }
  };
  const handleCategoryKeyPress = (event) => {
    if (event.key === "Enter") {
      handleCategoryAdd();
    }
  };
  const handleConcernKeyPress = (event) => {
    if (event.key === "Enter") {
      handleConcernAdd();
    }
  };
  const handleAreaOfFocusKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAreaOfFocusAdd();
    }
  };
  const toggleCategory = (category) => {
    const currentCategories = [...selectedCategories];
    if (currentCategories.includes(category)) {
      setSelectedCategories(currentCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...currentCategories, category]);
    }
  };
  const toggleConcern = (concern) => {
    const currentConcerns = [...selectedConcerns];
    if (currentConcerns.includes(concern)) {
      setSelectedConcerns(currentConcerns.filter((c) => c !== concern));
    } else {
      setSelectedConcerns([...currentConcerns, concern]);
    }
  };
  const toggleAreaOfFocus = (areaOfFocus) => {
    const currentAreasOfFocus = [...selectedAreasOfFocus];
    if (currentAreasOfFocus.includes(areaOfFocus)) {
      setSelectedAreasOfFocus(currentAreasOfFocus.filter((c) => c !== areaOfFocus));
    } else {
      setSelectedAreasOfFocus([...currentAreasOfFocus, areaOfFocus]);
    }
  };

  const handleCategoryAdd = () => {
    if (categoryInputValue && !allCategories.includes(categoryInputValue)) {
      setAllCategories([...allCategories, categoryInputValue]);
      if (!selectedCategories.includes(categoryInputValue)) {
        setSelectedCategories([...selectedCategories, categoryInputValue]);
      }
      setCategoryInputValue("");
      setShowCategoryInput(false);
    }
  };
  const handleConcernAdd = () => {
    if (concernInputValue && !allConcerns.includes(concernInputValue)) {
      setAllConcerns([...allConcerns, concernInputValue]);
      if (!selectedConcerns.includes(concernInputValue)) {
        setSelectedConcerns([...selectedConcerns, concernInputValue]);
      }
      setConcernInputValue("");
      setShowConcernInput(false);
    }
  };
  const handleAreaOfFocusAdd = () => {
    if (areaOfFocusInputValue && !allAreasOfFocus.includes(areaOfFocusInputValue)) {
      setAllAreasOfFocus([...allAreasOfFocus, areaOfFocusInputValue]);
      if (!selectedAreasOfFocus.includes(areaOfFocusInputValue)) {
        setSelectedAreasOfFocus([...selectedAreasOfFocus, areaOfFocusInputValue]);
      }
      setAreaOfFocusInputValue("");
      setShowAreaOfFocusInput(false);
    }
  };

  return (
    <>
      <div className="content-container">
        <div>
          <div>
            <h1>Edit Product #{productId}</h1>
          </div>
          {loadingUpdate && <LoadingCog />}
          {errorUpdate && (
            <MessageBox variant="danger">{errorUpdate}</MessageBox>
          )}
          {loading ? (
            <LoadingCog />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <div className={screenStyles.content}>
                <div>
                  <div>
                    <Input
                      name="Name"
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={setName}
                      withLabel
                      showStar
                    />
                  </div>
                  <div style={{ margin: "15px 0" }}>
                    <ImageUploader setImages={setImages} images={images} />
                  </div>
                  <div>
                    <Input
                      name="Brand"
                      type="text"
                      placeholder="Enter Brand"
                      value={brand}
                      onChange={setBrand}
                      withLabel
                      showStar
                    />
                  </div>

                  <div>
                    <TextArea
                      type="text"
                      placeholder="Enter Description"
                      value={description}
                      onChange={setDescription}
                      withLabel
                      name={"Description"}
                      rows={12}
                      showStar
                    />
                  </div>
                  <div>
                    <TextArea
                      type="text"
                      placeholder="Enter More Info"
                      value={moreInfo}
                      onChange={setMoreInfo}
                      withLabel
                      name={"More Information (optional)"}
                      rows={8}
                    />
                  </div>
                </div>
                <div>
                  <div className="py-2">
                    <div className={contentLayoutStyles.row}>
                      <Input
                        name="Key benefits of the product"
                        type="text"
                        placeholder="Enter Benefit"
                        value={factText}
                        onChange={setFactText}
                        withLabel
                        onKeyPress={handleFactKeyPress}
                      />
                      <Button type={"button"} width={"100%"} onClick={addFact}>
                        Add
                      </Button>
                    </div>
                    <div className={"column"}>
                      {facts?.map((fact, index) => {
                        return (
                          <div
                            key={index}
                            className={contentLayoutStyles.fact}
                            onClick={() => removeFact(fact)}
                          >
                            {fact}
                            <div className={contentLayoutStyles.factClose}>
                              {IconService.getIcon("close")}
                            </div>
                          </div>
                        );
                      })}
                      {!facts.length && (
                        <div className={contentLayoutStyles.noDataSmall}>
                          No benefits added
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="py-2">
                    <div className={contentLayoutStyles.label}>Categories</div>
                    <div className={contentLayoutStyles.flexWrap}>
                      {allCategories?.map((category, index) => {
                        return (
                          <div
                            onClick={() => toggleCategory(category)}
                            key={index}
                            className={`${contentLayoutStyles.categoryItem} ${selectedCategories.includes(category)
                              ? contentLayoutStyles.categoryItemSelected
                              : ""
                              }`}
                          >
                            {category}
                            {selectedCategories.includes(category) && (
                              <div
                                className={contentLayoutStyles.categoryClose}
                              >
                                {IconService.getIcon("close")}
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {showCategoryInput ? (
                        <>
                          <Input
                            width={"150"}
                            height={"30"}
                            value={categoryInputValue}
                            onChange={setCategoryInputValue}
                            placeholder={"Category"}
                            onKeyPress={handleCategoryKeyPress}
                          />
                          <Button onClick={handleCategoryAdd}>Add</Button>
                        </>
                      ) : (
                        <div
                          className={contentLayoutStyles.addCategory}
                          onClick={() => setShowCategoryInput(true)}
                        >
                          {" "}
                          {IconService.getIcon("plus")} Add a new category
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="py-2">
                    <div className={contentLayoutStyles.label}>Concerns</div>
                    <div className={contentLayoutStyles.flexWrap}>
                      {allConcerns?.map((concern, index) => {
                        return (
                          <div
                            onClick={() => toggleConcern(concern)}
                            key={index}
                            className={`${contentLayoutStyles.categoryItem} ${selectedConcerns.includes(concern)
                              ? contentLayoutStyles.categoryItemSelected
                              : ""
                              }`}
                          >
                            {concern}
                            {selectedConcerns.includes(concern) && (
                              <div
                                className={contentLayoutStyles.categoryClose}
                              >
                                {IconService.getIcon("close")}
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {showConcernInput ? (
                        <>
                          <Input
                            width={"150"}
                            height={"30"}
                            value={concernInputValue}
                            onChange={setConcernInputValue}
                            placeholder={"Concern"}
                            onKeyPress={handleConcernKeyPress}
                          />
                          <Button onClick={handleConcernAdd}>Add</Button>
                        </>
                      ) : (
                        <div
                          className={contentLayoutStyles.addCategory}
                          onClick={() => setShowConcernInput(true)}
                        >
                          {" "}
                          {IconService.getIcon("plus")} Add a new concern
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="py-2">
                    <div className={contentLayoutStyles.label}>
                      Areas of Focus
                    </div>
                    <div className={contentLayoutStyles.flexWrap}>
                      {allAreasOfFocus?.map((area, index) => {
                        return (
                          <div
                            onClick={() => toggleAreaOfFocus(area)}
                            key={index}
                            className={`${contentLayoutStyles.categoryItem} ${selectedAreasOfFocus.includes(area)
                              ? contentLayoutStyles.categoryItemSelected
                              : ""
                              }`}
                          >
                            {area}
                            {selectedAreasOfFocus.includes(area) && (
                              <div
                                className={contentLayoutStyles.categoryClose}
                              >
                                {IconService.getIcon("close")}
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {showAreaOfFocusInput ? (
                        <>
                          <Input
                            width={"150"}
                            height={"30"}
                            value={areaOfFocusInputValue}
                            onChange={setAreaOfFocusInputValue}
                            placeholder={"Area Of Focus"}
                            onKeyPress={handleAreaOfFocusKeyPress}
                          />
                          <Button onClick={handleAreaOfFocusAdd}>Add</Button>
                        </>
                      ) : (
                        <div
                          className={contentLayoutStyles.addCategory}
                          onClick={() => setShowAreaOfFocusInput(true)}
                        >
                          {" "}
                          {IconService.getIcon("plus")} Add a new area of focus
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={contentLayoutStyles.row}>
                      <Input
                        name="Included Items"
                        type="text"
                        placeholder="Enter Item"
                        value={includedItemsText}
                        onChange={setIncludedItemsText}
                        withLabel
                        onKeyPress={handleIncludedItemKeyPress}
                      />
                      <Button
                        type={"button"}
                        width={"100%"}
                        onClick={addIncludedItem}
                      >
                        Add
                      </Button>
                    </div>
                    <div className={"column"}>
                      {includedItems?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={contentLayoutStyles.fact}
                            onClick={() => removeIncludedItem(item)}
                          >
                            {item}
                            <div className={contentLayoutStyles.factClose}>
                              {IconService.getIcon("close")}
                            </div>
                          </div>
                        );
                      })}
                      {!includedItems.length && (
                        <div className={contentLayoutStyles.noDataSmall}>
                          No included items
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Input
                      name="Size"
                      type="text"
                      placeholder="Enter Size"
                      value={size}
                      onChange={setSize}
                      withLabel
                    />
                  </div>
                  <div>
                    <Input
                      name="Price"
                      type="number"
                      min={0}
                      placeholder="Enter Price"
                      value={price}
                      onChange={setPrice}
                      withLabel
                      showStar
                    />
                  </div>
                  <div>
                    <Input
                      name="Count In Stock"
                      type="number"
                      min={0}
                      placeholder="Enter Count in Stock"
                      value={countInStock}
                      onChange={setCountInStock}
                      withLabel
                      showStar
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Checkbox
                      checked={isVisible}
                      handleCheck={() => setVisible(!isVisible)}
                      label={"Visible?"}
                      name={"Visibility Checkbox"}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <Checkbox
                      checked={isFeatured}
                      handleCheck={() => setIsFeatured(!isFeatured)}
                      label={"Featured?"}
                      name={"Featured Checkbox"}
                    />
                  </div>
                </div>
              </div>
              <div style={{ margin: "25px" }}>
                <Button
                  type="button"
                  fullWidth
                  onClick={submitHandler}
                  disabled={loadingUpload}
                >
                  Update
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductEditScreen;
