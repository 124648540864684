import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingCog from "../loadingCog";
import ProductSearch from "../product/productSearch/productSearch";
import styles from "./search.module.css";
import styled from "styled-components";

const LinkContainer = styled.div`
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  transition: 0.2s;
  margin: 5px 0;
  &:hover {
    background-color: var(--search-background);
  }
  font-size: 16px;
  color: var(--primary);
`;

const SeeMoreContainer = styled.div`
  width: 100%;
  text-align: center;
  color: var(--primary);
  font-size: 16px;
  &:hover {
    color: var(--primary-hover);
  }
`;

const SearchDropdown = ({ loading, error, data, clearSearch, searchValue }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = (type, name) => {
    history.push(`/${type}/${name}`)
    dispatch({ type: "SEARCH_RESET" });
    clearSearch();
  };

  const handleSeeMoreClick = () => {
    dispatch({ type: "SET_SEARCH_VALUE", payload: searchValue });
    history.push(`/search`);
    dispatch({ type: "SEARCH_RESET" });
    clearSearch();
  }

  return (
    <>
      <div className={styles.dropdown}>
        {loading ? (
          <div className={styles.dropdownContainer + " " + styles.center}>
            <LoadingCog />
          </div>
        ) : error ? (
          <>{error}</>
        ) : data ? (
          <div className={styles.dropdownContainer}>
            {(!!data.brands.length || !!data.categories.length || !!data.concerns.length || !!data.areasOfFocus.length) && (
              <div>
                <div className={styles.dropdownSubheading}>
                  Search Suggestions
                </div>
                {data.brands &&
                  data.brands.map((brand, index) => {
                    return (
                      <LinkContainer
                        onClick={() => handleClick("brands", brand)}
                        key={index}
                      >
                        {brand}
                      </LinkContainer>
                    );
                  })}
                {data.categories &&
                  data.categories.map((category, index) => {
                    return (
                      <LinkContainer
                        onClick={() => handleClick("categories", category)}
                        key={index}
                      >
                        {category}
                      </LinkContainer>
                    );
                  })}
                {data.concerns &&
                  data.concerns.map((concern, index) => {
                    return (
                      <LinkContainer
                        onClick={() => handleClick("concerns", concern)}
                        key={index}
                      >
                        {concern}
                      </LinkContainer>
                    );
                  })}
                {data.areasOfFocus &&
                  data.areasOfFocus.map((area, index) => {
                    return (
                      <LinkContainer
                        onClick={() => handleClick("areas-of-focus", area)}
                        key={index}
                      >
                        {area}
                      </LinkContainer>
                    );
                  })}
              </div>
            )}
            {!!data.products.length && (
              <>
                <div className={styles.dropdownSubheading}>Products</div>
                {data.products.map((product, index) => {
                  if (index < 3) {
                    return (
                      <ProductSearch
                        {...{ product, clearSearch }}
                        key={index}
                      />
                    );
                  } else if (index === 3 ) {
                    return (
                      <SeeMoreContainer onClick={handleSeeMoreClick} key={index}>
                        See More
                      </SeeMoreContainer>
                    );
                  } else return null;
                })}
              </>
            )}
            {!data.brands.length &&
              !data.categories.length &&
              !data.products.length && !data.concerns.length && !data.areasOfFocus.length && (
                <div className={styles.noData}>No suggestions</div>
              )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default connect(({ generalSearch }) => ({
  loading: generalSearch.loading,
  data: generalSearch.data,
  error: generalSearch.error,
}))(SearchDropdown);
