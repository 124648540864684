import React from "react";
import IconService from "../../services/iconService";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import successScreenStyles from "../../styles/successScreen.module.css";
import GoogleMaps from "../map/googleMap";

const OrderInfo = ({ order, userInfo, isDetails }) => {
  return (
    <div className={contentLayoutStyles.backgroundContainer}>
      {isDetails ? (
        <>
          <div className={successScreenStyles.orderNumber}>
            Order #{order._id}
          </div>
          <div className={successScreenStyles.subheading}>
            {order.isDelivered
              ? "Your order has been delivered"
              : order.isShipped
              ? "Your order is on its way!"
              : "Your order is preparing for shipment"}
          </div>
        </>
      ) : (
        <>
          <div className={successScreenStyles.title}>
            Thank you,{" "}
            {userInfo ? userInfo.firstName : order.shippingAddress.firstName}!
          </div>
          <div className={successScreenStyles.subheading}>
            Your order has been confirmed.
          </div>
        </>
      )}

      <div className={successScreenStyles.sectionContainer}>
        <div className={successScreenStyles.sectionTitle}>Contact</div>
        <div className={successScreenStyles.sectionRow}>
          <span className={successScreenStyles.iconContainer}>
            {IconService.getIcon("useralt", successScreenStyles.iconUser)}
          </span>
          <span className={successScreenStyles.text}>
            {order.shippingAddress.firstName} {order.shippingAddress.lastName}
          </span>
        </div>
        <div className={successScreenStyles.sectionRow}>
          <span className={successScreenStyles.iconContainer}>
            {IconService.getIcon("mail", successScreenStyles.icon)}
          </span>{" "}
          <span className={successScreenStyles.text}>{order.email}</span>
        </div>
      </div>
      <div className={successScreenStyles.sectionContainer}>
        <div className={successScreenStyles.sectionTitle}>Shipping</div>
        <div className={successScreenStyles.shippingRow}>
          {order.shippingAddress.firstName} {order.shippingAddress.lastName}
        </div>
        <div className={successScreenStyles.shippingRow}>
          {order.shippingAddress.address}
          {order.shippingAddress.address2 &&
            ", " + order.shippingAddress.address2}
        </div>
        <div className={successScreenStyles.shippingRow}>
          {order.shippingAddress.city}, {order.shippingAddress.state}{" "}
          {order.shippingAddress.zipcode}
        </div>{" "}
        {order.shippingAddress?.latlng && (
          <div className={successScreenStyles.mapsContainer}>
            {" "}
            <GoogleMaps
              lat={order.shippingAddress.latlng.lat}
              lng={order.shippingAddress.latlng.lng}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderInfo;
