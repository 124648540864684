import React, { useEffect, useState } from "react";
import modalStyles from "../modal/modal.module.css";
import Button from "../button/button";
import LoadingCog from "../loadingCog";
import { useDispatch, useSelector } from "react-redux";
import PasswordInput from "../input/passwordInput";
import contentLayoutStyles from "../../styles/contentLayout.module.css";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../actions/userActions";

const searchParams = new URLSearchParams(window.location.search);

const token = searchParams.get("token");
const userId = searchParams.get("id");

const ResetPasswordCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorArr, setErrorArr] = useState([]);

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { success, loading, error } = userResetPassword;

  function handlePasswordChange(pass) {
    setPassword(pass);
    updateErrorArr("password");
  }
  function handleConfirmPasswordChange(pass) {
    setConfirmPassword(pass);
    updateErrorArr("confirmPassword");
  }

  useEffect(() => {
    if (!token && !userId) {
      history.push("/");
    }
  }, [history]);

  const submitHandler = (e) => {
    e.preventDefault();
    setErrorMessage("");
    let errorMessage = "";
    let error = [];
    if (password.length < 8) {
      error.push("password");
      if (!errorMessage) {
        errorMessage = "Your password must be at least 8 characters.";
      }
    }
    if (confirmPassword.length < 8) {
      error.push("confirmPassword");
    }
    if (password !== confirmPassword) {
      if (error.indexOf("confirmPassword") === -1) {
        error.push("confirmPassword");
      }
      if (error.indexOf("password") === -1) {
        error.push("password");
      }
      if (!errorMessage) {
        errorMessage = "Your passwords do not match.";
      }
    }
    if (error.length) {
      setErrorArr(error);
      setErrorMessage(errorMessage);
    } else {
      dispatch(resetPassword({ password, userId, token }));
    }
  };

  const updateErrorArr = (type) => {
    if (errorArr.length === 0) return;
    let arrCopy = [...errorArr];
    let index = arrCopy.indexOf(type);
    if (index > -1) {
      arrCopy.splice(index, 1);
    }
    setErrorArr(arrCopy);
  };

  return (
    <div className={contentLayoutStyles.card}>
      <div className={modalStyles.modalContent}>
        <div className={modalStyles.title}>Reset Password</div>
        {success ? (
          <div className={modalStyles.successMessage}>
            Password successfully updated
          </div>
        ) : (
          <div>
            <div className={modalStyles.paddingWithHelperText}>
              <PasswordInput
                withLabel={true}
                name={"Password"}
                required={true}
                onChange={handlePasswordChange}
                value={password}
                placeholder={"Enter Password"}
                showError={errorArr.includes("password")}
              />
              <div className={modalStyles.helperText}>
                Password must be at least 8 characters.
              </div>
            </div>{" "}
            <div className={modalStyles.paddingY}>
              <PasswordInput
                withLabel={true}
                name={"Confirm Password"}
                required={true}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
                placeholder={"Enter Password"}
                showError={errorArr.includes("confirmPassword")}
              />
            </div>{" "}
            <div className={modalStyles.errorMessageContainer}>
              {errorMessage ? errorMessage : error ? error : null}
            </div>
            <div>
              <Button
                type={"button"}
                onClick={submitHandler}
                height={"45px"}
                fullWidth
                disabled={loading}
              >
                {loading ? <LoadingCog /> : <>Reset Password</>}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordCard;
