import styled from "styled-components";

export const StyledContainer = styled.div`
  height: calc(100% + 30px);
  background-color: var(--secondary-bg-color);
  width: ${({ open }) => (open ? "230px" : "20px")};
  transition: 0.2s ease-in-out;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -10px;
  padding-bottom: 10px;
  padding-top: 20px;
  border-right: 1px solid var(--card-outline-color);
  border-left: 1px solid var(--card-outline-color);
`;

export const StyledButton = styled.div`
  position: absolute;
  left: ${({ open }) => (open ? "200px" : "0px")};
  top: 0px;
  height: 30px;
  width: 30px;
  z-index: 2;
  transition: 0.2s ease-in-out;
  border: 1px solid var(--card-outline-color);
  border-radius: 50%;
  background-color: var(--secondary-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--primary);
  &:hover {
    background-color: var(--main-bg-color);
  }
`;
export const Title = styled.div`
  color: var(--primary-dark);
  text-align: center;
  font-weight: 600;
  font-size: 22px;
`;
export const MobileTitle = styled.div`
  color: var(--primary-dark);
  text-align: center;
  font-weight: 600;
  font-size: 26px;
`;
export const SubTitle = styled.div`
  color: var(--primary-dark);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
`;

export const ContentContainer = styled.div`
  transition: opacity 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
`;


export const ContentStickyContainer = styled.div`
  position: sticky;
  top: 100px;
  margin-top: 40px;
`;

export const StyledFilterCount = styled.div`
  background-color: var(--primary);
  color: var(--button-text-color);
  height: 16px;
  width: 25px;
  font-size: 12px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: opacity 0.4s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;
export const MobileStyledFilterCount = styled.div`
  background-color: var(--primary);
  color: var(--button-text-color);
  height: 18px;
  width: 28px;
  font-size: 14px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: opacity 0.4s;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;
export const SectionTitle = styled.div`
  color: var(--primary);
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
`;
export const MobileSectionTitle = styled.div`
  color: var(--primary);
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
`;